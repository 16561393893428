import { computed, getCurrentInstance } from 'vue';

const POLL_INTERVAL = 2500;
const RECENT_NOTES_COUNT = 5;

export default function useNotesPoll() {
  const _this = getCurrentInstance()?.proxy as any;
  if (!_this) return;

  const fetchNotes = async () => {
    return _this.$store.dispatch('getNotes', { skipSetNotes: true });
  };

  const getRecentNotes = (notes: any) => {
    return Object.values(notes)
      .sort((a: any, b: any) => b.time_created - a.time_created)
      .slice(0, RECENT_NOTES_COUNT);
  };

  // Fetchs notes and sends recent *n* notes to electron process to be displayed in tray menu.
  const setNotes = async () => {
    const notes = await fetchNotes();

    //@ts-ignore
    const electronAPI = window.electron;
    if (electronAPI?.send) {
      electronAPI.send('send-notes-channel', getRecentNotes(notes));
    }
  };

  // Setups notes polling interval.
  const setupNotesPoll = async () => {
    await setNotes();
    setInterval(setNotes, POLL_INTERVAL);
  };

  return {
    getRecentNotes,
    setNotes,
    setupNotesPoll,
  };
}
