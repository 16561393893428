import { getCurrentInstance } from 'vue';
import { Store } from 'vuex';

export default function useAddCardInGroupByOrder(store?: Store<any>) {
  const _this = getCurrentInstance();
  const proxy: any = store ?? _this?.proxy.$store;

  const dispatchCardAction = (
    action: string,
    card: any,
    items: any[],
    groupBy: string,
    defaultId: string,
    idKey: string | number
  ) => {
    if (items.length > 0) {
      for (const item of items) {
        proxy.dispatch(action, {
          boardId: card.board_id,
          epicId: card.epic?.id,
          cardId: card.id,
          listId: idKey ? item[idKey] : item,
          groupBy,
        });
      }
    } else {
      proxy.dispatch(action, {
        boardId: card.board_id,
        epicId: card.epic?.id,
        cardId: card.id,
        listId: defaultId,
        groupBy,
      });
    }
  };

  const addCardInGroupByOrder = (card: any, tagsToAdd = card.tags || []) => {
    dispatchCardAction(
      'addCardToGroupByList',
      card,
      card.members,
      'assignee',
      'unassigned',
      'user_id'
    );
    dispatchCardAction('addCardToGroupByList', card, tagsToAdd, 'tag', 'notags', 'id');
    dispatchCardAction(
      'addCardToGroupByList',
      card,
      [card?.epic?.id || ''],
      'epic',
      'noproject',
      'id'
    );
    dispatchCardAction('addCardToGroupByList', card, [card.priority || 0], 'priority', '', 0);
    dispatchCardAction(
      'addCardToGroupByList',
      card,
      [card.status || 'backlog'],
      'statusType',
      '',
      0
    );

    if (card?.epic?.id) {
      dispatchCardAction('addCardToGroupByList', card, [card.project_id], 'space', '', 0);
    }
  };

  const removeCardFromGroupByOrder = (card: any, tagsToAdd = card.tags || []) => {
    dispatchCardAction(
      'removeCardFromGroupByList',
      card,
      card.members,
      'assignee',
      'unassigned',
      'user_id'
    );
    dispatchCardAction('removeCardFromGroupByList', card, tagsToAdd, 'tag', 'notags', 'id');
    dispatchCardAction(
      'removeCardFromGroupByList',
      card,
      [card?.epic?.id || ''],
      'epic',
      'noproject',
      'id'
    );

    dispatchCardAction('removeCardFromGroupByList', card, [card.priority || 0], 'priority', '', 0);
    dispatchCardAction(
      'addCardToGroupByList',
      card,
      [card.status || 'backlog'],
      'statusType',
      '',
      0
    );
  };

  return {
    addCardInGroupByOrder,
    removeCardFromGroupByOrder,
  };
}
