const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const MONTH = 30 * DAY;
const YEAR = 365 * DAY;
const UNSPLASH_URL = 'https://app.superthread.com/fn/img/unsplash';
const UNSPLASH_DOWNLOAD_URL = 'https://app.superthread.com/fn/img/unsplash-download';
const UNSPLASH_BASE_URL = '.unsplash.com';
const screens = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  xxl: '1536px',
};
const ST_PUBLIC_PAGE_BASE_URL = 'https://pages.superthread.com/t/tMZnvavA/p/';
const staticAssetsUrl = 'https://assets.superthread.com';
const staticFilesUrl = 'https://static.superthread.com';

export default {
  fullScreen: 'fullScreen',
  docked: 'docked',
  modal: 'modal',
  postHogApiHost: 'https://eu.posthog.com',
  postHogApiKey: 'phc_Bk5R0M204Bj1sACe9sc9WkSEL7J9cyI3qAhEY7grJhQ',
  emptyMarkdownInput: '<p></p>',
  emptyEditor: '<p></p>',
  profileImageSupportedFiles: ['JPG', 'JPEG', 'PNG', 'GIF'],
  cardSupportedFiles: [
    'JPG',
    'PNG',
    'PDF',
    'GIF',
    'TXT',
    'MP4',
    'BMP',
    'JPEG',
    'SVG',
    'AVIF',
    'WEBP',
    'DOC',
    'PLAIN',
    'DOCX',
    'RTF',
    'ODG',
    'XML',
    'HTML',
    'XLS',
    'XLSX',
    'ODS',
    'KEY',
    'ODP',
    'PPS',
    'PPT',
    'PPTX',
    'AVI',
    'FLV',
    'MOV',
    'MKV',
    'MPG',
    'RM',
    'VOB',
    'WMV',
    'H264',
    'MPEG',
    '7Z',
    'ARJ',
    'DEB',
    'PKG',
    'RAR',
    'RPM',
    'TAR',
    'ZIP',
    'ZIPX',
    'Z',
    'GZ',
    'TAR',
    'JSON',
  ],
  userColors: [
    '#12B76A',
    '#4A9AFF',
    '#F04438',
    '#F79009',
    '#4E5BA6',
    '#7A5AF8',
    '#EE46BC',
    '#F63D68',
    '#667085',
  ],
  editorSupportedFiles: ['JPG', 'JPEG', 'PNG', 'GIF', 'AVIF', 'WEBP'],
  videoVolumeDefault: 1,
  videoVolumeStorageKey: 'video-volume',
  videoMuteVolumeKey: 'video-mute-volume',
  addCardButton: 'add-card-', // add-card-<cardId>
  epicsBoardId: 'epics',
  inEpicBoardIdPrefix: 'inEpicBoard',
  epicsBoardRoute: {
    path: 'roadmap',
    name: 'epics-board',
  },
  epicRoute: {
    path: 'project',
    name: 'epic',
  },
  electronNotesRoute: {
    path: 'electronnotes',
    name: 'electronnotes',
  },
  newPage: 'newpage',
  newBoard: 'newboard',
  manageSpacesRouteName: 'manage-spaces',
  settingsManageSpacesRouteName: 'settings/manage-spaces',
  createSpaceRouteName: 'create-space',
  settingsCreateSpaceRouteName: 'settings/create-space',
  settingsMemberRouteName: 'settings/member',
  settingsSpaceRouteName: 'settings/space',
  settingsBoardsRouteName: 'settings/boards',
  settingsSelectedBoardRouteName: 'settings/boards/selected',
  settingsSprintsRouteName: 'settings/sprints',
  settingsWorkspaceRouteName: 'general',
  settingsImportRouteName: 'import',
  settingsNotificationsRouteName: 'notifications',
  settingsRouteName: 'settings',
  settingsTemplate: {
    root: 'templates',
    card: {
      root: {
        path: 'card',
        name: 'templates-card',
      },
      create: {
        path: 'create',
        name: 'templates-card-create',
      },
      edit: {
        path: 'edit',
        name: 'templates-card-edit',
      },
    },
    page: {
      root: {
        path: 'page',
        name: 'templates-page',
      },
      create: {
        path: 'create',
        name: 'templates-page-create',
      },
      edit: {
        path: 'edit',
        name: 'templates-page-edit',
      },
    },
  },
  memberRouteName: 'member',
  roles: {
    owner: 'owner',
    admin: 'admin',
    member: 'member',
    guest: 'guest',
  },
  myWorkRouteName: 'my-work',
  hRx: /((dev|staging)-)?(app|release)(\.)?superthread\.com/,
  internalLinkRegEx: /(https:\/\/)?((dev|staging)-)?(app|release)(\.)?superthread\.com[^ |\n]+\n?/g,
  projectSlug: 'space',
  projectsSlug: 'spaces',
  // sidebar constants
  sidebarWidthRoot: '--sidebar-width',
  sidebarResizeSnapWidth: 15,
  sidebarTogglePercentage: 20,
  timelineSidebarResizeSnapWidth: 15,
  inboxListResizeSnapWidth: 15,
  updateCardCancel: 'update-card-cancel',
  smallCardMemberCountMax: 3,
  openedCardMemberCountMax: 5,
  stImageMinWidth: 100,
  SECOND,
  MINUTE,
  HOUR,
  DAY,
  MONTH,
  YEAR,
  UNSPLASH_URL,
  UNSPLASH_DOWNLOAD_URL,
  screens,
  UNSPLASH_BASE_URL,
  stImage: 'st-image',
  editorChecklistItem: 'checklist-item',
  newChecklistItem: 'newchecklistitem',
  dark: 'dark',
  light: 'light',
  fromSection: 'sidebar',
  blockAttributeName: 'block-id',
  filterTypes: [
    'members',
    'owners',
    'creators',
    'tags',
    'statuses',
    'boards',
    'projects',
    'epics',
    'parent_cards',
    'is_parent',
    'is_child',
    'due_date',
    'linked_cards',
    'time_created',
    'time_updated',
    'external_links',
    'priority',
    'estimates',
  ],
  quickCard: 'quick-card',
  quickChildCard: 'quick-child-card',
  projectsColumn: 'projects-column',
  newListButton: 'new-list',
  integrationRoutes: {
    github: {
      dev: 'https://github.com/apps/superthread-app-dev',
      staging: 'https://github.com/apps/superthread-app-staging',
      release: 'https://github.com/apps/superthread-app',
      production: 'https://github.com/apps/superthread-app',
    },
    zapier: 'https://zapier.com/apps/superthread/integrations',
  },
  searchGroupsOrder: ['members', 'cards', 'epics', 'pages', 'spaces', 'boards'],
  stImageDefaultWidth: 350,
  stImageDefaultHeight: 200,
  stImagePlaceholder: 'st-image-placeholder',
  corsProxyBaseUrl: 'https://app.superthread.com/fn/corsproxy?url=',
  redirectAfterGoogleSignInRoute: 'superthread://oauth2',
  desktopRedirectRoutes: {
    dev: 'https://dev-app.superthread.com/desktop-redirect',
    staging: 'https://staging-app.superthread.com/desktop-redirect',
    release: 'https://release.superthread.com/desktop-redirect',
    production: 'https://app.superthread.com/desktop-redirect',
  },
  changelogUrl: 'https://pages.superthread.com/t/tMZnvavA/p/2141',
  uiNotificationStatuses: {
    success: 'success',
    error: 'error',
    warning: 'warning',
  },
  uiNotificationLabelTypes: {
    opne: 'open',
    update: 'update',
    retry: 'retry',
    changelog: 'changelog',
    undo: 'undo',
  },
  uiNotificationElements: {
    button: 'button',
    span: 'span',
    checkBox: 'checkbox',
  },
  uiNotificationMaxTitleLength: 60,
  timelineHelperElementId: 'timeline-helper-element',
  timelineArrowButtonOffset: 12,
  timelineAnimatedScrollingSpeed: 1.5,
  connectToDesktopAppUrls: [
    'http://localhost:40124/is-desktop-app-running',
    'http://localhost:18283/is-desktop-app-running',
    'http://127.0.0.1:40124/is-desktop-app-running',
    'http://127.0.0.1:18283/is-desktop-app-running',
  ],
  timelineCardTitleMinWidth: 100,
  timelineTooltipDateFormat: 'EEE, dd MMM',
  infoSidebarProject: 'infoSidebar-project',
  emojiRegex: /^[\uD800-\uDBFF][\uDC00-\uDFFF]/,
  pageSyncTime: 60, // 60 seconds
  quickCardMaxTagsCount: 5,
  fullyLoadedBoardPropThreshold: 3,
  noSpaceName: 'No space name',
  showMemberHoverPreviewDelay: 500,
  estimateSystems: {
    fibonacci: {
      values: [1, 2, 3, 5, 8],
      label: 'fibonacciPoints',
      labelAbbreviation: 'fib.',
      type: 'points',
    },
    exponential: {
      values: [1, 2, 4, 8, 16],
      label: 'exponentialPoints',
      labelAbbreviation: 'exp.',
      type: 'points',
    },
    tshirt: {
      values: ['extraSmall', 'small', 'mediumSize', 'large', 'extraLarge'],
      valueAbbreviations: ['XS', 'S', 'M', 'L', 'XL'],
      label: 'tshirtSizes',
      type: 'sizes',
    },
  },
  toggleButtonClass: 'settings-sidebar-toggle-button',
  defaultSuggestedTagsColors: {
    bug: 'red',
    feature: 'blue',
  },
  featureFlagIds: {
    aiAssistant: 'ai0',
    privateSpace: 'pvt',
    userPermissions: 'up',
    inviteMembers: 'im',
    publicPageIndexing: 'ppi',
    sprint: 'spt',
    timelineLayout: 'tml',
    views: 'vws',
    noteAudioTranscripton: 'nat',
  },
  limitIds: {
    spaceLimit: '#sp',
  },
  iconPicker: {
    createProject: 'create-project',
    manageProjects: 'manage-projects',
    sidebarProject: 'sidebar-project',
    page: 'page',
    templateContent: 'template-content',
    epicSidebar: 'epic-sidebar',
    epicBoardView: 'epic-board-view',
  },
  templateDropdown: {
    pageView: 'page-view',
    boardView: 'board-view',
    quickBoardView: 'quick-board-view',
    quickCardModalView: 'quick-card-modal-view',
    quickPageModalView: 'quick-page-modal-view',
  },
  updateEmailPopupTypes: {
    reauthenticate: 'reauthenticate',
    input: 'input',
    verify: 'verify',
  },
  timeline: 'timeline',
  list: 'list',
  calendarLayout: 'calendar',
  // number of items to show in the get started section when 'show less' is clicked
  getStartedShowLessCount: 3,
  projectPrivacyType: {
    open: 'open',
    private: 'private',
  },
  characterLimit: {
    comment: 5000,
  },
  knowledgeBaseUrls: {
    mentionAnyting: `${ST_PUBLIC_PAGE_BASE_URL}1095-other-features#17bfb836-c8d5-47e8-8b84-3095900a37f8`,
    childCards: `${ST_PUBLIC_PAGE_BASE_URL}1088-boards-cards-and-statuses#1c2685e0-d70b-4b0c-9b85-adc0b70cf737`,
    organisePages: `${ST_PUBLIC_PAGE_BASE_URL}1089-pages-subpages-and-public-pages#0e7663eb-aeca-48e4-bb57-4f97c2dd5b38`,
    createPages: `${ST_PUBLIC_PAGE_BASE_URL}1089-pages-subpages-and-public-pages#7b1c0284-990a-4248-9053-b7949870973e`,
    pageIconAndCoverImage: `${ST_PUBLIC_PAGE_BASE_URL}1089-pages-subpages-and-public-pages#f5f20f2f-dc87-4401-9d7d-91bb6ecba33e`,
    comments: `${ST_PUBLIC_PAGE_BASE_URL}1095-other-features#493de01a-1ab1-476b-850b-7d951da67d4c`,
    boardsCardsAndStatuses: `${ST_PUBLIC_PAGE_BASE_URL}1088-boards-cards-and-statuses`,
  },
  // Mon Dec 18 2023 00:00:00 GMT+0000
  onboardingActionsReleaseDate: 1702857600,
  resolved: 'resolved',
  open: 'open',
  onboardingActionsVideoPrefix: 'onboarding-actions-video',
  searchResultsType: {
    SearchModal: 'search_modal',
    MentionList: 'mention_list',
  },
  openSpace: 'open',
  notificationsIntegrations: {
    slackUser: 'slack_user',
    notificationDigest: 'notification_digest',
  },
  webhookIntegrations: {
    slackChannel: 'slack_channel_webhook',
  },
  defaultTimeInputValue: '15:45',
  fullWidth: 'full-width',
  readOnly: 'read-only',
  epicDefaultIcon: 'box',
  resourceIconDefault: {
    height: 16,
    width: 16,
  },
  emptyEditorClass: 'is-editor-empty',
  cardDetailsSidebar: 'card-details-sidebar',
  epicDetailsSidebar: 'epic-details-sidebar',
  owner: 'owner',
  editorContentBaseClass: 'st-editor-content',
  rolledOverList: 'rolledover',
  routeNames: {
    teamLogin: 'teamlogin',
    login: 'login',
    workspaces: 'list-workspaces',
    newWorkspace: 'new-workspace',
    verifyEmail: 'verify-email',
    onboardingInfo: 'onboarding-info',
    join: 'join',
    board: 'board',
    space: 'boards',
    page: 'page',
    boardCard: 'board-card',
    sprintCard: 'sprint-card',
    inbox: 'inbox',
    view: 'view',
    newView: 'newview',
    views: 'views',
    billing: 'billing',
    sprintsRouteName: 'sprints',
    sprint: 'sprint',
  },
  editor: {
    sharedToWebClass: 'shared-to-web',
    bottomGutterClass: 'bottom-gutter',
    arrowUpInFirstRow: 'arrow-up-in-first-row',
    arrowLeftAtDocStart: 'arrow-left-at-doc-start',
  },
  staticAssetsUrl,
  staticImages: `${staticAssetsUrl}/images`,
  staticVideos: `${staticAssetsUrl}/videos`,
  downloadLinks: {
    macArm64: {
      text: 'macArm64Label',
      icon: 'download-cloud',
      link: `${staticFilesUrl}/desktop-app/Superthread-arm64.dmg`,
    },
    macx64: {
      text: 'macx64Label',
      icon: 'download-cloud',
      link: `${staticFilesUrl}/desktop-app/Superthread-x64.dmg`,
    },
  },
  contactMail: 'contact@superthread.com',
  importerRadioNewProject: 'radioNewProject',
  prStates: ['pr_draft', 'pr_open', 'pr_merged', 'pr_closed'],
  prStatusKeys: {
    pr_draft: 'draftPROpened',
    pr_open: 'pROpened',
    pr_merged: 'pRMerged',
    pr_closed: 'pRClosed',
  },
  gitBranchNameRegex: /^[a-zA-Z0-9._\/-]{1,250}$/,
  boardEmailDomain: {
    dev: 'inbound.dev.superthread.com',
    staging: 'inbound.staging.superthread.com',
    prod: 'inbound.superthread.com',
  },
  keyboardShortcutCheatsheetUrl: `${ST_PUBLIC_PAGE_BASE_URL}1556-keyboard-shortcuts`,
  numberOfRecentWorkspaces: 2,
  cardView: 'cardView',
  boardView: 'boardView',
  noListsInEpicsBoardErr: 'Epics boards has no lists',
  missingCardTitleOrListIdErr: 'Missing card title or list id',
  userTypes: {
    user: 'user',
    system: 'system',
  },
  systemName: 'Superthread',
  memberSearchKeys: ['display_name', 'first_name', 'last_name', 'email'],
  memberSearchThreshold: 0.3,
  newNote: 'newnote',
};
