export enum MyWorkTabId {
  Assigned = 'assigned',
  Created = 'created',
  Notes = 'notes',
}

export enum EpicTabId {
  Details = 'details',
  Cards = 'cards',
}

export enum NoteTabId {
  Original = 'original',
  Enhanced = 'enhanced',
  Transcript = 'transcript',
}

export type TabDropdownOptions = {
  items?: any;
  selectedItem?: any;
  title?: string;
  events?: any;
};

export type Tab = {
  id: MyWorkTabId | EpicTabId | NoteTabId;
  label: string;
  icon?: string;
  disabled?: boolean;
  selected?: boolean;
  dropdownOptions?: TabDropdownOptions;
};
