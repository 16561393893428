/**
 * Main vuex actions
 * @packageDocumentation
 * @category Store
 */
import { ActionTree, ActionContext } from 'vuex';
import { nanoid } from 'nanoid';
import { RootState as State } from '@/types';
import {
  authGet,
  basePost,
  authPost,
  authDelete,
  authPatch,
  api,
  authPut,
  authRefresh,
} from '@/utilities/api';
import updateBoardPageTitle from '@/utilities/updateBoardPageTitleInProject';
import constants from '@/utilities/constants';
import objectSet from '@/utilities/objectSet';
import getRandomNumber from '@/utilities/getRandomNumber';
import { icons, colors } from '@/utilities/colorsAndIcons';
import {
  AddMembersRequest,
  MemberRole,
  ChildCommentResponse,
  CommentResponse,
  UpdateCommentRequest,
  UpdateCommentStatusRequest,
  CreateCommentRequest,
  CreateCommentReactionRequest,
  CreateProjectRequest,
  GetUserResponse,
  User,
  GetFilesResponse,
  File,
  UpdateNotificationDigestRequest,
  UpdateNotificationDigestSettingsRequest,
  AddWatcherRequest,
  UpdateUserResponse,
  SprintState,
} from '@superthread-com/api';
import { ResourceType } from '@/types/resources';
import { browserStorageGetItem, browserStorageSetItem } from '@/utilities/browser-storage.util';
import { hideEmptyGroupByBoardKey, showWeekendsByBoardKey } from '@/utilities/localDrafts';
import { Modal } from '@/types/modal';
import { translate } from '@/utilities';
import { OnboardingActionType } from '@/types/onboardingActions';
import { NoteContentType } from '@/types/notes';

const activePromises: { [key: string]: Promise<any> } = {};

let abortSearchController: AbortController | undefined;
let viewsPaginationCursor: string | undefined;
let notificationsPaginationCursor: string | undefined;

const actions: ActionTree<State, any> = {
  /**
   * Login Action
   * https://api.superthread.com/v1/auth/email
   */
  login({ getters }: ActionContext<State, State>) {
    return basePost(`${getters.getRoutes.users}/v1/auth/email`, {
      email: getters.getEmail,
      password: getters.getPassword,
    }).catch((error) => {
      throw error;
    });
  },
  /**
   * Action to login with username and password only and set cookie
   */
  loginUsernamePass({ getters }: ActionContext<State, State>) {
    return authPost(`${getters.getRoutes.users}/v1/auth/email`, {
      email: getters.getEmail,
      password: getters.getPassword,
    }).catch((error) => {
      throw error;
    });
  },
  /**
   * Action for loaing user details.
   * https://api.superthread.com/v1/users/{user_id}
   */
  loadUser({ commit }) {
    return api.auth
      .getUser('me')
      .then(({ data }: { data: GetUserResponse }) => {
        const user: User = { teams: [], ...data.user };
        commit('setUser', user);
        return { user };
      })
      .catch((error) => {
        throw error;
      });
  },
  /**
   * Action for checking if user has token_privileged which is requiered to update email.
   */
  checkForTokenPrivileged({ commit }) {
    return api.auth
      .getUser('me')
      .then(({ data }: { data: GetUserResponse }) => {
        return data.token_privileged;
      })
      .catch((error: Error) => {
        throw error;
      });
  },
  /**
   * Action for sending a code to a new email address.
   * @param {string} email - new email address
   * @param {boolean} resend - flag for resending a new code to same email without requiering a privileged token
   */
  sendCodeToNewEmail({ getters }, { email, resend = false }: { email: string; resend: boolean }) {
    return api.auth
      .sendUpdateUserEmailCode(getters.getUserId, { email }, { resend })
      .catch((error: Error) => {
        throw error;
      });
  },
  /**
   * Action for update user's email address.
   * @param {string} email - must match address the code was sent to
   * @param {boolean} resend - code sent to new email
   */
  updateUserEmail({ getters }, { email, code }: { email: string; code: string }) {
    return api.auth.updateUserEmail(getters.getUserId, { email, code }).catch((error: Error) => {
      throw error;
    });
  },
  /**
   * Sets the flag when user is logged in.
   */
  setLoggedIn({ commit }) {
    commit('setLoggedIn');
  },
  /**
   * Sets the flag when user is not loged in.
   */
  setNotLoggedIn({ commit }) {
    commit('setNotLoggedIn');
  },
  /**
   * Sets passed user to state.
   * @param {User} user
   */
  setUser({ commit }, user: User) {
    commit('setUser', user);
  },

  /**
   * PROJECTS
   */

  /**
   * Action for adding a new project.
   */
  addProject({ getters, dispatch, commit }, { isPrivate, icon, members } = {}) {
    const { getProjectFormName, getProjectFormDescription, getTeamID, getTeamProjects, getUserId } =
      getters;
    if (!getProjectFormName) return Promise.reject(new Error(constants.noSpaceName));
    const newTeamProjects = [...getTeamProjects];
    const currentDate = Math.floor(Date.now() / 1000);
    const projectMembers = [{ user_id: getUserId, role: MemberRole.Admin }, ...(members || [])];
    const localId = nanoid(6);
    const randomColorIndex = getRandomNumber(0, colors.length - 1);
    const randomIconIndex = getRandomNumber(0, icons.length - 1);

    const newProject = {
      description: '',
      id: localId,
      is_member: true,
      is_private: Boolean(isPrivate),
      members: projectMembers,
      team_id: getTeamID,
      time_created: currentDate,
      time_updated: currentDate,
      title: getProjectFormName,
      total_members: projectMembers.length,
      total_members_online: 0,
      user_id: getUserId,
      icon: {
        src: icon?.src || icons[randomIconIndex],
        color: icon?.color || colors[randomColorIndex],
        type: icon?.type || 'icon',
      },
      board_order: [],
      boards: [],
      pages: [],
      page_order: [],
    };
    newTeamProjects.push(newProject);
    dispatch('setProjectFormName', '');
    dispatch('setProjectFormDescription', '');
    dispatch('setTeamProjects', newTeamProjects);

    const payload: CreateProjectRequest = {
      title: getProjectFormName,
      description: getProjectFormDescription,
      is_private: newProject.is_private,
      icon: {
        src: newProject.icon.src,
        color: newProject.icon.color,
        type: newProject.icon.type,
      },
      members: projectMembers,
    };

    return api.projects
      .createProject(getTeamID, payload)
      .then(({ data }) => {
        const { project } = data;
        if (project) {
          commit('localUpdateProject', {
            localId,
            newId: project.id,
          });
          commit('localAddProjectInPageTree', project.id);
          const newProjctsOrder = [...getters.getProjectOrder];
          newProjctsOrder.push(project.id);
          commit('setProjectOrder', newProjctsOrder);
        }
        return data;
      })
      .catch((error) => {
        commit('localUpdateFailRequest', localId);
        throw error;
      });
  },

  addToSocketDeletedProjects({ commit }, projectId) {
    commit('addToSocketDeletedProjects', projectId);
  },

  clearSocketDeletedProjects({ commit }) {
    commit('clearSocketDeletedProjects');
  },

  localRemoveProject({ getters, commit }, projectId) {
    const { getTeamProjects } = getters;
    const newTeamProjects = [...getTeamProjects];
    const index = newTeamProjects.findIndex((p) => p.id === projectId);
    if (index > -1) {
      const project = { ...newTeamProjects[index] };
      newTeamProjects.splice(index, 1);
      commit('setTeamProjects', { projects: newTeamProjects });

      // remove project from projectOrder
      const newProjectOrder = [...getters.getProjectOrder];
      const projectIndex = newProjectOrder.indexOf(projectId);
      if (projectIndex > -1) {
        newProjectOrder.splice(projectIndex, 1);
        commit('setProjectOrder', newProjectOrder);
      }

      // add project to trashed projects
      const trashedProjects = [...getters.getTrashProjects];
      trashedProjects.push(project);
      commit('setTrashProjects', trashedProjects);
    }
  },

  localAddProjectToTeamProjects({ commit, getters }, newProject) {
    const newTeamProjects = [...getters.getTeamProjects];

    const alreadyExists = newTeamProjects.some((p) => p.id === newProject.id);
    if (alreadyExists) return;
    // add is_member flag to project if undefined
    if (newProject.is_member === undefined) {
      newProject.is_member = newProject.members?.some((m: any) => m.user_id === getters.getUserId);
    }
    newTeamProjects.push(newProject);
    commit('setTeamProjects', { projects: newTeamProjects });
  },

  /**
   * Joins user on project, for the passed project id.
   * @param {string} projectId
   */
  joinProject({ getters, dispatch }, projectId) {
    const { getTeamID, getUser } = getters;
    const payload: AddMembersRequest = {
      members: [{ user_id: getUser.id, role: MemberRole.Member }],
    };
    return api.projects
      .addProjectMember(getTeamID, projectId, payload)
      .then(({ data }) => dispatch('loadTeamProjects'))
      .catch((error) => {
        throw error;
      });
  },
  /**
   * Action to load all projects for user's team.
   */
  loadTeamProjects({ commit, getters }) {
    const key = 'loadTeamProjects';
    const existingPromise = activePromises[key];
    if (existingPromise !== undefined) return existingPromise;

    const promise = api.projects
      .getProjects(getters.getTeamID)
      .then(({ data }) => data)
      .then(({ projects, project_order }) => {
        commit('setTeamProjects', { projects, projectOrder: project_order });
        if (projects) {
          projects.forEach((project) => {
            commit('setProjectBoards', {
              projectId: project.id,
              projectBoards: project.board_order,
            });
            const projectBoards = project.boards
              .map((item: any) => {
                const oldBoardData = getters.getFlatBoards[item.id];
                return {
                  ...item,
                  // save old layout property
                  ...(oldBoardData?.layout && { layout: oldBoardData.layout }),
                  project_id: project.id,
                };
              })
              .filter((board) => {
                if (board.id) return !getters.getFlatBoards[board.id];
                return false;
              });
            if (projectBoards.length > 0) {
              commit('setFlatBoards', projectBoards);
            }
          });
        }
        return projects;
      })
      .catch(() => {})
      .finally(() => {
        delete activePromises[key];
      });
    activePromises[key] = promise;
    return promise;
  },
  setTeamProjects({ commit }, teamProjects) {
    commit('setTeamProjects', { projects: teamProjects });
  },
  setTeam({ commit }, team) {
    commit('setTeam', team);
  },
  /**
   * Action to set project order
   */
  setProjectOrder({ commit }, projectOrder) {
    commit('setProjectOrder', projectOrder);
  },
  /**
   * Updates project field.
   * @param {object} projectData {title: string, description: string, overview_page_id: number}
   */
  updateProject(
    { getters, commit, dispatch },
    { projectData, projectId = getters.getCurrentProjectId, skipCheck = false }
  ) {
    const { getTeamID, getSelectedProjectId, getTeamProjectById } = getters;
    let currentProjectId: string;
    if (!skipCheck) {
      currentProjectId = getSelectedProjectId === '' ? projectId : getSelectedProjectId;
    } else {
      currentProjectId = projectId;
    }
    const currentProject = { ...getTeamProjectById(currentProjectId) };
    const currentProjectTimeUpdated = currentProject?.time_updated;
    const timeUpdated = new Date().getTime();

    const updateFields = (fieldValueSource: any, forceUpdate = false) => {
      const fieldsToUpdate = ['title', 'description', 'icon', 'estimate_system'];
      fieldsToUpdate.forEach((field) => {
        if (forceUpdate || (projectData[field] && projectData[field] !== currentProject[field])) {
          dispatch('localUpdateTeamProjectField', {
            projectId: currentProjectId,
            fieldName: field,
            fieldValue: fieldValueSource[field],
          });
        }
      });
    };

    updateFields(projectData);

    dispatch('localUpdateTeamProjectField', { fieldName: 'time_updated', fieldValue: timeUpdated });

    return api.projects.updateProject(getTeamID, currentProjectId, projectData).catch((error) => {
      updateFields(currentProject, true);
      dispatch('localUpdateTeamProjectField', {
        fieldName: 'time_updated',
        fieldValue: currentProjectTimeUpdated,
      });
      commit('setSelectedProjectId', '');
      throw error;
    });
  },

  /**
   * Updates team project field locally.
   * @param {object} fieldNameValueObject {fieldName: string, fieldValue: string}
   */
  localUpdateTeamProjectField(
    { commit, getters },
    { projectId = getters.getCurrentProjectId, fieldName, fieldValue }
  ) {
    const payload = {
      projectId,
      fieldName,
      fieldValue,
    };
    commit('setTeamProjectField', payload);
  },

  /**
   * Sets a flag for displaing a dropdown for adding/browsing projects.
   * @param {boolean} payload
   */
  setProjectSidebarDropdown({ commit }, payload: boolean) {
    commit('setProjectSidebarDropdown', payload);
  },
  /**
   * Sets a flag for displaying a popup for help section.
   * @param {boolean} payload
   */
  setHelpPopupIsOn({ commit }, payload: boolean) {
    commit('setHelpPopupIsOn', payload);
  },
  /**
   * Sets current project id on state and local storage.
   * @param {string} id
   */
  setCurrentProjectId({ commit, getters }, id: string) {
    commit('setCurrentProjectId', {
      teamId: getters.getTeamID,
      projectId: id,
    });
  },
  /**
   * Sets project description from form input when adding project or editing description.
   * @param {string} description
   */
  setProjectFormDescription({ commit }, description: string) {
    commit('setProjectFormDescription', description);
  },
  /**
   * Sets project name from form input.
   * @param {string} name
   */
  setProjectFormName({ commit }, name: string) {
    commit('setProjectFormName', name);
  },

  /**
   * PAGES
   */
  /**
   * Sets previous route path.
   * @param {string} route
   */
  setPreviousRoute({ commit }, route: string) {
    commit('setPreviousRoute', route);
  },
  /**
   * Action sets user's password.
   * @param {string} password
   */
  setPassword({ commit }, password: string) {
    commit('setPassword', password);
  },
  /**
   * Action sets user's username.
   * @param {string} username
   */
  setUserName({ commit }, username: string) {
    commit('setUserName', username);
  },
  /**
   * Action sets user's email address.
   * @param {string} email
   */
  setEmail({ commit }, email: string) {
    commit('setEmail', email);
  },

  /**
   * Action sets the team subdomain to store and browser's local storage.
   * @param {string} teamSubdomain
   */
  setTeamSubDomain({ commit }, teamSubdomain: string) {
    commit('setTeamSubDomain', teamSubdomain);
  },

  removeTeamSubDomain({ commit }, index: number) {
    commit('removeTeamSubDomain', index);
  },

  /**
   * COMMENTS
   */

  addLocalComment({ commit }, payload) {
    commit('addLocalComment', payload);
  },

  updateLocalCommentId({ commit }, { commentId, localId, childCommentId, commentPayload, cardId }) {
    commit('updateLocalCommentId', {
      commentId,
      localId,
      childCommentId,
      commentPayload,
      cardId,
    });
  },

  removeLocalComment({ commit }, { localCommentId, parentCommentId }) {
    commit('removeLocalComment', { localCommentId, parentCommentId });
  },

  localUpdateComment({ commit }, { fieldName, fieldValue, commentId, childCommentId, cardId }) {
    commit('localUpdateComment', { fieldName, fieldValue, commentId, childCommentId, cardId });
  },

  /**
   * Add new comment from page or card.
   */
  addComment(
    { dispatch, getters },
    { content, context = '', isCard = false, card_id, page_id = getters.getCurrentPageID }
  ) {
    const totalCommentCount = parseInt(getters.getPage?.total_comments || 0, 10);
    if (!isCard) {
      dispatch('localUpdatePageProp', {
        pageId: getters.getPage.id,
        propertyName: 'total_comments',
        value: `${totalCommentCount + 1}`,
      });
    }

    if (content === constants.emptyMarkdownInput || content === '')
      return Promise.reject(new Error('Comment content is empty.'));

    const body: CreateCommentRequest = {
      content,
      schema: getters.commentsSchema,
      ...(context && { context }),
      ...(isCard && { card_id }),
      ...(!isCard && { page_id }),
    };

    return api.comments
      .createComment(getters.getTeamID, body)
      .then(({ data }: { data: CommentResponse }) => {
        return data;
      })
      .catch((err) => {
        if (!isCard) {
          dispatch('localUpdatePageProp', {
            pageId: getters.getPage.id,
            propertyName: 'total_comments',
            value: `${totalCommentCount - 1}`,
          });
        }
        return err;
      });
  },

  /**
   * Add new child comment for existing comment.
   * https://api.superthread.com/v1/{team_id}/comments/{comment_id}/children
   */
  addChildComment({ dispatch, getters }, { content, commentId, isCard = false }) {
    const commentContent = content.replace(/(<([^>]+)>)/, '');
    if (!commentContent.length) return Promise.reject(new Error('Comment content is empty.'));
    const body: CreateCommentRequest = {
      content,
      schema: getters.commentsSchema,
    };

    return api.comments
      .createChildComment(getters.getTeamID, commentId, body)
      .then(({ data }: { data: ChildCommentResponse }) => {
        return data;
      })
      .catch((err) => err);
  },

  /**
   * Update edited comment in the comment list.
   */
  updateComment({ getters, commit }, { commentId, content, childComment, childCommentId, status }) {
    const body: UpdateCommentRequest = {
      ...(content && { content }),
      schema: getters.commentsSchema,
      ...(status && { status }),
    };
    const updateChild = () =>
      api.comments.updateChildComment(getters.getTeamID, commentId, childCommentId, body);
    const updateParent = () => api.comments.updateComment(getters.getTeamID, commentId, body);
    const update = childComment ? updateChild : updateParent;

    return update().catch(({ response }) => {
      const error = response.data;
      if (error.message === 'oldSchema') {
        // add ui notification
        commit('addUiNotification', {
          message: translate('updateAppToEditComment'),
          duration: 4000,
          id: nanoid(4),
        });
      }
      throw error;
    });
  },

  updateCommentStatus({ getters }, { commentId, status }) {
    const body: UpdateCommentStatusRequest = {
      status,
    };
    return api.comments.updateCommentStatus(getters.getTeamID, commentId, body);
  },

  /**
   * Loads comments for the previously set comments section
   * Used only for pages, we get card comments within activity
   */
  loadComments({ getters, commit, dispatch }) {
    return api.comments
      .getComments(getters.getTeamID, { page_id: getters.getCurrentPageID })
      .then(({ data }) => {
        commit('setComments', data.comments);
        return data;
      })
      .catch((/* err */) => {
        dispatch('addUiNotification', {
          message: translate('couldntLoadComments'),
          status: constants.uiNotificationStatuses.error,
          labels: [
            {
              text: translate('retry'),
              type: constants.uiNotificationElements.button,
              action: 'loadComments',
              buttonStyle: 'important',
            },
          ],
        });
      });
  },

  /**
   * Load comment by comment id
   */
  loadComment({ getters, commit, dispatch }, commentId) {
    return api.comments
      .getComment(getters.getTeamID, commentId)
      .then(({ data }) => {
        commit('addComment', data.comment);
        return data;
      })
      .catch((/* err */) => {
        dispatch('addUiNotification', {
          message: translate('couldntLoadComments'),
          status: constants.uiNotificationStatuses.error,
          labels: [
            {
              text: translate('retry'),
              type: constants.uiNotificationElements.button,
              action: 'loadComment',
              params: { commentId },
              buttonStyle: 'important',
            },
          ],
        });
      });
  },

  /**
   * Sets the current comment di for further manipualtion.
   * @param {string} commentId
   */
  setCurrentEditCommentID({ commit }, commentId) {
    commit('setCurrentEditCommentID', commentId);
  },

  /**
   * Sets current comments.
   * @param {object} payload
   */
  setComments({ commit }, payload) {
    commit('setComments', payload);
  },

  /**
   * Sets current card comments and activities.
   * @param {object} payload
   */
  setCardCommentsAndActivities({ commit }, { cardId, payload }) {
    commit('setCardCommentsAndActivities', { cardId, payload });
  },

  /**
   * Dispatches API call to delete comment using commentToDeleteId previously set.
   */
  deleteComment(
    { getters, commit, dispatch },
    { commentId, commentType, cardId = '', parentCommentId = '' }
  ) {
    const teamId = getters.getTeamID;

    const totalCommentCount = parseInt(getters.getPage.total_comments, 10);
    const currentRouteName = getters.getCurrentRoute.name;
    let comments = null;
    let commentIndex = -1;
    if (cardId && commentType === ResourceType.CardComment) {
      comments = getters.getCardCommentsAndActivities(cardId);
    } else {
      comments = getters.getPageComments;
      if (currentRouteName === 'page' && !parentCommentId) {
        dispatch('localUpdatePageProp', {
          pageId: getters.getPage.id,
          propertyName: 'total_comments',
          value: `${totalCommentCount - 1}`,
        });
      }
    }
    let commentToDeleteId = '';
    if (parentCommentId) {
      commentToDeleteId = parentCommentId;
      commentIndex = comments.findIndex((c: any) => c.id === parentCommentId);
    } else {
      commentToDeleteId = commentId;
      commentIndex = comments.findIndex((c: any) => c.id === commentId);
    }
    dispatch('addCommentInProgressDelete', commentToDeleteId);
    const oldComment = { ...comments[commentIndex] };

    commit('removeLocalComment', { localCommentId: commentId, parentCommentId, cardId });
    const childCommentString = parentCommentId
      ? `${parentCommentId}/children/${commentId}`
      : `${commentId}`;
    return authDelete(`${getters.getRoutes.pages}/v1/${teamId}/comments/${childCommentString}`)
      .then(({ data }) => {
        dispatch('removeCommentInProgressDelete', commentToDeleteId);
        return data;
      })
      .catch((error) => {
        commit('addLocalComment', {
          comment: oldComment,
          index: commentIndex,
        });
        if (currentRouteName === 'page') {
          dispatch('localUpdatePageProp', {
            pageId: getters.getPage.id,
            propertyName: 'total_comments',
            value: `${totalCommentCount + 1}`,
          });
        }
        throw error;
      });
  },

  /* POPUP */

  /**
   * Sets the message to be displayed on popup.
   * @param {string} message
   */
  setPopUpMessage({ commit }, message: string) {
    commit('setPopUpMessage', message);
  },

  /**
   * Sets the name of the current tab.
   * @param {string} tabName pages/boards
   */
  setCurrentTabName({ commit }, tabName) {
    commit('setCurrentTabName', tabName);
  },

  /**
   * Sets a flag controling the display of comments list in the page view.
   * @param {boolean} active
   */
  setCommentsAreActive({ commit, dispatch }, active: boolean) {
    if (!active) {
      dispatch('setHighlightedPageLevelComments', []);
    }
    commit('setCommentsAreActive', active);
  },

  /**
   * Sets a flag determing where the comment is written.
   * @param {string} section Identifier where the comment is pages/card
   */
  setCommentsSection({ commit }, section) {
    commit('setCommentsSection', section);
  },

  /**
   * Fetches Team Members
   * */
  fetchTeamMembers({ commit, getters }) {
    const teamId = getters.getTeamID;
    if (!teamId) return Promise.reject(new Error('No team id.'));
    return api.auth
      .getTeamMembers(teamId)
      .then(({ data }) => {
        const members = [...(data.members ?? []), ...(data.invited ?? [])].sort((a, b) =>
          (a.display_name || a.email || '').localeCompare(b.display_name || b.email || '')
        );
        commit('setTeamMembers', { members });
        if (data.inactive) {
          commit('setTeamMembers', {
            members: data.inactive.sort((a, b) =>
              (a.display_name || a.email || '').localeCompare(b.display_name || b.email || '')
            ),
            inactive: true,
          });
        }
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },

  /**
   * Set team members
   * @param {array} teamMembers Array of team members
   * */
  setTeamMembers({ commit }, teamMembers) {
    commit('setTeamMembers', { members: teamMembers });
  },

  /**
   * Set current project members
   * @param {array} projectMembers Array of project members
   */
  setProjectMembers({ commit }, projectMembers) {
    commit('setCurrentMembers', projectMembers);
  },

  /**
   * Sets a showSearchModal flag.
   * @param {boolean} payload
   */
  toggleSearchModal({ commit }, payload: boolean) {
    commit('toggleSearchModal', payload);
  },

  cancelSearch() {
    if (abortSearchController !== undefined) {
      abortSearchController.abort();
    }
  },

  fetchSearchResults({ getters, commit }, { query, advanceOptions, childCard = false }) {
    if (abortSearchController !== undefined) {
      // cancel previous request
      abortSearchController.abort();
    }

    const queryParams = {
      query,
      archived: advanceOptions.archived,
      grouped: advanceOptions.grouped,
      types: advanceOptions.types,
      ...(advanceOptions.fields && { fields: advanceOptions.fields }),
      project_ids: advanceOptions.spaces,
      statuses: advanceOptions.statuses,
      parent_card: advanceOptions.parentCard,
    };

    abortSearchController = new AbortController();

    return api.search
      .getSearch(getters.getTeamID, queryParams, {
        signal: abortSearchController.signal,
        paramsSerializer: {
          indexes: null,
        },
      })
      .then((response) => {
        abortSearchController = undefined;
        return response;
      })
      .catch((error) => {
        if (error === 'canceled') return;
        abortSearchController = undefined;
        throw error;
      });
  },

  /**
   * Dispatches API call to search microservice and cancels previous request
   * @param {string} query
   */
  sendSearchQuery({ getters, commit, dispatch }, { query, advanceOptions, childCard = false }) {
    if (query !== '') {
      return dispatch('fetchSearchResults', { query, advanceOptions, childCard })
        .then((resp) => {
          const { data } = resp;
          if (childCard) {
            commit('setChildSearchResults', data);
            return;
          }
          commit('setSearchResults', data);
          return data;
        })
        .catch((/* err */) => {});
    }
    if (childCard) {
      commit('setChildSearchResults', []);
    } else {
      commit('setSearchResults', []);
    }
    return Promise.resolve('No search query!');
  },

  /**
   * Sets search results to store.
   * @param {array} results
   */
  setSearchResults({ commit }, results: any[]) {
    commit('setSearchResults', results);
  },

  setLastSearchQuery({ commit }, lastSearchQuery: string) {
    commit('setLastSearchQuery', lastSearchQuery);
  },

  /**
   * Sets search results to store.
   * @param {array} results
   */
  setChildSearchResults({ commit }, results: any[]) {
    commit('setChildSearchResults', results);
  },

  /**
   * Updates user profile properties
   * @param {{ first_name: string, last_name: string, display_name: string }} payload
   * https://api.superthread.com/v1/users/{user_id}
   */
  updateUserProfile({ getters, dispatch }, payload) {
    return api.auth
      .updateUser(getters.getUserId, payload)
      .then(({ data }: { data: UpdateUserResponse }) => {
        const { profile_image } = data?.user || {};
        if (profile_image) {
          dispatch('completeOnboardingActions', [OnboardingActionType.CustomiseYourProfile]);
        }
        return data;
      });
  },

  /**
   * Loads project by passed project id
   * @param {string} projectId project id
   */
  loadProjectDetails({ getters, dispatch }, projectId) {
    return api.projects
      .getProject(getters.getTeamID, projectId)
      .then(({ data }) => {
        if (data.project) {
          dispatch('setProjectMembers', data.project.members || []);
        }
        return data;
      })
      .catch((err: any) => err);
  },

  /**
   * Sets the message to be displayed on notification.
   * @param {string} message
   */
  setNotificationMessage({ commit }, message) {
    commit('setNotificationMessage', message);
  },

  /**
   * Sets the type of the notification to be displayed.
   * @param {string} message
   */
  setNotificationType({ commit }, type) {
    commit('setNotificationType', type);
  },
  /**
   * Toggles global dark mode toggle.
   */
  toggleDarkMode({ commit }) {
    commit('toggleDarkMode');
  },
  /**
   * Sets the background image of a boards.
   * @param {string} value
   */
  setBoardsBackground({ commit }, value) {
    commit('setBoardsBackground', value);
  },
  /**
   * Sets sidebarExpanded property to the state.
   * @param {boolean} payload
   */
  setSidebarExpanded({ getters, commit }, payload) {
    if (getters.displayInfoSidebar) commit('displayInfoSidebar', false);
    commit('setSidebarExpanded', payload);
  },

  setDisplayEditLinkPopup({ commit }, payload) {
    commit('setDisplayEditLinkPopup', payload);
  },

  setDisplayEditorTooltip({ commit }, payload) {
    commit('setDisplayEditorTooltip', payload);
  },

  setCommentLinkUrl({ commit }, value) {
    commit('setCommentLinkUrl', value);
  },

  setCommentLinkText({ commit }, value) {
    commit('setCommentLinkText', value);
  },

  resolveInlineComment({ commit }, payload) {
    commit('resolveInlineComment', payload);
  },

  logOut({ getters, commit }, redirectData) {
    return authPost(`${getters.getRoutes.users}/v1/auth/logout`)
      .then(({ data }) => {
        commit('resetState');
        localStorage.clear();
        if (redirectData) {
          browserStorageSetItem('redirectRouteAfterLogin', redirectData);
        }
        window.location.href = `${data.logout_redirect_url}`;
      })
      .catch((e) => {
        throw e;
      });
  },
  /**
   * Sets property for displaying add new member popup to the state
   * @param {boolean} payload
   */
  setAddMemberPopup({ commit }, payload) {
    commit('setAddMemberPopup', payload);
  },
  /**
   * Sets property for displaying add members dropdown to the state
   * @param {string} payload
   */
  setDisplayMembersDropdownId({ commit }, payload) {
    commit('setDisplayMembersDropdownId', payload);
  },

  setMembersSearchInput({ commit }, payload) {
    commit('setMembersSearchInput', payload);
  },

  setAddMembersTarget({ commit }, target) {
    commit('setAddMembersTarget', target);
  },
  /**
   * Sets a flag controling the display of info sidebar.
   */
  displayInfoSidebar({ commit }, payload) {
    commit('displayInfoSidebar', payload);
  },

  localAddUserInWorkspace({ commit }, workspace) {
    commit('localAddUserInWorkspace', workspace);
  },

  locaRemoveUserFromWorkspace({ commit }, teamId) {
    commit('locaRemoveUserFromWorkspace', teamId);
  },

  addCardIdForLocalId({ commit }, { localId, id }) {
    commit('addCardIdForLocalId', { localId, id });
  },

  uploadFiles({ getters, commit }, { payload, id, localId, showActualFileSize = false }) {
    const abortUploadController: AbortController = new AbortController();
    return authPost(`${getters.getRoutes.files}/v1/${getters.getTeamID}/files`, payload, {
      signal: abortUploadController.signal,
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
        if (!id || !localId) return;
        const uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // If the id starts with 'editor-', it is an editor upload.
        if ((id as string)?.startsWith?.('editor-')) {
          commit('insertOrUpdateEditorUpload', {
            // We should persist the id as-is, without the prefix.
            id: (id as string)?.replace('editor-', ''),
            progress: uploadProgress,
          });
        } else {
          commit('addAttachmentUploadDetails', {
            id,
            localId,
            uploadDetails: {
              uploadProgress,
              // Actual file size is original file size,
              // while progressEvent.total is the size + all body bytes (multipart/form-data)
              ...(!showActualFileSize && { fileSize: progressEvent.total }),
              cancelUpload: () => {
                abortUploadController.abort();
              },
            },
          });
        }
      },
      timeout: 600000,
    }).then((data) => {
      if ((id as string)?.startsWith?.('editor-')) {
        commit('removeEditorUpload', (id as string)?.replace('editor-', ''));
      }
      return data;
    });
  },

  getFiles({ getters, commit, dispatch }, payload) {
    const uploadInProgressCardId = getters.getCardAttachmentUploadingId;

    return api.files
      .getFiles(getters.getTeamID, payload)
      .then(({ data }: { data: GetFilesResponse }) => {
        const { files }: { files?: File[] } = data;
        files?.forEach((file: any) => {
          dispatch('addLoadedFile', file);
        });
        if (payload.card_id) {
          const attachmentsInProgress =
            uploadInProgressCardId !== -1 && uploadInProgressCardId === payload.card_id
              ? getters
                  .getAttachments(uploadInProgressCardId, 'c')
                  .filter((att: any) => att.local_id)
              : [];

          commit('setAttachments', {
            files: [...attachmentsInProgress, ...(files ?? [])],
            id: payload.card_id,
            prefix: 'c',
          });
          return files;
        }
        if (payload.page_id) {
          return files;
        }
        return data;
      });
  },

  getFile({ getters, dispatch }, id) {
    return authGet(`${getters.getRoutes.files}/v1/${getters.getTeamID}/files/${id}?noredirect=true`)
      .then(({ data }) => {
        dispatch('addLoadedFile', data.file);
        return data.file;
      })
      .catch((error) => error);
  },

  deleteFile({ getters, commit }, { fileId, cardId }: { fileId: string; cardId: string }) {
    if (cardId) {
      commit('localRemoveFileFromCard', { cardId, fileId });
    }
    return authDelete(`${getters.getRoutes.files}/v1/${getters.getTeamID}/files/${fileId}`).catch(
      (error) => {
        throw error;
      }
    );
  },

  resetState({ commit }) {
    commit('resetState');
  },

  addMemberToProject(
    { commit, getters, state, dispatch },
    { membersToAdd, projectId = getters.getCurrentProjectId }
  ) {
    const { getTeamID, getRedirectProjectId } = getters;
    const membersPayload: any[] = [];
    const project = getters.getTeamProjectById(projectId);
    const existingProjectMembers = project?.members ?? getters.getCurrentMembers;
    let isMe = false;

    dispatch('addProjectMembers', {
      membersToAdd,
      projectId,
    });
    membersToAdd.forEach((memberToAdd: any) => {
      isMe = memberToAdd.user_id === getters.getUserId;
      const memberExist = existingProjectMembers.some(
        (m: any) => m.user_id === memberToAdd.user_id
      );

      if (!memberExist) {
        membersPayload.push(memberToAdd);
        const memberAlreadyAdded = state.members.some((m) => m.user_id === memberToAdd.user_id);
        if (!memberAlreadyAdded) commit('addMemberToProject', { memberToAdd });
        commit('joinProject', { memberId: memberToAdd.user_id, projectId });
        if (getRedirectProjectId === projectId) {
          commit('joinProject', getRedirectProjectId);
        }
      }
    });

    const existingOrder = [...getters.getProjectOrder];
    if (isMe) {
      // add project to projectOrder
      const newOrder = [...existingOrder];
      newOrder.push(projectId);
      commit('setProjectOrder', newOrder);
    }

    if (membersPayload.length < 1) return Promise.resolve();

    return api.projects
      .addProjectMember(getTeamID, projectId, { members: membersPayload })
      .catch((error: any) => {
        dispatch('removeProjectMembers', {
          membersToRemove: membersToAdd,
          projectId,
        });
        membersToAdd.forEach((memberToAdd: any) => {
          commit('removeMemberFromProject', { memberToRemove: memberToAdd });
          commit('leaveProject', { memberId: memberToAdd.user_id });
        });
        commit('setProjectOrder', existingOrder);
        throw error;
      });
  },

  addProjectMembers({ commit }, { membersToAdd, projectId }) {
    const { getTeamProjects } = this.getters;
    const projectIndex = getTeamProjects.findIndex((i: any) => i.id === projectId);
    if (projectIndex !== -1) {
      const { members: projectMembers } = getTeamProjects[projectIndex];
      const newMembers = [...projectMembers];
      membersToAdd.forEach((memberToAdd: any) => {
        const memberExist = projectMembers.some((m: any) => m.user_id === memberToAdd.user_id);
        if (!memberExist) {
          newMembers.push(memberToAdd);
        }
      });
      commit('setProjectMembers', {
        members: newMembers,
        projectId,
        projectIndex,
      });
    }
  },

  removeProjectMembers({ commit }, { membersToRemove, projectId }) {
    const { getTeamProjects } = this.getters;
    const projectIndex = getTeamProjects.findIndex((i: any) => i.id === projectId);
    if (projectIndex !== -1) {
      const { members: projectMembers } = getTeamProjects[projectIndex];
      const newMembers = [...projectMembers];
      membersToRemove.forEach((memberToRemove: any) => {
        const memberIndex = projectMembers.findIndex(
          (m: any) => m.user_id === memberToRemove.user_id
        );
        if (memberIndex !== -1) {
          newMembers.splice(memberIndex, 1);
        }
      });
      commit('setProjectMembers', {
        members: newMembers,
        projectId,
        projectIndex,
      });
    }
  },
  /**
   * Remove a member from a project.
   * @param {string} memberId
   */
  removeMemberFromProject(
    { commit, getters, dispatch },
    { memberToRemove, projectId = getters.getCurrentProjectId }
  ) {
    const { getTeamID, getSelectedProjectId } = getters;

    const currentProjectId = getSelectedProjectId === '' ? projectId : getSelectedProjectId;
    dispatch('removeProjectMembers', {
      membersToRemove: [memberToRemove],
      projectId: currentProjectId,
    });
    if (projectId === currentProjectId) {
      commit('removeMemberFromProject', { memberToRemove });
    }
    commit('leaveProject', {
      memberId: memberToRemove.user_id,
      projectId: currentProjectId,
    });
    const memberId = memberToRemove.user_id;
    const isMe = memberId === getters.getUserId;

    return api.projects
      .deleteProjectMember(getTeamID, currentProjectId, memberId)
      .then(({ data }) => {
        if (isMe) {
          // remove project from projectOrder
          const newOrder = [...getters.getProjectOrder];
          const index = newOrder.findIndex((i) => i === currentProjectId);
          newOrder.splice(index, 1);
          commit('setProjectOrder', newOrder);
        }
        return data;
      })
      .catch((error: any) => {
        dispatch('addProjectMembers', {
          membersToAdd: [memberToRemove],
          projectId: currentProjectId,
        });
        if (projectId === getSelectedProjectId) {
          commit('addMemberToProject', { memberToAdd: memberToRemove });
          commit('joinProject', { memberId: memberToRemove.user_id });
        }
        throw error;
      });
  },

  transferSpaceOwnership(
    { commit, getters },
    { ownerId, projectId = getters.getCurrentProjectId }
  ) {
    commit('setTeamProjectField', { projectId, fieldName: 'user_id', fieldValue: ownerId });
    return api.projects.setProjectOwner(getters.getTeamID, projectId, {
      user_id: ownerId,
    });
  },

  /**
   * Sets route path for redirection.
   * @param {string} route
   */
  setRedirectRouteAfterLogin({ commit }, route: object) {
    commit('setRedirectRouteAfterLogin', route);
  },

  /* NOTIFICATIONS */

  /**
   * Loads count of notifications
   */
  loadNotificationCount({ getters, commit }) {
    return authGet(
      `${getters.getRoutes.projects}/v1/${getters.getTeamID}/notifications?filter=unseen`
    )
      .then(({ data }) => {
        commit('setNotificationCount', data.count);
        commit('setNotificationAlerts', data.notifications);
      })
      .catch((err) => err);
  },
  localUpdateNotificationCount({ commit }, count) {
    commit('setNotificationCount', count);
  },
  /**
   * Loads team member notifications.
   */
  async loadTeamMemberNotifications({ getters, commit }, { loadMore = false, filter = '' }) {
    if (notificationsPaginationCursor === 'end' && loadMore === true) return [];

    return api.activity
      .getNotifications(getters.getTeamID, {
        ...(loadMore && { cursor: notificationsPaginationCursor }),
        ...(filter && { filter }),
      })
      .then(({ data }) => {
        // Filter out member_added notifications for boards until backend disables them
        const notifications = (data.notifications || []).filter(
          (n: any) => !(n.type === 'member_added' && n.resource_type === 'board')
        );
        notificationsPaginationCursor = data.cursor || 'end';
        if (loadMore) {
          commit('setTeamMemberNotifications', [
            ...getters.getTeamMemberNotifications,
            ...notifications,
          ]);
        } else {
          commit('setTeamMemberNotifications', notifications);
        }
      });
  },
  /**
   * Update team member notifications by Id.
   */
  updateTeamMemberNotification({ getters }, { id, read, interacted }) {
    return authPatch(`${getters.getRoutes.projects}/v1/${getters.getTeamID}/notifications/${id}`, {
      read,
      interacted,
    }).catch((err) => err);
  },

  localRemoveNotification({ commit }, notificationId) {
    commit('localRemoveNotification', notificationId);
  },

  /* *
   * Update notifications. Mark all as read or seen.
   */
  markAllReadSeen({ getters, commit }, option) {
    const notifications = getters.getTeamMemberNotifications;
    const mostRecentNotificationId = notifications[0].id;
    const read = { read_up_to_id: mostRecentNotificationId };
    const seen = { seen_up_to_id: mostRecentNotificationId };
    if (option === 'read') {
      commit('localUpdateNotifMarkAllAsRead', notifications);
    }
    return authPatch(
      `${getters.getRoutes.projects}/v1/${getters.getTeamID}/notifications`,
      option === 'seen' ? seen : read
    ).catch((err) => {
      throw err;
    });
  },
  setTeamMemberNotifications({ commit }, notifications) {
    commit('setTeamMemberNotifications', notifications);
  },

  inviteUser({ getters, dispatch }, body) {
    /*
      api.auth.createTeamInvite(getters.getTeamID, body)
    */
    return authPost(`${getters.getRoutes.users}/v1/teams/${getters.getTeamID}/invites`, body).then(
      ({ data }) => {
        dispatch('fetchTeamMembers');
        return data;
      }
    );
  },
  updateTeamDetails({ dispatch, getters }, body) {
    return api.auth
      .updateTeam(getters.getTeamID, body)
      .then(({ data }) => {
        const { id, logo } = data?.team || {};
        if (logo?.src) {
          dispatch('completeOnboardingActions', [OnboardingActionType.AddWorkspaceLogo]);
        }
        dispatch('updateRecentWorkspace', { workspaceId: id, updatedFields: data?.team });
        return data;
      })
      .catch((error) => error);
  },
  setRedirectProjectId({ commit }, payload) {
    commit('setRedirectProjectId', payload);
  },

  setInviteeRole({ commit }, payload) {
    commit('setInviteeRole', payload);
  },
  setInviteesProjectIds({ commit }, payload) {
    commit('setInviteesProjectIds', payload);
  },

  loadViews({ commit, getters }, payload) {
    const { getRoutes, getTeamID } = getters;
    return authGet(`${getRoutes.views}/v1/${getTeamID}/views`, { params: payload }).then(
      ({ data }) => {
        if (payload.shared) {
          commit('setPublicViews', data.views);
        } else {
          commit('setPrivateViews', data.views);
        }
      }
    );
  },

  setSelectedViewType({ commit }, viewType) {
    commit('setSelectedViewType', viewType);
  },

  updateSelectedViewName({ commit }, viewName) {
    commit('updateSelectedViewName', viewName);
  },

  fetchNewViewResults({ getters, commit }, payload) {
    const { getRoutes, getTeamID } = getters;
    return authPost(
      `${getRoutes.views}/v1/${getTeamID}/views/preview`,
      { ...payload },
      { params: { count: 100 } }
    ).then(({ data }) => {
      viewsPaginationCursor = data.cursor || 'end';
      return data;
    });
  },

  hideAllReplies({ commit }, payload) {
    commit('hideAllReplies', payload);
  },
  showHideReplies({ commit }, payload) {
    commit('showHideReplies', payload);
  },

  setViewResults({ commit }, payload) {
    commit('setViewResults', payload);
  },

  updateLocalViewId({ commit }, { key, viewId }) {
    commit('updateLocalViewId', { key, viewId });
  },

  localAddViewResultsToList({ commit }, payload) {
    commit('localAddViewResultsToList', payload);
  },

  updateCountInViewResultsList({ commit }, payload) {
    commit('updateCountInViewResultsList', payload);
  },

  createView({ getters }, payload) {
    const { getRoutes, getTeamID } = getters;
    return authPost(`${getRoutes.views}/v1/${getTeamID}/views`, { ...payload }).then(
      ({ data }) => data
    );
  },

  updateView({ getters }, updatingData) {
    const { getRoutes, getTeamID } = getters;
    return authPatch(`${getRoutes.views}/v1/${getTeamID}/views/${updatingData.id}`, {
      ...updatingData.payload,
    }).then(({ data }) => data);
  },

  fetchViewResults({ commit, getters }, viewId) {
    const { getRoutes, getTeamID } = getters;
    return authGet(`${getRoutes.views}/v1/${getTeamID}/views/${viewId}/results`, {
      params: { count: 100 },
    }).then(({ data }) => {
      const { cards = [], count = 0, cursor } = data;
      viewsPaginationCursor = cursor || 'end';
      commit('localAddViewResultsToList', { cards, count, viewId });
      return data;
    });
  },

  currentViewTotalCount({ commit }, payload) {
    commit('currentViewTotalCount', payload);
  },

  fetchMoreViewResults({ commit, getters }, viewId) {
    if (viewsPaginationCursor === 'end') return { cards: [] };

    const { getTeamID, getViewEditMode, getCurrentRoute } = getters;
    const params = viewsPaginationCursor
      ? { count: 100, cursor: viewsPaginationCursor }
      : { count: 100 };
    const card_filters = getters.getViewFilters;
    const type = 'card';
    const isMyWorkRoute = getCurrentRoute.name === constants.myWorkRouteName;
    const isMemberRoute = getCurrentRoute.name === constants.memberRouteName;
    const request =
      !getViewEditMode && !isMyWorkRoute && !isMemberRoute
        ? api.views.getViewResults(getTeamID, viewId, params)
        : api.views.viewPreview(getTeamID, { card_filters, type }, params);

    return request.then(({ data }) => {
      const { cards = [], cursor } = data;
      viewsPaginationCursor = cursor || 'end';
      commit('setViewResults', [...getters.getViewResults, ...cards]);
      return data;
    });
  },

  fetchView({ commit, getters }, viewId) {
    const { getRoutes, getTeamID } = getters;
    return authGet(`${getRoutes.views}/v1/${getTeamID}/views/${viewId}`).then(({ data }) => {
      commit('setSelectedView', data);
      commit('setSelectedViewType', data.type);
    });
  },

  localAddViewToViewList({ commit }, view) {
    commit('localAddViewToViewList', view);
  },

  setSelectedView({ commit }, view) {
    commit('setSelectedView', view);
  },

  setViewFilters({ commit }, filters) {
    commit('setViewFilters', filters);
  },

  setViewPills({ commit }, payload) {
    commit('setViewPills', payload);
  },

  setViewEditMode({ commit }, changed) {
    commit('setViewEditMode', changed);
  },

  localUpdateViewId({ commit }, payload) {
    commit('localUpdateViewId', payload);
  },

  itemNotFound({ commit }, payload) {
    commit('itemNotFound', payload);
  },

  getAllTrashProjects({ commit, getters }, trash) {
    return api.projects.getProjects(getters.getTeamID, { trash }).then(({ data }) => {
      commit('setTrashProjects', data.projects);
      // filter out trashed from the projectOrder
      const newOrder = getters.getProjectOrder.filter((id: string) => {
        return !data.projects?.some((p: any) => p.id === id);
      });
      commit('setProjectOrder', newOrder);
    });
  },

  deleteView(
    { commit, dispatch, getters, state },
    { viewId, shared }: { viewId: string; shared: boolean }
  ) {
    const key = shared ? 'publicViews' : 'privateViews';
    const index = state[key].findIndex((v: any) => v.id === viewId);
    const view = { ...state[key][index] };

    commit('localDeleteView', { key, index });
    const favourites = structuredClone(getters.getFavourites);
    const isFavourite = getters.isFavourite(viewId, ResourceType.View);
    if (isFavourite) {
      commit('localRemoveFromFavourites', {
        resourceId: viewId,
        resourceType: ResourceType.View,
      });
    }

    return api.views
      .viewDelete(getters.getTeamID, viewId)
      .then(() => {
        if (isFavourite) {
          dispatch('deleteUserFavourite', {
            resource_id: viewId,
            resource_type: ResourceType.View,
            title: view.name,
          });
        }
      })
      .catch((err: Error) => {
        commit('localAddView', { view, key, index });
        if (isFavourite) {
          commit('setFavourites', favourites);
        }
        throw err;
      });
  },

  localDeleteView({ commit }, { key, index }) {
    commit('localDeleteView', { key, index });
  },

  fetchBoardsFilters({ commit, getters }) {
    const { getRoutes, getTeamID } = getters;

    return authGet(`${getRoutes.search}/v1/${getTeamID}/search/boards`).then(({ data }) => {
      const { boards } = data;
      commit('setBoardsFilters', boards);
    });
  },
  /**
   * Adds a new UI notification to state.
   * @param {object} notificatin
   */
  addUiNotification({ commit }, notification) {
    const newNotification = {
      ...notification,
      id: notification.id ?? nanoid(4),
    };
    commit('addUiNotification', newNotification);
  },
  /**
   * Removes UI notification by id
   * @param {string} notificationId
   */
  removeUiNotificationById({ commit }, notificationId) {
    commit('removeUiNotificationById', notificationId);
  },
  setOpenedFavouriteItem({ commit }, favouriteItem) {
    commit('setOpenedFavouriteItem', favouriteItem);
  },
  setHeaderMemberDropdownPosition({ commit }, payload) {
    commit('setHeaderMemberDropdownPosition', payload);
  },

  setActiveComment({ commit }, payload) {
    commit('setActiveComment', payload);
  },

  setImportHistory({ commit }, value) {
    commit('setImportHistory', value);
  },

  watchResource({ dispatch }, { resourceType, resourceId, subscribe, message }) {
    let actionName = '';
    switch (resourceType) {
      case ResourceType.Board:
        actionName = 'watchBoard';
        break;
      case ResourceType.List:
        actionName = 'watchList';
        break;
      case ResourceType.Card:
        actionName = 'watchCard';
        break;
      case ResourceType.Page:
        actionName = 'watchPage';
        break;
    }

    if (actionName) {
      dispatch(actionName, { resourceId, subscribe, message });
    }
  },

  async watchPage({ getters, dispatch }, { resourceId, subscribe, message }) {
    const { getTeamID, getUserId } = getters;
    const localPageUpdatePayload = {
      pageId: resourceId,
      propertyName: 'is_watching',
      value: subscribe,
    };
    dispatch('localUpdatePageProp', localPageUpdatePayload);

    const successHandler = () => {
      dispatch('addUiNotification', {
        message: translate(message.success, { name: 'page' }),
        duration: 4000,
      });
    };

    const errHandler = () => {
      const revertPayload = {
        pageId: resourceId,
        propertyName: 'is_watching',
        value: !subscribe,
      };
      dispatch('localUpdatePageProp', revertPayload);
      dispatch('addUiNotification', {
        message: translate(message.error, { name: 'page' }),
        status: constants.uiNotificationStatuses.error,
      });
    };

    if (subscribe) {
      const body: AddWatcherRequest = { user_id: getUserId };
      await api.pages
        .addPageWatcher(getTeamID, resourceId, body)
        .then(successHandler)
        .catch(errHandler);
    } else {
      await api.pages
        .deletePageWatcher(getTeamID, resourceId, getUserId)
        .then(successHandler)
        .catch(errHandler);
    }
  },

  async watchCard({ getters, dispatch }, { resourceId, subscribe, message }) {
    const { getTeamID, getUserId } = getters;
    const localCardUpdatePayload = {
      cardId: resourceId,
      fieldName: 'is_watching',
      fieldValue: subscribe,
    };
    dispatch('localUpdateCardField', localCardUpdatePayload);

    const isEpicCard = getters.getCards[resourceId]?.board_id === constants.epicsBoardId;
    const resourceCardName = isEpicCard ? ResourceType.Project : ResourceType.Card;

    const successHandler = () => {
      dispatch('addUiNotification', {
        message: translate(message.success, { name: resourceCardName }),
        duration: 4000,
      });
    };

    const errHandler = () => {
      const revertPayload = {
        cardId: resourceId,
        fieldName: 'is_watching',
        fieldValue: !subscribe,
      };
      dispatch('localUpdateCardField', revertPayload);
      dispatch('addUiNotification', {
        message: translate(message.error, { name: resourceCardName }),
        status: constants.uiNotificationStatuses.error,
      });
    };

    if (subscribe) {
      const body: AddWatcherRequest = { user_id: getUserId };
      await api.boards
        .addCardWatcher(getTeamID, resourceId, body)
        .then(successHandler)
        .catch(errHandler);
    } else {
      await api.boards
        .deleteCardWatcher(getTeamID, resourceId, getUserId)
        .then(successHandler)
        .catch(errHandler);
    }
  },

  async watchBoard({ getters, dispatch }, { resourceId, subscribe, message }) {
    const { getTeamID, getUserId } = getters;
    const localBoardUpdatePayload = {
      boardId: resourceId,
      propertyName: 'is_watching',
      value: subscribe,
    };
    dispatch('updateBoardPropertyInBoards', localBoardUpdatePayload);

    const successHandler = () => {
      dispatch('addUiNotification', {
        message: translate(message.success, { name: 'board' }),
        duration: 4000,
      });
    };

    const errHandler = () => {
      const revertPayload = {
        boardId: resourceId,
        propertyName: 'is_watching',
        value: !subscribe,
      };
      dispatch('updateBoardPropertyInBoards', revertPayload);
      dispatch('addUiNotification', {
        message: translate(message.error, { name: 'board' }),
        status: constants.uiNotificationStatuses.error,
      });
    };

    if (subscribe) {
      const body: AddWatcherRequest = { user_id: getUserId };
      await api.boards
        .addBoardWatcher(getTeamID, resourceId, body)
        .then(successHandler)
        .catch(errHandler);
    } else {
      await api.boards
        .deleteBoardWatcher(getTeamID, resourceId, getUserId)
        .then(successHandler)
        .catch(errHandler);
    }
  },

  async watchList({ getters, dispatch }, { resourceId, subscribe, message }) {
    const { getTeamID, getUserId } = getters;
    const localListUpdatePayload = {
      listId: resourceId,
      propertyName: 'is_watching',
      value: subscribe,
    };
    dispatch('setBoardListProp', localListUpdatePayload);

    const successHandler = () => {
      dispatch('addUiNotification', {
        message: translate(message.success, { name: 'list' }),
        duration: 4000,
      });
    };

    const errHandler = () => {
      const revertPayload = {
        listId: resourceId,
        propertyName: 'is_watching',
        value: !subscribe,
      };
      dispatch('setBoardListProp', revertPayload);
      dispatch('addUiNotification', {
        message: translate(message.error, { name: 'list' }),
        status: constants.uiNotificationStatuses.error,
      });
    };

    if (subscribe) {
      const body: AddWatcherRequest = { user_id: getUserId };
      await api.boards
        .addListWatcher(getTeamID, resourceId, body)
        .then(successHandler)
        .catch(errHandler);
    } else {
      await api.boards
        .deleteListWatcher(getTeamID, resourceId, getUserId)
        .then(successHandler)
        .catch(errHandler);
    }
  },

  fetchProjectData({ getters }, { teamId, resourceId }) {
    return api.projects
      .getProject(teamId, resourceId)
      .then(({ data }) => data.project)
      .catch((error) => {
        throw error;
      });
  },

  setFavouritesOpened({ commit }, value) {
    commit('setFavouritesOpened', value);
  },

  moveBoardHandler(
    { commit, getters, dispatch },
    { destinationProjectId, originalProjectId, destinationBoardOrder, originalBoardOrder, boardId }
  ) {
    const projectExpanded = getters.getProjectExpanded(
      `${getters.getTeamSlug}-${destinationProjectId}`
    );
    if (projectExpanded) {
      dispatch('loadBoards', destinationProjectId);
    }
    if (!getters.getFlatBoards[boardId]?.lists) {
      dispatch('fetchBoard', { boardId });
    }

    if (getters.currentBoardId === boardId) {
      dispatch('setCurrentProjectId', destinationProjectId);
    }

    commit('localUpdateProjectBoardsOrder', {
      projectId: destinationProjectId,
      payload: destinationBoardOrder,
    });
    commit('localUpdateProjectBoardsOrder', {
      projectId: originalProjectId,
      payload: originalBoardOrder,
    });

    commit('setProjectBoards', {
      projectId: destinationProjectId,
      projectBoards: destinationBoardOrder,
    });

    commit('setProjectBoards', {
      projectId: originalProjectId,
      projectBoards: originalBoardOrder,
    });

    commit('updateBoardPropertyInBoards', {
      boardId,
      propertyName: 'project_id',
      value: destinationProjectId,
    });
  },

  localAddPageInProject({ commit, getters }, { projectId, page }) {
    if (!projectId || !page) return;
    const projectForUpdate = getters.getTeamProjects.find((p: any) => p.id === projectId);
    const newPage = {
      id: page.id,
      title: page.title,
      ...(page.icon && { icon: page.icon }),
    };
    if (!projectForUpdate.pages) return;
    projectForUpdate.pages.push(newPage);
    commit('updateProjectById', projectForUpdate);
  },

  localUpdateSprintInProject({ commit, getters }, { id, changes }) {
    const unfinishedCards: any[] = [];
    const cardIds: string[] = [];
    changes.forEach((change: any) => {
      const [resource] = change.path;
      if (resource === 'lists') return;
      if (change.type === 'update') {
        commit('updateBoardPropertyInBoards', {
          boardId: id,
          propertyName: resource,
          value: change.to,
        });
      }
      if (change.type === 'create' && resource === 'unfinished_cards') {
        unfinishedCards.push(change.to);
        cardIds.push(change.to.id);
      }
    });
    if (unfinishedCards.length) {
      setTimeout(() => {
        const rolledoverList = {
          id: `${id}-rolledover`,
          title: 'Rolled over',
          board_id: id,
          team_id: getters.getTeamID,
          card_order: cardIds,
          cards: unfinishedCards,
          behavior: 'rolledover',
        };
        commit('updateBoardPropertyInBoards', {
          boardId: id,
          propertyName: 'unfinished_cards',
          value: unfinishedCards,
        });
        commit('addBoardListId', {
          boardId: id,
          listId: `${id}-rolledover`,
        });
        commit('setSingleListInListCards', { listId: `${id}-rolledover`, value: cardIds });
        commit('setBoardListsFlat', [rolledoverList]);
      }, 300);
    }
  },

  localUpdateProject({ commit, getters, dispatch }, { id, changes }) {
    const project = getters.getTeamProjectById(id) || {};
    const projectString = JSON.stringify(project);

    let newProject = JSON.parse(projectString);
    const newPageTree: any = {};

    changes.forEach((change: any) => {
      if (
        ['page_order', 'archive_page_order', 'archive_pages', 'pages', 'boards'].includes(
          change.path[0]
        )
      ) {
        newProject = updateBoardPageTitle(
          newProject,
          change.type,
          change.path,
          change.to,
          change.from,
          dispatch,
          getters
        );
      } else {
        newProject = objectSet(newProject, change.path, change.to);
      }
    });

    commit('updateProjectById', newProject);
    [...(newProject.page_order || [])].forEach((p) => {
      newPageTree[p.id] = p;
    });

    /* commit('setProjectPagesTree', {
      projectId: newProject.id,
      pagesTree: { ...getters.getProjectPagesTree(newProject.id), ...newPageTree },
    }); */

    if (project.board_order !== newProject.board_order) {
      commit('setProjectBoards', {
        projectId: id,
        projectBoards: newProject.board_order,
      });
    }
  },

  setCurrentRoute({ commit }, route) {
    commit('setCurrentRoute', route);
  },

  setSocketConnected({ commit }, value) {
    commit('setSocketConnected', value);
  },

  setDisplayVideoLinkPopup({ commit }, value) {
    commit('setDisplayVideoLinkPopup', value);
  },

  createBoardWebhookNotification({ getters, commit }, { boardId, integration, integrationId }) {
    const { getTeamID } = getters;

    return api.boards
      .createWebhookNotification(getTeamID, boardId, {
        destination_type: integration,
        integration_id: integrationId,
      })
      .then(({ data }) => {
        commit('updateBoardPropertyInBoards', {
          boardId,
          propertyName: 'webhook_notifications',
          value: data.board?.webhook_notifications,
        });
      });
  },

  deleteBoardWebhookNotification({ getters }, { boardId, webhookDestination }) {
    const { getTeamID } = getters;

    return api.boards.deleteWebhookNotification(getTeamID, boardId, webhookDestination);
  },

  createInstallationForIntegration({ getters }, { integration, payload }) {
    const { getRoutes, getTeamID } = getters;
    return authPost(
      `${getRoutes.integrations}/v1/${getTeamID}/integrations/${integration}/installations`,
      payload
    );
  },

  deleteInstallationForIntegration({ getters }, { integration, installationId }) {
    const { getRoutes, getTeamID } = getters;
    return authDelete(
      `${getRoutes.integrations}/v1/${getTeamID}/integrations/${integration}/installations/${installationId}`
    );
  },

  getTeamIntegrations({ getters }) {
    const { getRoutes, getTeamID } = getters;
    return authGet(`${getRoutes.integrations}/v1/${getTeamID}/integrations`).then(
      ({ data }) => data
    );
  },

  getTeamIntegrationInstallations({ commit, getters }, { integration }) {
    const { getRoutes, getTeamID } = getters;
    return authGet(
      `${getRoutes.integrations}/v1/${getTeamID}/integrations/${integration}/installations`
    ).then(({ data }) => {
      commit('setInstallations', {
        integration,
        installations: data.installations,
      });
    });
  },

  toggleBoardWebhookNotificationOption(
    { getters, commit },
    { boardId, webhookDestination, payload }
  ) {
    const { getTeamID } = getters;

    return api.boards
      .updateWebhookNotificationBoard(getTeamID, boardId, webhookDestination, payload)
      .then(({ data }) => {
        commit('updateBoardPropertyInBoards', {
          boardId,
          propertyName: 'webhook_notifications',
          value: data.board?.webhook_notifications,
        });
      });
  },

  toggleListWebhookNotificationOption(
    { getters, dispatch },
    { listId, webhookDestination, payload }
  ) {
    const { getTeamID } = getters;

    return api.boards
      .updateWebhookNotificationList(getTeamID, listId, webhookDestination, payload)
      .then(({ data }) => {
        dispatch('setBoardListProp', {
          listId,
          propertyName: 'webhook_notifications',
          value: data.list?.webhook_notifications,
        });
      });
  },

  setUpImport({ getters }, value) {
    return api.importer
      .importsCreate(getters.getTeamID, value)
      .then(({ data }) => data.import)
      .catch((error) => {
        throw error;
      });
  },

  updateImportSettings({ getters }, { value, importId }) {
    return authPatch(
      `${getters.getRoutes.projects}/v1/${getters.getTeamID}/imports/${importId}`,
      value
    )
      .then(({ data }) => data)
      .catch((error) => {
        throw error;
      });
  },

  startImportProcess({ getters }, importId) {
    return authPost(
      `${getters.getRoutes.projects}/v1/${getters.getTeamID}/imports/${importId}/queue`
    )
      .then((data) => data)
      .catch((e) => {
        throw e;
      });
  },

  getImportHistory({ getters }) {
    return authGet(`${getters.getRoutes.projects}/v1/${getters.getTeamID}/imports`)
      .then(({ data }) => data.imports)
      .catch((error) => {
        throw error;
      });
  },

  setImportProgressSteps({ commit }, payload) {
    commit('setImportProgressSteps', payload);
  },

  updateImportProgressSteps({ commit }, { step, propertyName, value }) {
    commit('updateImportProgressSteps', { step, propertyName, value });
  },

  setRouteBeforeSettings({ commit }, value) {
    commit('setRouteBeforeSettings', value);
  },

  setImportProgressCurrentStep({ commit }, value) {
    commit('setImportProgressCurrentStep', value);
  },

  setSettingsCurrentProjectId({ commit }, id: string) {
    commit('setSettingsCurrentProjectId', id);
  },

  setCachedComponents({ commit }, components) {
    commit('setCachedComponents', components);
  },

  setSettingsCurrentBoardId({ commit }, id: string) {
    commit('setSettingsCurrentBoardId', id);
  },
  getTeamIntegrationsSettings({ getters }, integration) {
    const { getRoutes, getTeamID } = getters;
    return authGet(
      `${getRoutes.integrations}/v1/${getTeamID}/integrations/${integration}/settings`
    ).then(({ data }) => data);
  },

  updateIntegrationsSettings({ getters }, { integration, payload }) {
    return authPatch(
      `${getters.getRoutes.projects}/v1/${getters.getTeamID}/integrations/${integration}/settings`,
      {
        [integration]: payload,
      }
    )
      .then(({ data }) => data)
      .catch((error) => {
        throw error;
      });
  },

  setIntegrationSettings({ commit }, settings) {
    commit('setIntegrationSettings', settings);
  },

  updateLocalIntegrationSettings({ commit }, payload) {
    commit('updateLocalIntegrationSettings', payload);
  },

  fetchIntegrationDetails({ getters }, { client_id }) {
    return authGet(`${getters.getRoutes.integrations}/oauth2/clients/${client_id}`)
      .then(({ data }) => data)
      .catch((error) => error);
  },

  setExpandedProject({ commit }, { id, value }) {
    commit('setExpandedProject', { id, value });
  },

  setBoardsColumnLayout(
    { commit, getters },
    { layout, projectId = getters.getCurrentProjectId, teamSlug = getters.getTeamSlug }
  ) {
    commit('setBoardsColumnLayout', { layout, projectId, teamSlug });
  },

  setPagesColumnLayout(
    { commit, getters },
    { layout, projectId = getters.getCurrentProjectId, teamSlug = getters.getTeamSlug }
  ) {
    commit('setPagesColumnLayout', { layout, projectId, teamSlug });
  },

  setProjectsExpanded({ commit }, value) {
    commit('setProjectsExpanded', value);
  },

  setFavouritesExpanded({ commit }, value) {
    commit('setFavouritesExpanded', value);
  },

  setDraggingEntityProjectFromId({ commit }, id) {
    commit('setDraggingEntityProjectFromId', id);
  },

  setDraggingSidebarItem({ commit }, value) {
    commit('setDraggingSidebarItem', value);
  },

  localAddProjectBoardOrder({ commit }, { projectId, boardId }) {
    commit('localAddProjectBoardOrder', { projectId, boardId });
  },

  localAddPageInTree({ commit }, { projectId, pageId }) {
    commit('localAddPageInTree', { projectId, pageId });
  },

  setSelectedProjectId({ commit }, projectId) {
    commit('setSelectedProjectId', projectId);
  },

  localRemovePageInTree({ commit }, { projectId, pageId }) {
    commit('localRemovePageFromPageOrder', { projectId, pageId });
    commit('localRemoveBoardOrPageInProject', {
      projectId,
      itemId: pageId,
      section: 'pages',
    });
  },

  localRemoveBoardFromOrder({ commit }, { projectId, boardId }) {
    commit('localRemoveBoardFromOrder', { projectId, boardId });
    commit('localRemoveBoardOrPageInProject', {
      projectId,
      itemId: boardId,
      section: 'boards',
    });
  },

  setSdProjectSettingsDropdown({ commit }, value) {
    commit('setSdProjectSettingsDropdown', value);
  },

  setSdProjectNewPageBoardDropdown({ commit }, value) {
    commit('setSdProjectNewPageBoardDropdown', value);
  },

  setHeaderProjectSettingsDropdown({ commit }, value) {
    commit('setHeaderProjectSettingsDropdown', value);
  },

  setCardsLayoutOptionsDropdown({ commit }, { value, boardId }) {
    commit('setCardsLayoutOptionsDropdown', { value, boardId });
  },

  setSdPageSettingsDropdown({ commit }, value) {
    commit('setSdPageSettingsDropdown', value);
  },

  setSdBoardSettingsDropdown({ commit }, value) {
    commit('setSdBoardSettingsDropdown', value);
  },

  setSdUserActionsDropdown({ commit }, value) {
    commit('setSdUserActionsDropdown', value);
  },
  /**
   * Get pending invites for a team
   * https://api.superthread.com/v1/users/{user_id}/pending_invites
   */
  getTeamPendingInvities({ commit, getters }) {
    return authGet(`${getters.getRoutes.users}/v1/teams/${getters.getTeamID}/invites`)
      .then(({ data }) => commit('setPendingInvites', data))
      .catch((error) => error);
  },

  deleteTeamInvite({ getters }, payload) {
    return authDelete(`${getters.getRoutes.users}/v1/teams/${getters.getTeamID}/invites`, {
      data: { invitee_emails: payload },
    })
      .then(({ data }) => data)
      .catch((error) => {
        throw error;
      });
  },

  deleteTeamMember({ getters }, userId) {
    return authDelete(`${getters.getRoutes.users}/v1/teams/${getters.getTeamID}/members/${userId}`)
      .then(({ data }) => data)
      .catch((error) => {
        throw error;
      });
  },

  updateTeamMember({ commit, getters }, { userId, payload }) {
    return authPatch(
      `${getters.getRoutes.projects}/v1/teams/${getters.getTeamID}/members/${userId}`,
      {
        role: payload,
      }
    )
      .then(({ data }) => {
        commit('localUpdateTeamMember', { userId, updatedFields: data?.member || {} });
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },

  resendTeamInvite({ getters }, payload) {
    return authPost(
      `${getters.getRoutes.projects}/v1/teams/${getters.getTeamID}/invites/resend`,
      payload
    )
      .then((data) => data)
      .catch((e) => {
        throw e;
      });
  },

  deleteProject({ getters, dispatch, commit }, projectId: string) {
    const project = getters.getTeamProjectById(projectId);
    const oldProjectOrder = [...getters.getProjectOrder];
    const oldTrashedProjects = [...getters.getTrashProjects];

    dispatch('localRemoveProject', project.id);

    return api.projects.trashProject(getters.getTeamID, project.id).catch((err) => {
      commit('setTrashProjects', oldTrashedProjects);
      dispatch('localAddProjectToTeamProjects', project);
      dispatch('setProjectOrder', oldProjectOrder);
      throw err;
    });
  },

  deletePopupId({ commit }, payload) {
    commit('deletePopupId', payload);
  },

  setCurrentRole({ commit }, payload) {
    commit('setCurrentRole', payload);
  },

  setDoubleCheckPopup({ commit }, payload) {
    commit('setDoubleCheckPopup', payload);
  },

  setConfirmationModal({ commit }, payload) {
    commit('setConfirmationModal', payload);
  },

  setColorsPopup({ commit }, payload) {
    commit('setColorsPopup', payload);
  },

  setCommentsOrder({ commit }, payload) {
    commit('setCommentsOrder', payload);
  },

  loadActivity({ getters }, payload) {
    return api.comments
      .activityDetail(getters.getTeamID, payload)
      .then(({ data }) => data)
      .catch((error) => error);
  },
  closeEditor({ commit }, payload) {
    commit('closeEditor', payload);
  },
  editorOpen({ commit }, payload) {
    commit('editorOpen', payload);
  },

  setQuickCard({ commit }, payload: boolean) {
    commit('setQuickCard', payload);
  },

  setQuickCardBoardId({ commit }, payload) {
    commit('setQuickCardBoardId', payload);
  },

  setQuickPage({ commit }, value) {
    commit('setQuickPage', value);
  },

  setNoteInQuickPage({ commit }, value) {
    commit('setNoteInQuickPage', value);
  },

  setQuickNote({ commit }, value) {
    commit('setQuickNote', value);
  },

  setCardCommentActivityFilter({ commit }, payload) {
    commit('setCardCommentActivityFilter', payload);
  },

  addCommentInProgressPatch({ commit }, payload) {
    commit('addCommentInProgressPatch', payload);
  },

  removeCommentInProgressPatch({ commit }, payload) {
    commit('removeCommentInProgressPatch', payload);
  },

  addCommentInProgressDelete({ commit }, payload) {
    commit('addCommentInProgressDelete', payload);
  },

  removeCommentInProgressDelete({ commit }, payload) {
    commit('removeCommentInProgressDelete', payload);
  },

  setOnline({ commit }, value) {
    commit('setOnline', value);
  },

  addLoadedFile({ commit }, payload) {
    commit('addLoadedFile', payload);
  },

  setProjectPreviewLastScrollPos({ commit }, { projectId, scrollPosition }) {
    commit('setProjectPreviewLastScrollPos', { projectId, scrollPosition });
  },

  fetchFavourites({ commit, getters }) {
    return api.favourites
      .getFavourites(getters.getTeamID)
      .then(({ data }: any) => {
        if (data) {
          commit('setFavourites', data.favourites);
          return data.favourites;
        }
      })
      .catch((err: any) => err);
  },

  setFavourites({ commit }, favourites) {
    commit('setFavourites', favourites);
  },

  addFavourite({ commit, getters, dispatch }, item) {
    const { getFavourites } = getters;
    const payload = {
      ...(item.parent_id && { parent_id: item.parent_id }),
      position: item.position,
      title: item.title,
      resource_type: item.resource_type,
      resource_id: item.resource_id,
    };
    const favourites = getFavourites;

    commit('addInFavourites', payload);

    if (item.resource_type === ResourceType.Sprint && item?.state === SprintState.Active) {
      payload.resource_id = `${item.project_id}s0`;
    }

    return api.favourites
      .addFavourite(getters.getTeamID, payload)
      .then(({ data }: any) => {
        if (data) {
          commit('setFavourites', data.favourites);
          dispatch('addUiNotification', {
            message: `${translate(payload.resource_type)} ${translate('addedSuccesfully')}`,
            icon: 'star',
            duration: 4000,
          });
          return data.favourites;
        }
      })
      .catch((err: any) => {
        commit('setFavourites', favourites);
      });
  },

  setFavouritesStettingsDropdown({ commit }, id) {
    commit('setFavouritesStettingsDropdown', id);
  },

  localRemoveFromFavourites({ commit }, { resourceId, resourceType }) {
    commit('localRemoveFromFavourites', {
      resourceId,
      resourceType,
    });
  },

  addInRootFavourite({ commit }, resource) {
    commit('addInFavourites', resource);
  },

  addInParentFavourite({ commit }, { item, parentId, position = -1 }) {
    commit('addInParentFavourite', { item, parentId, position });
  },

  deleteUserFavourite({ commit, getters, dispatch }, resource) {
    const { getTeamID, getFavourites } = getters;
    const favourites = getFavourites;

    commit('localRemoveFromFavourites', {
      resourceId: resource.resource_id,
      resourceType: resource.resource_type,
    });

    let resourceIdForRequest = `${resource.resource_type}:${resource.resource_id}`;
    if (resource.resource_type === ResourceType.Sprint && resource?.state === SprintState.Active) {
      resourceIdForRequest = `${resource.resource_type}:${resource.project_id}s0`;
    }

    return api.favourites
      .deleteFavourite(getTeamID, resourceIdForRequest)
      .then(() => {
        dispatch('addUiNotification', {
          message: `${translate(resource.resource_type)} ${translate('deletedSuccesfully')}`,
          icon: 'unfavourite',
          duration: 4000,
        });
      })
      .catch((err: any) => {
        commit('setFavourites', favourites);
      });
  },

  localUpdateResourceInFavourites({ commit }, resource) {
    commit('localUpdateResourceInFavourites', resource);
  },

  updateFavourite({ commit, getters }, { resource, payload }) {
    const { getTeamID, getFavourites } = getters;
    const favourites = getFavourites;
    let resourceForUpdateKey = `${resource.resource_type}:${resource.resource_id}`;

    if (resource.resource_type === ResourceType.Sprint) {
      const sprint = getters.getFlatBoards[resource.resource_id];
      if (sprint?.state === SprintState.Active) {
        resourceForUpdateKey = `${resource.resource_type}:${sprint.project_id}s0`;
      }
    }

    const resourceForUpdate = {
      ...resource,
      ...payload,
    };

    if (!payload.position) {
      commit('localUpdateResourceInFavourites', resourceForUpdate);
    }

    return api.favourites
      .updateFavourite(getTeamID, resourceForUpdateKey, payload)
      .then(({ data }: any) => {
        commit('setFavourites', data.favourites);
      })
      .catch((err: any) => {
        commit('setFavourites', favourites);
      });
  },

  setFavouritesFolderExpand({ commit }, { id, value }) {
    commit('setFavouritesFolderExpand', { id, value });
  },

  toggleCommentReaction({ commit, getters }, payload) {
    commit('toggleCommentReaction', payload);
    const { getTeamID } = getters;
    const { commentId, reaction } = payload;
    let removeProgressTimeout: any = null;
    // set as in progress so activity pooling does not override the change
    commit('addCommentInProgressPatch', commentId);

    const body: CreateCommentReactionRequest = {
      unicode: reaction.native,
      variation: reaction.colons,
    };

    return api.comments
      .createCommentReaction(getTeamID, commentId, reaction.id, body)
      .catch(() => {
        commit('toggleCommentReaction', payload);
      })
      .finally(() => {
        // remove in progress so activity pooling can override the change
        removeProgressTimeout = setTimeout(() => {
          commit('removeCommentInProgressPatch', commentId);
          clearTimeout(removeProgressTimeout);
        }, 1000);
      });
  },

  removeCommentReaction({ commit, getters }, payload) {
    commit('toggleCommentReaction', payload);
    const { getTeamID } = getters;
    const { commentId, reaction } = payload;
    let removeProgressTimeout: any = null;

    // set as in progress so activity pooling does not override the change
    commit('addCommentInProgressPatch', commentId);

    return api.comments
      .deleteCommentReaction(getTeamID, commentId, reaction.id)
      .catch(() => {
        commit('toggleCommentReaction', payload);
      })
      .finally(() => {
        // remove in progress so activity pooling can override the change
        removeProgressTimeout = setTimeout(() => {
          commit('removeCommentInProgressPatch', commentId);
          clearTimeout(removeProgressTimeout);
        }, 1000);
      });
  },

  setEmojiPickerDisplayed({ commit }, payload) {
    commit('setEmojiPickerDisplayed', payload);
  },

  setNewFolderInput({ commit }, value) {
    commit('setNewFolderInput', value);
  },

  setFavouriteFolderForRename({ commit }, value) {
    commit('setFavouriteFolderForRename', value);
  },

  setFavouriteFolderTitle({ commit }, value) {
    commit('setFavouriteFolderTitle', value);
  },

  showPeekSidebar({ commit }, value) {
    commit('showPeekSidebar', value);
  },

  setTheme({ commit }, value) {
    commit('setTheme', value);
  },
  addToElectronBrowseHistory({ commit }, payload) {
    commit('addToElectronBrowseHistory', payload);
  },
  setElectronBrowseHistory({ commit }, value) {
    commit('setElectronBrowseHistory', value);
  },
  setElectronBrowseHistoryIndex({ commit }, value) {
    commit('setElectronBrowseHistoryIndex', value);
  },
  setElectronRecentlyViewed({ commit }, value) {
    commit('setElectronRecentlyViewed', value);
  },
  addToElectronRecentlyViewed({ commit }, payload) {
    commit('addToElectronRecentlyViewed', payload);
  },
  removeFromElectronRecentlyViewed({ commit }, payload) {
    commit('removeFromElectronRecentlyViewed', payload);
  },
  updateItemInElectronRecentlyViewed({ commit }, payload) {
    commit('updateItemInElectronRecentlyViewed', payload);
  },
  setThemePreference({ commit }, value) {
    commit('setThemePreference', value);
  },

  setCurrentInboxResource({ commit }, value) {
    commit('setCurrentInboxResource', value);
  },

  setInboxFilters({ commit }, value) {
    commit('setInboxFilters', value);
  },

  setInboxListWidth({ commit }, value) {
    commit('setInboxListWidth', value);
  },
  setInboxListExpanded({ commit }, value) {
    commit('setInboxListExpanded', value);
  },

  setShowOnboarding({ commit }, value) {
    commit('setShowOnboarding', value);
  },

  setIconPickerDropdown({ commit }, value) {
    commit('setIconPickerDropdown', value);
  },

  setManageProjectsItemSettingsDropdown({ commit }, value) {
    commit('setManageProjectsItemSettingsDropdown', value);
  },

  setOpenInDesktopApp({ commit }, value) {
    commit('setOpenInDesktopApp', value);
  },

  setShowRedirectedToAppOverlay({ commit }, value) {
    commit('setShowRedirectedToAppOverlay', value);
  },

  updateFile({ getters }, { fileId, payload }) {
    if (!fileId) return Promise.reject(new Error('invalid file id'));
    return api.files.updateFile(getters.getTeamID, fileId, payload);
  },

  setMembersDropdownInvitees({ commit }, value) {
    commit('setMembersDropdownInvitees', value);
  },

  addMembersDropdownInvitee({ commit }, value) {
    commit('addMembersDropdownInvitee', value);
  },

  removeMembersDropdownInvitee({ commit }, value) {
    commit('removeMembersDropdownInvitee', value);
  },

  setEditorForCardLink({ commit }, value) {
    commit('setEditorForCardLink', value);
  },

  /**
   * Sets a selected tab in for 'my work' page for each individual user
   * Default value is AssignedToMe
   * @param {string} tabId
   * @param {string} userId
   */
  setMyWorkSelectedTabId({ commit }, { userId, tabId }) {
    commit('setMyWorkSelectedTabId', { userId, tabId });
  },

  /**
   * Sets a selected userId for which 'my-work' page is displayed
   * @param {string} userId
   */
  setMyWorkUserId({ commit }, userId) {
    commit('setMyWorkUserId', userId);
  },

  /**
   * Sets a userId for displaying member's three dot dropdown in MembersManagement
   * @param {string} value
   */
  setManageMembersItemSettingsDropdown({ commit }, value) {
    commit('setManageMembersItemSettingsDropdown', value);
  },

  /**
   * Sets a user id for which hover preview is displayed
   * @param {string} value
   */
  setMemberHoverPreview({ commit }, value) {
    commit('setMemberHoverPreview', value);
  },

  /**
   * Sets a userId for displaying member's three dot dropdown in InfoSidebar
   * @param {string} value
   */
  setInfoSidebarMemberItemSettingsDropdown({ commit }, value) {
    commit('setInfoSidebarMemberItemSettingsDropdown', value);
  },

  fetchArchivedBoardsByWorkspace({ getters, commit }) {
    const { getRoutes, getTeamID } = getters;

    return authGet(`${getRoutes.search}/v1/${getTeamID}/search/boards?archived=true`).then(
      ({ data }) => {
        const { boards } = data;
        const archivedBoards = boards.filter((board: any) => board.archived);
        commit('setFlatBoards', archivedBoards);
      }
    );
  },

  /**
   * Adds a card to first place in array of current view results for views/my work/members page
   * @param {object} value { card }
   */
  addCardToCurrentViewResults({ commit }, value) {
    commit('addCardToCurrentViewResults', value);
  },

  /**
   * Adds a created tippy instance for member hover preview.
   * @param {object}
   */
  addMemberHoverPreviewInstance({ commit }, instance) {
    commit('addMemberHoverPreviewInstance', instance);
  },

  /**
   * Removes a tippy instance from store when it gets destroyed.
   * @param {object}
   */
  removeMemberHoverPreviewInstance({ commit }, instance) {
    commit('removeMemberHoverPreviewInstance', instance);
  },
  setPressedEscCancelDrag({ commit }, value) {
    commit('setPressedEscCancelDrag', value);
  },

  setSidebarHover({ commit }, value) {
    commit('setSidebarHover', value);
  },

  setSidebarDragging({ commit }, value) {
    commit('setSidebarDragging', value);
  },

  /**
   * Sets flag for displaying the 'Update email' popup
   * @param {string} type - type of currently displayed popup ('reauthenticate', 'input' or 'verify')
   */
  setShowUpdateEmailPopup({ commit }, type: string) {
    commit('setShowUpdateEmailPopup', type);
  },

  async AiTask({ commit, getters }, payload) {
    const response = await fetch(`${getters.getRoutes.projects}/v1/${getters.getTeamID}/ai/llm`, {
      method: 'POST',
      body: JSON.stringify(payload),
      credentials: 'include',
      headers: {
        Accept: 'text/event-stream',
      },
    });

    return response;
  },

  getAllTeamFeatures({ commit, getters }) {
    return api.auth.getTeamFeatures(getters.getTeamID).then(({ data }) => {
      commit('setTeamFeatures', data?.features);
      return data;
    });
  },

  setTeamFeatures({ commit }, features) {
    commit('setTeamFeatures', features);
  },

  toggleTeamFeature({ commit, getters }, { featureId, value }) {
    const oldValue = getters.getTeamFeature(featureId);
    commit('updateTeamFeature', { featureId, feature: { ...oldValue, enabled: true } });
    return authPut(
      `${getters.getRoutes.users}/v1/teams/${getters.getTeamID}/features/${featureId}`,
      {
        enabled: value,
      }
    )
      .then(({ data }) => {
        authRefresh().then(() => {
          api.auth.getUser('me').then(({ data }) => {
            commit('setUser', data.user);
          });
        });
        commit('updateTeamFeature', { featureId, feature: data?.feature });
      })
      .catch(() => {
        commit('updateTeamFeature', { featureId, feature: oldValue });
      });
  },

  updateTeamFeature({ commit }, { featureId, feature }) {
    commit('updateTeamFeature', { featureId, feature });
  },

  setPageIconPickerDisplayed({ commit }, value) {
    commit('setPageIconPickerDisplayed', value);
  },

  setHideEmptyGroups({ commit, getters }, { value, boardId = getters.currentBoardId }) {
    const teamId = getters.getTeamID;
    if (!teamId || !boardId) return;
    const v: boolean =
      value ?? !!browserStorageGetItem<string>(hideEmptyGroupByBoardKey(teamId, boardId));

    browserStorageSetItem(hideEmptyGroupByBoardKey(teamId, boardId), `${v}`);
    commit('setHideEmptyGroups', { value: v, boardId });
  },

  setShowWeekends({ commit, getters }, payload: boolean) {
    const teamId = getters.getTeamID;
    const currentBoardId = getters.currentBoardId;
    if (!teamId || !currentBoardId) return;
    const value: boolean =
      payload ?? !!browserStorageGetItem<string>(showWeekendsByBoardKey(teamId, currentBoardId));
    browserStorageSetItem(showWeekendsByBoardKey(teamId, currentBoardId), value);
    commit('setShowWeekends', value);
  },

  addReconnectionCallback({ commit }, { callbackId, callback }) {
    commit('addReconnectionCallback', { callbackId, callback });
  },

  removeReconnectionCallback({ commit }, callbackId) {
    commit('removeReconnectionCallback', callbackId);
  },

  callReconnectionCallbacks({ commit, getters }) {
    const { getReconnectionCallbacks } = getters;
    Object.keys(getReconnectionCallbacks || []).forEach((key) => {
      const callback = getReconnectionCallbacks[key];
      if (typeof callback === 'function') {
        callback();
      }
    });
  },

  previousNextButtonClicked({ commit }, value) {
    commit('previousNextButtonClicked', value);
  },

  setShowTemplatesDropdown({ commit }, value) {
    commit('setShowTemplatesDropdown', value);
  },

  /**
   * Updates user timezone in store and in backend
   * @param timezone_id {string} - new timezone id
   */
  updateUserTimezoneId({ commit }, timezone_id) {
    return api.auth
      .updateUser('me', {
        timezone_id,
      })
      .then(() => commit('setUserTimezoneId', timezone_id))
      .catch((err: Error) => {
        throw err;
      });
  },

  /**
   * Fetches notification digest for current team
   */
  fetchNotificationDigest({ getters, commit }) {
    return api.activity
      .getNotificationDigest(getters.getTeamID)
      .then(({ data }) => {
        commit('updateNotificationDigest', data?.digest);
      })
      .catch((err: Error) => {
        throw err;
      });
  },

  /**
   * Sets notification digest for current team
   */
  updateNotificationDigest({ getters, commit }, payload: UpdateNotificationDigestRequest) {
    const oldNotifDigest = getters.getNotificationDigest;
    commit('updateNotificationDigest', payload);
    return api.activity.updateNotificationDigest(getters.getTeamID, payload).catch((err: Error) => {
      commit('updateNotificationDigest', oldNotifDigest);
      throw err;
    });
  },

  /**
   * Sets notification digest settings for current team
   */
  fetchNotificationDigestSettings({ getters, commit }) {
    return api.activity
      .getNotificationDigestSettings(getters.getTeamID)
      .then(({ data }) => {
        commit('updateNotificationDigestSettings', data);
      })
      .catch((err: Error) => {
        throw err;
      });
  },

  /**
   * Sets notification digest settings for current team
   */
  updateNotificationDigestSettings(
    { getters, commit },
    payload: UpdateNotificationDigestSettingsRequest
  ) {
    const oldNotifDigestSettings = getters.getNotificationDigestSettings;
    commit('updateNotificationDigestSettings', payload);
    return api.activity
      .updateNotificationDigestSettings(getters.getTeamID, payload)
      .catch((err: Error) => {
        commit('updateNotificationDigestSettings', oldNotifDigestSettings);
        throw err;
      });
  },

  setBoardEmbedPopup({ commit }, payload) {
    commit('setBoardEmbedPopup', payload);
  },

  async askQuestionAi({ commit, getters }, payload) {
    const response = await fetch(`${getters.getRoutes.projects}/v1/${getters.getTeamID}/ai/ask`, {
      method: 'POST',
      body: JSON.stringify(payload),
      credentials: 'include',
      headers: {
        Accept: 'text/event-stream',
      },
    });

    return response;
  },

  updatePageInTeamProject({ commit, getters }, { projectId, pageId, field, value }) {
    commit('updatePageInTeamProject', { projectId, pageId, field, value });
  },

  addPageToTeamProject({ commit, getters }, { projectId, page }) {
    commit('addProjectPage', { page, projectId });
  },

  removePageFromTeamProject({ commit, getters }, { pageId, projectId }) {
    commit('removeProjectPage', { pageId, projectId });
  },

  setIsSettingsOpen({ commit }, value) {
    commit('setIsSettingsOpen', value);
  },

  setShowUpgradePlanPopup({ commit }, value) {
    commit('setShowUpgradePlanPopup', value);
  },

  setMyWorkSortBy({ commit, getters }, payload) {
    commit('setMyWorkSortBy', { teamId: getters.getTeamID, ...payload });
  },

  setMyWorkSortByForAllUsers({ commit }, payload) {
    commit('setMyWorkSortByForAllUsers', payload);
  },

  localRemoveTeamMember({ commit }, userId) {
    commit('localRemoveTeamMember', userId);
  },

  setModal({ commit }, payload: Modal) {
    commit('setModal', payload);
  },

  setRecentWorkspaces({ commit }, workspace) {
    commit('setRecentWorkspaces', workspace);
  },

  updateRecentWorkspace({ commit }, { workspaceId, updatedFields }) {
    commit('updateRecentWorkspace', { workspaceId, updatedFields });
  },

  setOpenWorkspaceThroughQuickSwitcher({ commit }, value) {
    commit('setOpenWorkspaceThroughQuickSwitcher', value);
  },

  setSidebarSprintsExpanded({ commit, getters }, { projectId, value }) {
    const teamSlug = getters.getTeamSlug;
    commit('setSidebarSprintsExpanded', { teamSlug, projectId, value });
  },

  localAddSprintToProject({ commit }, { projectId, sprint }) {
    commit('localAddSprintToProject', { projectId, sprint });
  },

  setNote({ commit }, note) {
    commit('setNote', note);
  },

  getNotes({ dispatch, getters }, { skipSetNotes } = { skipSetNotes: false }) {
    return api.pages.getNotes(getters.getTeamID).then(({ data }) => {
      const notes = (data?.notes || []).reduce((acc: any, note: any, index: any) => {
        acc[note.id] = { ...note };
        return acc;
      }, {});
      if (!skipSetNotes) dispatch('setNotes', notes);
      return notes;
    });
  },

  setNotes({ commit }, notes) {
    commit('setNotes', notes);
  },

  updateNoteTitle({ commit, getters }, { noteId, title }) {
    return api.pages.updateNote(getters.getTeamID, noteId, { title });
  },

  updateNoteContent({ commit, getters }, { noteId, fieldType, content }) {
    return api.pages.updateNote(getters.getTeamID, noteId, {
      [NoteContentType.UserNotes]: content,
    });
  },

  updateAiNoteContent({ commit, getters }, { noteId, aiNoteId, content }) {
    return api.pages.updateEnhancedNote(getters.getTeamID, noteId, aiNoteId, { content });
  },

  createNewNote({ commit, getters, dispatch }, { title, transcripts }) {
    const key = 'createNewNote';
    const existingPromise = activePromises[key];
    if (existingPromise !== undefined) return existingPromise;

    const promise = api.pages
      .createNote(getters.getTeamID, { title })
      .then(({ data }) => {
        dispatch('setNote', { noteIdToUpdate: constants.newNote, note: data.note });
        return data.note?.id;
      })
      .finally(() => {
        delete activePromises[key];
      });

    activePromises[key] = promise;
    return promise;
  },

  deleteNote({ commit, getters }, noteId) {
    commit('deleteNote', noteId);
    return api.pages.deleteNote(getters.getTeamID, noteId);
  },

  localUpdateNoteProp({ commit }, { noteId, aiNoteId, propertyName, value }) {
    commit('localUpdateNoteProp', { noteId, aiNoteId, propertyName, value });
  },

  setAiNoteGenerating({ commit }, noteId) {
    commit('setAiNoteGenerating', noteId);
  },

  setAiNoteGeneratingTitle({ commit }, noteId) {
    commit('setAiNoteGeneratingTitle', noteId);
  },

  fetchNoteTemplates({ dispatch, getters }) {
    return api.pages
      .getNoteTemplates(getters.getTeamID)
      .then(({ data }) => {
        dispatch('setNoteTemplates', data.note_templates);
      })
      .catch((error) => error);
  },

  setNoteTemplates({ commit }, templates) {
    commit('setNoteTemplates', templates);
  },

  async applyNoteTemplate({ dispatch, getters }, { noteId, templateId }) {
    const response = await fetch(
      `${getters.getRoutes.pages}/v1/${getters.getTeamID}/notes/${noteId}/apply_template`,
      {
        method: 'POST',
        body: JSON.stringify({ note_template_id: templateId }),
        credentials: 'include',
        headers: {
          Accept: 'text/event-stream',
        },
      }
    );

    return response;
  },

  setAiNote({ commit }, { noteId, templateId, aiNote }) {
    commit('setAiNote', { noteId, templateId, aiNote });
  },

  updateLastNoteTemplateId({ commit, getters }, { noteId, templateId }) {
    return api.pages.updateNote(getters.getTeamID, noteId, { last_note_template_id: templateId });
  },

  setSelectedNoteTabId({ commit }, { noteId, tabId }) {
    commit('setSelectedNoteTabId', { noteId, tabId });
  },

  localCreateTranscript({ commit }, { noteId, transcript }) {
    commit('localCreateTranscript', { noteId, transcript });
  },

  localUpdateTranscript({ commit }, { noteId, transcript }) {
    commit('localUpdateTranscript', { noteId, transcript });
  },

  localSetNoteTranscripts({ commit }, { noteId, transcripts }) {
    commit('localSetNoteTranscripts', { noteId, transcripts });
  },

  createTranscript({ getters }, { noteId, transcripts }) {
    return api.pages.updateNote(getters.getTeamID, noteId, { transcripts });
  },
};

export default actions;
