export default {
  computed: {
    iconsContext() {
      return require.context('../assets/icons/', false);
    },
  },
  methods: {
    iconPath(name, extension = 'svg') {
      if (!name) return '';
      try {
        return this.iconsContext(`./${name}.${extension}`);
      } catch (error) {
        return '';
      }
    },

    getCustomIconStyle(path, styleOptions = {}, useMask = true) {
      const url = `url(${path})`;
      return useMask
        ? {
            // Use mask styles if you want to override colors
            // require set with css: background-color, width and height
            maskImage: url,
            webkitMaskImage: url,
            maskSize: 'contain', // change with css if needed, use !important to apply
            webkitMaskSize: 'contain',
            maskRepeat: 'no-repeat',
            webkitMaskRepeat: 'no-repeat',
            ...styleOptions,
          }
        : {
            // Use background-image to preserve original colors
            backgroundImage: url,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            ...styleOptions,
          };
    },
  },
};
