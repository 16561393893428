/**
 * Boards module getters
 * @packageDocumentation
 * @category Store
 */
import { GetterTree } from 'vuex';
import { BoardState as State, Board, CardsLayoutOption } from '@/types';
import { SuggestionType } from '@/types/suggestions';
import { CardHint, Card } from '@superthread-com/api';
import { IEpic } from '@/types/epics';
import { ResourceType } from '@/types/resources';
import { DisplayedCard } from '@/types/displayedCard';
import constants from '@/utilities/constants';
import { EpicTabId } from '@/types/tabs';

export default {
  /** Returns currently opened board. */
  currentBoard: (state, getters): Board | {} => state.flatBoards[getters.currentBoardId] || {},
  /** Returns currently opened board id */
  currentBoardId: (state): string => state.currentBoardId,
  /** Returns the list of added boards. */
  lists: (state) => state.lists,
  /** Returns the list of board ids for specific projectId. */
  getProjectBoards: (state) => (projectId: string | number) => state.projectBoards[projectId] || [],

  getFlatBoards: (state) => state.flatBoards,

  boardsListsIds: (state) => state.boardsListsIds,

  /** Returns  the id of project in which board is being added. */
  getNewBoardProjectId: (state): string | undefined => state.newBoardProjectId,
  getCardById:
    (state): any =>
    (cardId: string) =>
      state.cards[cardId] ?? {},
  getBoardById:
    (state): any =>
    (boardId: string = state.currentBoardId) =>
      state.flatBoards[boardId] ?? {},
  getViewById:
    (state): any =>
    (viewId: string = state.selectedView) =>
      [...(state.privateViews || []), ...(state.publicviews || [])].find((v) => v.id == viewId) ??
      {},

  /** Returns id of the list in which card is creating. */
  getListDraftCardCreateView: (state): string | null | undefined => state.listDraftCardCreateView,
  /** Returns a data of dragged entity. */
  draggedEntity: (state): any => state.draggedEntity,
  /** Returns id of element in in which dragging was finished. */
  dropTargetId: (state): string | undefined => state.dropTargetId,
  /** Returns the board that is expanded in the list of boards. */
  boardExpanded:
    (state): any =>
    (boardId: string) =>
      state.expandedBoardIds[boardId],
  /** Returns the flag that indicates whether boards are loading. */
  loadingBoards: (state): boolean => state.loadingBoards,
  /** Returns the flag that indicates when the board's data is needed to be removed. */
  boardViewNoDestroy: (state): boolean => state.boardViewNoDestroy,
  /** Returns the flag for opening list's input when there aren't any list on the board. */
  draftListInputVisibleOnOpen: (state): boolean => state.draftListInputVisibleOnOpen,
  /** Returns current board list view type (list or grid) */
  getBoardListView: (state): string | undefined => state.boardListView,
  /** Returns type of board's lists ordering */
  getBoardListOrderBy: (state): string => state.boardListOrderBy,
  /** Returns object of the tag that needs to be edited */
  getCurrentTagForEdit: (state): any => state.currentTagForEdit,
  /** Returns the flag that indicates is popup for uplading image opened */
  getShowImageUploadPopup: (state): boolean => state.showImageUploadPopup,
  /** Returns id of card which have opened dropdown for edit card. */
  getCurrentCardIdActionEdit: (state): string => state.currentCardIdActionEdit,
  /** Returns id of list which have opened dropdown for edit list. */
  getCurrentListIdActionEdit: (state): string | null => state.currentListIdActionEdit,
  /** Returns current board view type (list or grid) */
  cardsLayout:
    (state, getters) =>
    (boardId: string = getters.currentBoardId) => {
      let layout = state.cardsLayout[boardId] || state.flatBoards[boardId]?.layout || '';
      if (!layout) {
        const { value } = getters.cardsLayoutOptions.find(
          (option: CardsLayoutOption) => option.default
        );
        layout = value;
      }
      return layout;
    },
  getCardsLayoutForBoard:
    (state): any =>
    (boardId: string) =>
      state.cardsLayout[boardId] || state.flatBoards[boardId]?.layout || 'board',
  getLocalBoardLayoutForBoard: (state) => (boardId: string) => state.cardsLayout[boardId],
  cardsLayoutOptions: (state): CardsLayoutOption[] => state.cardsLayoutOptions,
  getCurrentCardActionEdit: (state): any => state.cards[state.currentCardIdActionEdit] || {},
  currentCardForPopup: (state): any => state.cards[state.currentCardIdForPopup] || {},
  /** Returns object conaining the meta of removed board list  */
  removedListMeta: (state) => state.removedListMeta,
  /** Returns object conaining the meta of removed card  */
  removedCardMeta: (state) => state.removedCardMeta,
  getAttachments: (state) => (id: number, prefix: string) =>
    state.attachments[`${prefix}-${id}`] || [],
  /** Returns whether any of attachments is still uploading */
  getAreAttachmentsUploading: (state) => {
    const { attachments } = state;
    return Object.keys(attachments).some((key) =>
      attachments[key].some((a: any) => a.uploadProgress)
    );
  },
  getCardAutoFocus: (state): boolean => state.cardAutoFocus,
  headerIconsDropdown: (state) => state.headerIconsDropdown,
  getPreviewPopoverKey: (state): boolean => state.previewPopoverKey,
  getAttachmentTargetCardId: (state) => state.attachmentTargetCardId,
  getCardAttachmentsLoaded: (state): boolean => state.cardAttachmentsLoaded,
  archivedBoards: (state) => state.archivedBoards,
  archivedLists: (state) => state.archivedLists,
  archivedCards: (state) => state.archivedCards,
  getNewBoardTitle: (state) => state.newBoardTitle,
  getCurrentCardIdMemberDropdown: (state): string => state.currentCardIdMemberDropdown,
  currentCardIdForPopup: (state): string => state.currentCardIdForPopup,
  getCurrentCardMembersDropdown: (state): any => state.currentCardMembersDropdown,
  previewProject: (state) => state.previewProject,
  getLinkedCardType: (state): string => state.linkedCardType,
  getReportInfoData: (state) => state.reportInfoData,
  projectPreviewBoards: (state) => state.projectPreviewBoards,
  displayProjectPreviewBoards: (state) => state.displayProjectPreviewBoards,
  getCardAttachmentUploadingId: (state) => state.cardAttachmentUploadingId,
  getTimeperoidOptions: (state) => state.timeperiodOptions,
  getShowDuplicateCardPopup: (state): string => state.showDuplicateCardPopup,
  getShowSetParentCardPopup: (state): string => state.showSetParentCardPopup,
  /** Returns the flag that indicates is card dragging just finished */
  getDraggingEnd: (state): boolean => state.draggingEnd,
  getListCards: (state) => state.listCards,
  getCards: (state) => state.cards,
  /** Filters epics out of cards on state. */
  getEpics: (state) => {
    const epics: { [key: string]: IEpic } = {};
    for (const c of Object.values(state.cards as Card[] | IEpic[])) {
      if (c.type === ResourceType.Epic) {
        epics[c.id] = c;
      }
    }
    return epics;
  },
  getDisplayedCards: (state) => state.displayedCards,
  getIsCardDisplayed: (state) => (cardId: string) =>
    state.displayedCards.some((c) => c.id === cardId),
  getIsAnyCardDisplayed: (state) => state.displayedCards.length > 0,
  getLayoutOfDisplayedCard: (state) => (cardId: string) =>
    state.displayedCards.find((card: DisplayedCard) => card.id === cardId)?.layout ||
    state.currentCardLayout,
  getIsAnyCardDisplayedInLayout: (state) => (layout: string) =>
    state.displayedCards.some((card: DisplayedCard) => card.layout === layout),
  getCommentActivityFilterOfDisplayedCard: (state, globalGetters) => (cardId: string) =>
    state.displayedCards.find((card: DisplayedCard) => card.id === cardId)
      ?.comment_activity_filter || globalGetters.getCardCommentActivityFilter,
  getCommentActivityOrderOfDisplayedCard: (state, globalGetters) => (cardId: string) =>
    state.displayedCards.find((card: DisplayedCard) => card.id === cardId)
      ?.comment_activity_order || globalGetters.getCommentsOrder,
  getDisplayedCardListCards: (state) => (cardId: string) =>
    state.displayedCards.find((card: DisplayedCard) => card.id === cardId)?.list_cards || [],
  /** Returns the list of checklist ids that have collapsed state active */
  getCollapsedChecklistIds: (state) => state.collapsedChecklistIds,
  /** Returns the list of checklist ids that have checklist items hidden */
  getHiddenItemsChecklistIds: (state) => state.hiddenItemsChecklistIds,
  showFilterMenu: (state) => state.showFilterMenu,
  showSubHeaderFilterMenu: (state) => state.showSubHeaderFilterMenu,
  boardsFilters: (state) => state.boardsFilters,
  boardFilters: (state) => (boardId: number) => state.boardsFilters[boardId],
  boardsPills: (state) => (boardId: number) => state.boardsPills[boardId] || [],
  getTags: (state) => state.tags,
  /** Returns the list of tag ids for specific projectId. */
  getProjectTags: (state) => (projectId: string | number) => state.projectTags[projectId] || [],
  /** Returns the list of tag ids for specific teamId. */
  getWorkspaceTags: (state) => (teamId: string | number) => state.workspaceTags[teamId] || [],
  /** Returns the object of project tags. */
  projectTags: (state) => state.projectTags,
  getCardCommentAttachmentUploadPopup: (state): object => state.cardCommentAttachmentUploadPopup,
  getCardDescriptionAttachmentUploadPopup: (state): object =>
    state.cardDescriptionAttachmentUploadPopup,
  lastHoveredListId: (state) => state.lastHoveredListId,
  lastHoveredCardId: (state) => state.lastHoveredCardId,
  lastHoveredDisplayedCardId: (state) => state.lastHoveredDisplayedCardId,
  boardViewMouseSuspended: (state) => state.boardViewMouseSuspended,
  selectedCardId: (state) => state.selectedCardId,
  lastOpenedBoardId: (state): string => state.lastOpenedBoardId,
  getCustomColorPicker: (state): boolean => state.customColorPicker,
  /** Returns the flag for checking if checklist or checklist item input is active. */
  getChecklistInputActive: (state) => (checklistId: string) =>
    state.checklistInputActive[checklistId] || false,
  getChecklistsInputActive: (state) => state.checklistInputActive,
  getChecklistItemInputActive: (state) => (checklistItemId: string) =>
    state.checklistItemInputActive[checklistItemId] || false,
  getChecklistItemsInputActive: (state) => state.checklistItemInputActive,
  getTimelineZoom: (state) => state.timelineZoom,
  getTimelineSidebarWidth: (state) => state.timelineSidebarWidth,
  getCardWidth: (state) => state.cardWidth,
  getChildCardActionsDropdownId: (state) => state.childCardActionsDropdownId,
  getChildCardStatusPickerDropdownId: (state) => state.childCardStatusPickerDropdownId,
  getListCardStatusPickerDropdownId: (state) => state.listCardStatusPickerDropdownId,
  getChecklistActionsDropdownId: (state) => state.checklistActionsDropdownId,
  cardsSchema: (state) => state.cardsSchema,
  getCardIdForMove: (state) => state.cardIdForMove,
  // Quick card
  getQuickCardFilesToUpdate: (state) => state.quickCardFilesToUpdate,
  getMembersToAddQuickCard: (state) => state.quickCardObject.members,
  getQuickCardProjectId: (state) => state.quickCardObject.projectId,
  getQuickCard: (state) => state.quickCardObject,
  // Quick child card embed
  getQuickChildCardFilesToUpdate: (state) => state.quickChildCardFilesToUpdate,
  getMembersToAddQuickChildCard: (state) => state.quickChildCardObject.members,
  getQuickChildCardProjectId: (state) => state.quickChildCardObject.projectId,
  getQuickChildCard: (state) => state.quickChildCardObject,
  getChildCardCreation: (state) => state.childCardCreation,
  getCardInEpicCreation: (state) => state.cardInEpicCreation,
  getCardInNoteCreation: (state) => state.cardInNoteCreation,
  getCardSliderActive: (state) => state.cardSliderActive,
  getBoardSortBy:
    (state, getters) =>
    (boardId = getters.currentBoardId): string => {
      let sortBy = `${state.boardSortBy[boardId] || ''}`;
      const isInEpicBoard = boardId.startsWith(constants.inEpicBoardIdPrefix);

      if (!sortBy) {
        const { value } = getters.boardSortByOptions.find((option: CardsLayoutOption) =>
          isInEpicBoard ? option.inEpicBoardDefault : option.default
        );
        sortBy = value;
      }

      return sortBy;
    },
  boardSortByOptions: (state): CardsLayoutOption[] => state.boardSortByOptions,
  getShowPriorityDropdown: (state): boolean => state.priorityDropdown,
  getShowCardPriorityPopup: (state): boolean => state.showCardPriorityPopup,
  getBoardGroupBy:
    (state, getters) =>
    (boardId = getters.currentBoardId): string => {
      let groupBy = `${state.boardGroupBy[boardId] || ''}`;
      const isInEpicBoard = boardId.startsWith(constants.inEpicBoardIdPrefix);

      if (!groupBy) {
        const { value } = getters.boardGroupByOptions.find((option: CardsLayoutOption) =>
          isInEpicBoard ? option.inEpicBoardDefault : option.default
        );
        groupBy = value;
      }

      return groupBy;
    },
  boardGroupByOptions: (state): CardsLayoutOption[] => state.boardGroupByOptions,
  getCardsByCurrentBoardId:
    (state, getters) =>
    (boardId = getters.currentBoardId) =>
      Object.values(state.cards).filter((c: any) => c.board_id === boardId) || [],
  /** Gets all the cards that are added to epic. */
  getCardsByEpicId: (state) => (epicId: string) => {
    const cards: Record<string, Card | IEpic> = {};
    for (const key in state.cards) {
      if (state.cards[key].epic?.id === epicId) {
        cards[key] = state.cards[key];
      }
    }
    return cards;
  },
  getCardsByCurrentSprintId:
    (state, getters) =>
    (boardId = getters.currentBoardId) =>
      Object.values(state.cards).filter((c: any) => c.sprint_id === boardId) || [],
  boardsCardsIds: (state) => {
    const boardsCardsIds: Record<string, string[]> = {};
    Object.entries(state.boardsListsIds).forEach(([boardId, listIds]) => {
      boardsCardsIds[boardId] = listIds.reduce((acc: string[], listId: string) => {
        return [...acc, ...(state.listCards[listId] || [])];
      }, []);
    });
    return boardsCardsIds;
  },
  getBoardsCardsIdsByBoardId:
    (state, getters) =>
    (boardId: string = getters.currentBoardId): string[] => {
      return getters.boardsCardsIds[boardId] || [];
    },
  getBoardListGroupByOrder: (state) => (boardId: string) => state.boardListGroupByOrder[boardId],
  getListCardsGroupByOrder: (state) => (listId: string) => state.listCardsGroupByOrder[listId],
  getCardCustomSectionDropdown: (state): string => state.cardCustomSectionDropdown,
  getCurrentGroupByListIdCardActionEdit: (state): string =>
    state.currentGroupByListIdCardActionEdit,
  getCurrentCardUniqueRowId: (state): string => state.currentCardUniqueRowId,
  getCardTemplates: (state) => state.cardTemplates,
  getCardTemplateDraft: (state) => state.cardTemplateDraft,
  getCardTemplateChildCards: (state) => state.cardTemplateDraft.card_child_cards,
  getQCChildCards: (state) => state.quickCardObject.childCards,
  getSuggestions: (state) => (cardId: string) => state.cards[cardId]?.hints || [],
  getQCSuggestions: (state) => state.quickCardObject?.hints || [],
  getTagSuggestions: (state) => (cardId: string) =>
    state.cards[cardId]?.hints?.filter(({ type }: CardHint) => type === SuggestionType.tag) || [],
  getQCTagSuggestions: (state) =>
    state.quickCardObject?.hints?.filter(({ type }: CardHint) => type === SuggestionType.tag) || [],
  getDuplicateSuggestions: (state) => (cardId: string) =>
    state.cards[cardId]?.hints?.filter(({ type }: CardHint) => type === SuggestionType.relation) ||
    [],
  getQCDuplicateSuggestions: (state) =>
    state.quickCardObject?.hints?.filter(
      ({ type }: CardHint) => type === SuggestionType.relation
    ) || [],
  getShowSuggestionsPlaceholder: (state) => state.showSuggestionsPlaceholder,
  getQCShowSuggestionsPlaceholder: (state) => state.qcShowSuggestionsPlaceholder,
  getNewBoardObject: (state) => state.newBoardObject,
  getSprintStatusPickerDropdownId: (state) => state.sprintStatusPickerDropdownId,
  getSprintSettings: (state) => (projectId: string) => state.sprintSettings[projectId] || {},
  getSpaceSprints: (state) => (projectId: string | number) => state.spaceSprints[projectId] || {},
  getSprints: (state) => state.spaceSprints,
  getSprintLists: (state) => (listId: string | number) => state.sprintLists[listId] || [],
  getSprintForEditId: (state) => state.sprintForEditId,
  getEpicSelectedTabId: (state) => (cardId: string) =>
    state.epicSelectedTabId[cardId] || EpicTabId.Details,
} as GetterTree<State, any>;
