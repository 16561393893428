/**
 * Boards module actions
 * @packageDocumentation
 * @category Store
 */
import { ActionTree } from 'vuex';
import { BoardState as State, CardsLayoutOption } from '@/types';
import { RemoveSuggestionPayload, SuggestionType } from '@/types/suggestions';
import { authGet, authPut, authPost, authDelete, authPatch, api } from '@/utilities/api';
import getItemAndIndex from '@/utilities/getItemAndIndex';
import { addCard } from '@/utilities/moveCard';
import constants from '@/utilities/constants';
import styleVariables from '@/assets/scss/variables.module.scss';
import {
  Board,
  ListBehavior,
  CardHint,
  GetCardResponse,
  GetEpicResponse,
  GetBoardResponse,
  GetEpicsResponse,
  CreateCardRequest,
  CreateEpicRequest,
  UpdateCardResponse,
  UpdateEpicResponse,
  CreateCardResponse,
  CreateEpicResponse,
  Card,
  List,
  Epic,
  EpicBrief,
} from '@superthread-com/api';
import { nanoid } from 'nanoid';
import { groupByOption } from '@/types/groupByOption';
import { OnboardingActionType } from '@/types/onboardingActions';
import fnRemoveSuggestion from '@/utilities/removeSuggestion';
import { ResourceType } from '@/types/resources';
import { EpicsBoard, IEpic } from '@/types/epics';
import { defaultSprintStatuses, getCardStatus } from '@/utilities/defaultCardStatuses';
import { translate } from '@/utilities';
import { DisplayedCardPayload, DisplayedCard } from '@/types/displayedCard';
import { getNormalizedDisplayedCardPayload } from '@/utilities/displayCardUtil';
import { getSprintKey, getSprintListKey } from '@/utilities/sprintKeys';
import cardStatusType from '@/types/cardStatusType';

// activePromisess is object with key type of structure and value of
// object with key of id and value of promise:
// This is used to reduce the number of requests to the server
const activePromises: { [key: string]: { [key: string]: Promise<any> } } = {
  boards: {},
  lists: {},
  cards: {},
  tags: {},
  sprints: {},
  projectBoards: {},
};

const actions: ActionTree<State, any> = {
  setBoardsListsIds({ commit }, boards) {
    commit('setBoardsListsIds', boards);
  },
  /**
   *  Action for loading a boards in current project.
   *  https://api.superthread.com/v1/boards
   */
  loadBoards({ commit, getters }, projectIdParam) {
    let projectId = projectIdParam;
    if (activePromises.projectBoards[projectId] !== undefined) {
      return activePromises.projectBoards[projectId];
    }
    if (!projectIdParam) {
      projectId = getters.getCurrentProjectId;
      commit('loadingBoards', true);
    }

    activePromises.projectBoards[projectId] = api.boards
      .getBoards(getters.getTeamID, { project_id: projectId })
      .then(({ data }) => {
        const { boards, board_order: projectBoards } = data;
        commit('setProjectBoards', {
          projectId,
          projectBoards,
        });
        commit('setFlatBoards', boards);
        commit('setBoardsListsIds', boards);
        const boardLists: string | any[] = [];
        boards?.forEach((b: Board) => {
          boardLists.push(...(b.lists || []));
        });
        commit('setBoardListsFlat', boardLists);
        const listCards: any = {};
        boardLists.forEach((l: any) => {
          listCards[l.id] = [...(l.card_order || [])];
        });
        commit('setListCards', listCards);
        return data;
      })
      .catch(() => {})
      .finally(() => {
        if (getters.loadingBoards) {
          commit('loadingBoards', false);
        }
        delete activePromises.projectBoards[projectId];
      });
    return activePromises.projectBoards[projectId];
  },

  setProjectBoards({ commit }, payload) {
    commit('setProjectBoards', payload);
  },

  localUpdateProjectBoardsOrder({ commit }, { projectId, payload }) {
    commit('localUpdateProjectBoardsOrder', { projectId, payload });
  },

  removeProjectBoard({ commit }, payload) {
    commit('removeProjectBoard', payload);
  },

  /**
   *  Action for getting a single board.
   *  https://api.superthread.com/v1/boards/{board_id}
   */

  handleBoardCreated({ commit }, { board }) {
    commit('addBoardInBoards', board);
    commit('setFlatBoards', [board]);
    commit('setBoardsListsIds', [board]);
    if (board.lists) {
      commit('setBoardListsFlat', board.lists);
    }
  },

  /**
   * Fetch full board or epics board (roadmap).
   * https://api.superthread.com/v1/{team_id}/boards/{board_id}
   * https://api.superthread.com/v1/{team_id}/epics
   * @param {string} boardId - id of the board to fetch, in case of epic its hardcoded to 'epics'
   * @param {string} teamId - id of the team
   */
  fetchBoard({ getters, dispatch }, { boardId, teamId = getters.getTeamID }) {
    // prevent multiple same requests if request is already in progress
    const existingPromise = activePromises.boards[boardId];
    if (existingPromise !== undefined) return existingPromise;

    const isEpicsBoard = boardId === constants.epicsBoardId;
    const req = isEpicsBoard
      ? api.boards.getEpics(getters.getTeamID)
      : api.boards.getBoard(getters.getTeamID, boardId);

    const promise = req
      .then(({ data }: { data: GetBoardResponse | GetEpicsResponse }) => {
        const board = isEpicsBoard ? (data as EpicsBoard) : (data as GetBoardResponse).board;
        if (!board) throw new Error('Invalid response');

        if (isEpicsBoard) board.id = constants.epicsBoardId;
        if (teamId === getters.getTeamID) {
          dispatch('postFetchBoard', { board });
        }

        return { board };
      })
      .catch((error) => {
        const currentRouteName = getters.getCurrentRoute.name;
        if (
          error.response?.status === 403 &&
          (currentRouteName === constants.routeNames.board ||
            currentRouteName === constants.routeNames.boardCard) &&
          boardId === getters.currentBoardId
        ) {
          dispatch('itemNotFound', true);
        }
        if (!boardId?.includes('s')) {
          // if boardId is not a sprint board
          dispatch('addBoardInBoards', { id: `${boardId}_notFound`, notFound: true });
        }
        throw error;
      })
      .finally(() => {
        // remove promise from activePromises.boards
        delete activePromises.boards[boardId];
      });
    // add promise to activePromises.boards
    activePromises.boards[boardId] = promise;
    return promise;
  },

  postFetchBoard({ commit, getters }, { board }: { board: any }) {
    const isEpicsBoard = board.id === constants.epicsBoardId;

    if (isEpicsBoard) {
      board.lists?.forEach((l: List) => {
        l.board_id = constants.epicsBoardId;
      });
    }

    commit('setFlatBoards', [board]);
    commit('setBoardsListsIds', [board]);
    commit('setBoardListsFlat', board.lists);

    const listCards: any = {};
    const cards: any = {};
    board.lists?.forEach((l: any) => {
      listCards[l.id] = [...(l.card_order || [])];
      (isEpicsBoard ? l.epics : l.cards)?.forEach((c: any) => {
        if (c) {
          const stateCard = getters.getCards[c.id] || {};

          cards[c.id] = {
            ...stateCard,
            ...c,
            ...(c.type === ResourceType.Epic && { board_id: constants.epicsBoardId }),
          };
        }
        if (c?.child_cards?.length) {
          c.child_cards.forEach((child: any) => {
            if (!getters.getCards[child.card_id]) {
              child.partially_loaded = true;
              child.id = child.card_id;
              commit('addCardToCards', { card: child });
            }
          });
        }
      });
    });
    commit('setCards', cards);
    commit('setListCards', listCards);
  },

  /**
   * Archives the board with passed id.
   * https://api.superthread.com/v1/{team_id}/boards/{board_id}
   * @param {string} id
   */
  archiveBoard({ getters, commit, dispatch }, { id, archived }) {
    return authPatch(`${getters.getRoutes.boards}/v1/${getters.getTeamID}/boards/${id}`, {
      archived,
    })
      .then((data) => {
        const timeNow = Math.floor(Date.now() / 1000);
        dispatch('updateBoardPropertyInBoards', {
          boardId: id,
          propertyName: 'archived',
          value: {
            time_archived: timeNow,
            user_id: getters.getUserId,
          },
        });
      })
      .catch((error) => {
        dispatch('updateBoardPropertyInBoards', {
          boardId: id,
          propertyName: 'archived',
          value: false,
        });
      });
  },
  /**
   * Load archived boards or lists or cards.
   * https://api.superthread.com/v1/{team_id}/boards?archived=true
   * https://api.superthread.com/v1/{team_id}/lists?archived=true
   * https://api.superthread.com/v1/{team_id}/cards?archived=true
   * @param {string} section
   */
  loadArchived({ commit, getters }, section) {
    return authGet(`${getters.getRoutes.boards}/v1/${getters.getTeamID}/${section}`, {
      params: {
        archived: true,
      },
    })
      .then((r) => r.data)
      .then(({ boards, lists, cards }) => {
        switch (section) {
          case 'boards':
            commit('setArchivedBoards', boards);
            commit('setFlatBoards', boards);
            commit('setBoardsListsIds', boards);
            // eslint-disable-next-line no-case-declarations
            const boardLists: string | any[] = [];
            boards.forEach((b: { lists: any }) => {
              boardLists.push(...(b.lists || []));
            });
            commit('setBoardListsFlat', boardLists);

            // eslint-disable-next-line no-case-declarations
            const listCards: any = {};
            boardLists.forEach((l: any) => {
              listCards[l.id] = [...(l.card_order || [])];
            });
            commit('setListCards', listCards);

            return boards;
          case 'lists':
            commit('setBoardListsFlat', lists);
            commit('setArchivedLists', lists);
            return lists;
          case 'cards':
            // eslint-disable-next-line no-case-declarations
            const newCards: any = {};
            cards.forEach((c: any) => {
              newCards[c.id] = c;
            });
            commit('setCards', newCards);

            commit('setArchivedCards', cards);
            return cards;
          default:
            return [];
        }
      });
  },
  /**
   * Archives a list with passed id.
   * https://api.superthread.com/v1/{team_id}/lists/{list_id}
   * @param {string} id
   */
  archiveList({ getters, dispatch, state, commit }, id) {
    const list = state.lists[id];
    const currentListOrder = [...state.boardsListsIds[list.board_id]];
    const newListOrder = [...currentListOrder];

    const index = newListOrder.indexOf(id);
    if (index > -1) {
      commit('removeBoardListId', {
        boardId: list.board_id,
        id,
      });
    }
    return authPatch(
      `${getters.getRoutes.lists}/v1/${getters.getTeamID}/boards/${list.board_id}/lists/${id}`,
      { archived: true }
    )
      .then((data) => {
        dispatch('addUiNotification', {
          message: translate('archiveSuccess', {
            name: list.title,
            type: translate(ResourceType.List).toLowerCase(),
          }),
          status: constants.uiNotificationStatuses.warning,
          icon: 'archive',
          duration: 4000,
          labels: [
            {
              text: translate('undo'),
              type: constants.uiNotificationElements.button,
              action: 'unarchiveList',
              buttonStyle: 'important',
              params: {
                id,
                passedList: list,
              },
            },
          ],
        });
        return data;
      })
      .catch((error) => {
        if (index > -1) {
          commit('addBoardListId', {
            boardId: list.board_id,
            listId: id,
          });
        }
      });
  },

  getList({ getters, state, commit }, { teamId, listId, isEpicsList = false }) {
    const existingPromise = activePromises.lists[listId];
    if (existingPromise !== undefined) return existingPromise;

    const promise = api.boards
      .getList(teamId, listId)
      .then(({ data }) => {
        // epics list dont have board_id in resp
        if (isEpicsList && data.list) {
          data.list.board_id = constants.epicsBoardId;
        }

        return data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        delete activePromises.lists[listId];
      });

    activePromises.lists[listId] = promise;
    return promise;
  },

  unarchiveList({ getters, commit }, { id, passedList }) {
    const newArchivedBoardLists = getters.archivedLists;

    const { item: list, itemIndex: index } = getItemAndIndex(newArchivedBoardLists, id);

    newArchivedBoardLists.splice(index, 1);
    commit('setArchivedLists', newArchivedBoardLists);
    return authPatch(`${getters.getRoutes.lists}/v1/${getters.getTeamID}/lists/${id}`, {
      archived: false,
    })
      .then((data) => {
        commit('addBoardListId', {
          boardId: passedList.board_id,
          listId: id,
        });
        return data;
      })
      .catch((error) => {
        commit('addArchivedListToIndex', { list, index });
        throw error;
      });
  },
  /**
   * Sets lists to the state.
   * @param {any} data
   */
  setBoardListsFlat({ commit }, data) {
    commit('setBoardListsFlat', data);
  },

  addBoardListId({ commit }, payload) {
    commit('addBoardListId', payload);
  },

  replaceBoardListId({ commit }, payload) {
    commit('replaceBoardListId', payload);
  },

  removeBoardListId({ commit }, payload) {
    commit('removeBoardListId', payload);
  },

  moveBoardListId({ commit }, payload) {
    commit('moveBoardListId', payload);
  },

  removeBoardList({ commit }, listId) {
    commit('removeBoardList', listId);
  },

  /**
   * Sets passed board object as current on the state.
   * @param {Board} board
   */
  setCurrentBoardId({ commit }, board) {
    commit('setCurrentBoardId', board);
  },

  /**
   * Action for updating list.
   * @param {String} listId
   * @param {Object} list
   * https://api.superthread.com/v1/lists/{list_id}
   */
  updateList({ getters, dispatch }, { boardId, listId, list }) {
    const isEpicsList = boardId === constants.epicsBoardId;
    const req = isEpicsList
      ? api.boards.updateEpicsList(getters.getTeamID, listId, list)
      : api.boards.updateList(getters.getTeamID, listId, list);
    return req;
  },
  /**
   * Publish list using passed board id.
   * @param {string} boardId
   * @param {string} list
   * https://api.superthread.com/v1/lists
   */
  createBoardList({ getters }, { boardId, list }) {
    const isEpicsList = boardId === constants.epicsBoardId;
    const req = isEpicsList
      ? api.boards.createEpicsList(getters.getTeamID, list)
      : api.boards.createList(getters.getTeamID, { ...list, board_id: boardId });
    return req
      .then(({ data }) => {
        if (isEpicsList && data.list) {
          data.list.board_id = constants.epicsBoardId;
        }
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },

  /**
   * Sets list's cards to the state.
   * @param {any} data
   */
  /**
   * Creates a card/epic with passed title in specific list by using passed listId.
   * https://api.superthread.com/v1/{team_id}/cards
   * https://api.superthread.com/v1/{team_id}/epics
   * @param {object} card - object containning card/epic fields
   * @param {ResourceType} type - 'card' or 'epic'
   */
  createCard(
    { getters, dispatch },
    {
      card,
      type = ResourceType.Card,
    }: {
      card: CreateCardRequest | CreateEpicRequest;
      type?: ResourceType;
    }
  ): Promise<Card | Epic> {
    const isEpic = type === ResourceType.Epic;

    if (!card.list_id || !card.title)
      return Promise.reject(new Error(constants.missingCardTitleOrListIdErr));

    const params: CreateCardRequest | CreateEpicRequest = {
      ...card,
      schema: card.schema ?? getters.cardsSchema,
    };

    const req = isEpic
      ? api.boards.createEpic(getters.getTeamID, params as CreateEpicRequest)
      : api.boards.createCard(getters.getTeamID, params as CreateCardRequest);

    return req
      .then(({ data }: { data: CreateCardResponse | CreateEpicResponse }) => {
        const card = isEpic ? (data as CreateEpicResponse).epic : (data as CreateCardResponse).card;

        if (!card) throw new Error('Invalid response');
        dispatch('completeOnboardingActions', [OnboardingActionType.CreateCard]);

        //@ts-ignore
        if (isEpic) card.board_id = constants.epicsBoardId;

        return { card };
      })
      .catch((err) => err);
  },
  /**
   * Updates card/epic to the passed object.
   * https://api.superthread.com/v1/{team_id}/cards/{card_id} - EP for card
   * https://api.superthread.com/v1/{team_id}/epics/{epic_id} - EP for epic
   * @param {ResourceType} type - 'card' or 'epic'
   * @param {string} cardId - id of a card/epic to update
   * @param {object} card - object containning card/epic fields to update
   */
  updateCard(
    { getters, commit, dispatch },
    {
      cardId,
      card,
      type = ResourceType.Card,
      oldListId,
    }: {
      cardId: string;
      card: any;
      type?: ResourceType;
      oldListId: string;
    }
  ) {
    const isEpic = type === ResourceType.Epic;
    const movingCard = Boolean(card.list_id);
    const { getTeamID: teamId } = getters;
    const updateRequest = isEpic ? api.boards.updateEpic : api.boards.updateCard;

    const stateCard = getters.getCardById(cardId);
    const epicId = stateCard?.epic?.id;
    if (movingCard && !isEpic && epicId) {
      const { project_id, sprint_id, list_id } = card;
      const listId =
        sprint_id && project_id ? getSprintListKey(project_id, sprint_id, list_id) : list_id;

      const oldList = getters.lists[oldListId || ''];
      if (oldList) {
        commit('removeCardFromGroupByList', {
          epicId,
          cardId,
          listId: oldList.behavior || 'backlog',
          groupBy: 'statusType',
          teamId,
        });
      }

      const newList = getters.lists[listId];
      if (newList) {
        commit('addCardToGroupByList', {
          epicId,
          cardId,
          listId: newList.behavior || 'backlog',
          groupBy: 'statusType',
          teamId,
        });
      }
    }

    if (isEpic) {
      //@ts-ignore
      card.board_id = constants.epicsBoardId;
    }

    return updateRequest(teamId, cardId, card)
      .then((resp: { data: UpdateCardResponse | UpdateEpicResponse }) => {
        if (movingCard || card.title) {
          dispatch('localUpdateChildParent', { card, cardId });
        }
        return resp;
      })
      .catch((resp) => {
        if (typeof resp === 'string') return resp;

        const { response } = resp;
        const error = response.data;

        if (error.message === 'oldSchema') {
          // add ui notification
          commit('addUiNotification', {
            message: translate('updateAppToEditCard'),
            duration: 4000,
            id: nanoid(4),
          });
        }

        throw error;
      });
  },

  localUpdateChildParent({ getters, dispatch }, { card, cardId }) {
    const cardData = getters.getCards[cardId] || {};
    if (!cardData.child_cards?.length) return;

    const list = card.sprint_id
      ? getters.lists[getSprintListKey(card.project_id, card.sprint_id, card.list_id)]
      : getters.lists[card.list_id];

    cardData.child_cards?.forEach((childCard: any) => {
      const childCardData = getters.getCards[childCard.card_id] || {};
      if (!childCardData.parent_card) return;

      const parentCardData = {
        card_id: childCardData.parent_card.card_id,
        title: card.title || childCardData.parent_card.title,
        list_color: card.title ? childCardData.parent_card.list_color : list?.color,
        status: card.title ? childCardData.parent_card.status : list?.behavior,
      };

      dispatch('localUpdateCardField', {
        cardId: childCardData.id,
        fieldName: 'parent_card',
        fieldValue: parentCardData,
      });
    });
  },

  /**
   * Local update card on state
   * Update specific card with new values.
   * @param {string} cardId required
   * @param {object} card required (provide card fields to update)
   * @param {boolean} overwrite optional (default: false)
   * @param {string} timeUpdated optional (default: null)
   * @param {string} userIdUpdated optional (default: null)
   */
  localUpdateCardFields({ commit }, payload) {
    commit('localUpdateCardFields', { ...payload });
  },
  /**
   * Local update card on state
   * Update specific card's field with new value.
   * @param {string} cardId
   * @param {string} fieldName
   * @param {string} fieldValue
   */
  localUpdateCardField({ commit }, payload) {
    commit('localUpdateCardField', { ...payload });
  },

  /**
   *  Update local card with patch request
   * @param {string} cardId
   * @param {object} card
   * @param {boolean} updateTime
   * @param {boolean} updateUserId
   * @returns {Promise}
   * @throws {Error}
   * @example
   * updateCardWithPatchRequest({ cardId: '1', card: { title: 'new title' } });
   * updateCardWithPatchRequest({ cardId: '2', card: { due_date: 1691160300, start_date: 1684161900 }, updateTime: false });
   **/
  updateCardWithPatchRequest(
    { getters, dispatch },
    { cardId, card, updateTime = true, updateUserId = true }
  ) {
    const { getCards, getUserId } = getters;
    const cards = getCards || {};
    const oldCard = cards[cardId];
    dispatch('localUpdateCardFields', {
      cardId,
      card,
      overwrite: false,
      ...(updateTime && { timeUpdated: Math.floor(Date.now() / 1000) }),
      ...(updateUserId && { userIdUpdated: getUserId }),
    });
    return dispatch('updateCard', { cardId, card }).catch((error) => {
      dispatch('localUpdateCardFields', {
        cardId,
        card: oldCard,
        overwrite: true,
      });
      throw error;
    });
  },

  localUpdateCardFieldInMultipleCards({ commit }, payload) {
    commit('localUpdateCardFieldInMultipleCards', payload);
  },

  /**
   * Creates published board.
   * https://api.superthread.com/v1/boards
   */
  createPublishedBoard({ getters, dispatch }, { title = '', icon, color, project_id, layout }) {
    if (!getters.getNewBoardProjectId && !project_id) {
      const msg = 'No project selected';
      return Promise.reject(new Error(msg));
    }

    return api.boards
      .createBoard(getters.getTeamID, {
        project_id: project_id || getters.getNewBoardProjectId,
        title,
        icon,
        color,
        // @ts-ignore
        layout,
        lists: [
          {
            title: 'Backlog',
            behavior: ListBehavior.Backlog,
            icon: 'circle-dotted',
            color: styleVariables.gray400,
          },
          {
            title: 'To do',
            behavior: ListBehavior.Committed,
            icon: 'disc',
            color: styleVariables.gray700,
          },
          {
            title: 'Doing',
            behavior: ListBehavior.Started,
            icon: 'play-circle',
            color: styleVariables.stBlue,
          },
          {
            title: 'Done',
            behavior: ListBehavior.Completed,
            icon: 'check-circle',
            color: styleVariables.success400,
          },
          {
            title: 'Cancelled',
            behavior: ListBehavior.Cancelled,
            icon: 'x-circle',
            color: styleVariables.warning500,
          },
        ],
      })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },

  async createBoardCopy({ getters, dispatch }, { boardId, projectId, title }) {
    const resp = await api.boards.copyBoard(getters.getTeamID, boardId, {
      project_id: projectId,
      title,
    });

    if (resp.status < 200 || resp.status >= 300 || !resp.data.board) {
      throw new Error('failed to copy board');
    }

    const board = resp.data.board;
    dispatch('addBoardInBoards', board);
    dispatch('localAddProjectBoardOrder', {
      projectId: board.project_id,
      boardId: board.id,
    });

    return board;
  },

  /**
   * Update board
   * https://api.superthread.com/v1/boards/{board_id}
   */
  updateBoard({ getters }, { id, board }) {
    return api.boards.updateBoard(getters.getTeamID, id, board).catch((error) => {
      throw error;
    });
  },

  /**
   * Sets the id of a project to the state.
   * @param {string} name
   */
  setNewBoardProjectId({ commit }, name) {
    commit('setNewBoardProjectId', name);
  },
  /**
   * Loads details of the specific card by using passed cardId.
   * https://api.superthread.com/v1/{team_id}/cards/{card_id}
   * @param {string} cardId
   */

  /**
   * Creates a checklist in a certain card by using passed cardId.
   * https://api.superthread.com/v1/{team_id}/cards/{card_id}/checklists
   * @param {string} cardId
   * @param {string} checklistTitle
   */
  createChecklist({ getters }, { cardId, checklistTitle }) {
    if (!cardId || !checklistTitle) {
      return Promise.reject(new Error('Missing checklist title or card id'));
    }
    const { getRoutes, getTeamID } = getters;
    return authPost(`${getRoutes.checklists}/v1/${getTeamID}/cards/${cardId}/checklists`, {
      title: checklistTitle,
    })
      .then(({ data }) => data)
      .catch((error) => {
        throw error;
      });
  },
  /**
   * Updates checklist.
   * https://api.superthread.com/v1/{team_id}/cards/{card_id}/checklists/{checklist_id}
   * @param {object} payload { id: string, field_name: string, field_value: any }
   */
  updateChecklist({ getters }, { cardId, checklistId, payload }) {
    const { getRoutes, getTeamID } = getters;
    return authPatch(
      `${getRoutes.checklists}/v1/${getTeamID}/cards/${cardId}/checklists/${checklistId}`,
      { ...payload }
    ).catch((error) => {
      throw error;
    });
  },
  /**
   * Deletes a certain checklist by using passed id.
   * https://api.superthread.com/v1/{team_id}/cards/{card_id}/checklists/{checklist_id}
   * @param {string} id
   */
  deleteChecklist({ getters, commit, state, dispatch }, { id, cardId }) {
    const { getRoutes, getTeamID, getCardById } = getters;
    const card = getCardById(cardId);
    const oldCardChecklists = [...state.cards[cardId].checklists];
    const oldChecklistOrder = [...state.cards[cardId].checklist_order];
    const oldChecklistItemOrder = { ...state.cards[cardId].checklist_item_order };

    const cardChecklistIndex = oldCardChecklists.findIndex((c) => c.id === id);
    const cardChecklists = [...oldCardChecklists];
    cardChecklists.splice(cardChecklistIndex, 1);

    const checklistOrderIndex = card.checklist_order.findIndex((co: any) => co === id);
    const checklistOrder = [...card.checklist_order];
    checklistOrder.splice(checklistOrderIndex, 1);

    // remove checklist from current card checklists
    commit('localUpdateCardField', {
      cardId,
      fieldName: 'checklists',
      fieldValue: cardChecklists,
    });

    // remove checklist from current card checklist_order
    commit('localUpdateCardField', {
      cardId,
      fieldName: 'checklist_order',
      fieldValue: checklistOrder,
    });
    // remove checklist_item_order from current card
    dispatch('removeOrReplaceLocalCurrentCardChecklistItemOrder', {
      cardId,
      localId: id,
    });
    return authDelete(
      `${getRoutes.checklists}/v1/${getTeamID}/cards/${cardId}/checklists/${id}`
    ).catch((error) => {
      commit('localUpdateCardField', {
        cardId,
        fieldName: 'checklists',
        fieldValue: oldCardChecklists,
      });

      commit('localUpdateCardField', {
        cardId,
        fieldName: 'checklist_order',
        fieldValue: oldChecklistOrder,
      });

      commit('localUpdateCardField', {
        cardId,
        fieldName: 'checklist_item_order',
        fieldValue: oldChecklistItemOrder,
      });
      throw error;
    });
  },

  /**
   * Creates checklist item in a certain checklist by using passed checklistId.
   * https://api.superthread.com/v1/{team_id}/cards/{card_id}/checklists/{checklist_id}/items
   * @param {string} checklistId
   * @param {string} title
   */
  createPublishedChecklistItem({ getters }, { cardId, checklistId, title }) {
    const { getRoutes, getTeamID } = getters;
    const itemsBase = getRoutes.checklistitems;
    return authPost(
      `${itemsBase}/v1/${getTeamID}/cards/${cardId}/checklists/${checklistId}/items`,
      {
        title,
        checklist_id: checklistId,
      }
    )
      .then(({ data }) => data)
      .catch((error) => {
        throw error;
      });
  },
  /**
   * Deletes a certain checklist item by using passed checklistId and checklistItemId.
   * https://api.superthread.com/v1/{team_id}/checklists/{checklist_id}/items/{item_id}
   * @param {string} checklistId
   * @param {string} checklistItemId
   */
  deleteChecklistItem({ getters }, { checklistItem, cardId, checklistId }) {
    const { getRoutes, getTeamID } = getters;
    const { id } = checklistItem;
    const itemsUrl = getRoutes.checklistitems;

    return authDelete(
      `${itemsUrl}/v1/${getTeamID}/cards/${cardId}/checklists/${checklistId}/items/${id}`
    );
  },

  localDeleteChecklistItem({ commit, getters }, { cardId, checklistItem }) {
    const card = JSON.parse(JSON.stringify(getters.getCards[cardId]));

    if (card) {
      const { checklists } = card;
      const clIndex = checklists.findIndex((cl: any) => cl.id === checklistItem.checklist_id);
      if (clIndex > -1) {
        const itemIndex = checklists[clIndex].items.findIndex(
          (cli: any) => cli.id === checklistItem.id
        );

        if (itemIndex > -1) {
          checklists[clIndex].items.splice(itemIndex, 1);
        }
      }
      commit('localUpdateCardField', {
        cardId,
        fieldName: 'checklists',
        fieldValue: checklists,
      });
    }
  },
  /**
   * Archives a certain card by using passed cardId.
   * https://api.superthread.com/v1/{team_id}/cards/{card_id}
   * @param {string} cardId - id of the card to be archived/restored
   * @param {boolean} archived - true if card should be archived, false if card should be restored
   */
  archiveCard({ getters, dispatch }, { cardId, archived }) {
    const { getTeamID, getCards, getUserId } = getters;
    const card = getCards[cardId];
    const cardArchived = card.archived;
    const type = card.type || ResourceType.Card;

    // check if card is archived before trying to restore it
    if (!archived && !cardArchived) return;

    if (card.sprint_id) {
      dispatch('addUiNotification', {
        message: translate('cannotArchiveCardFromSprint'),
        status: constants.uiNotificationStatuses.warning,
        duration: 5000,
      });
      return;
    }

    if (archived) {
      dispatch('localUpdateCardField', {
        cardId,
        fieldName: 'archived',
        fieldValue: {
          time_archived: Math.round(new Date().getTime() / 1000),
          user_id: getUserId,
        },
      });
      dispatch('removeCardFromListCards', {
        listId: card.list_id,
        cardId,
      });
      dispatch('removeCardFromGroupByBoard', { boardId: card.board_id, card });
    } else {
      dispatch('localUpdateCardField', {
        cardId,
        fieldName: 'archived',
        fieldValue: false,
      });
      dispatch('addCardToListCards', {
        listId: card.list_id,
        cardId,
      });
      dispatch('removeCardFromArchived', cardId);
    }

    const interpolationData = {
      name: card.title,
      type: translate(type).toLowerCase(),
    };
    const successMsg = translate(archived ? 'archiveSuccess' : 'restoreSuccess', interpolationData);
    const errorMsg = translate(archived ? 'archiveError' : 'restoreError', interpolationData);

    const req =
      type == ResourceType.Epic
        ? api.boards.updateEpic(getTeamID, cardId, { archived })
        : api.boards.updateCard(getTeamID, cardId, { archived });

    return req
      .then(({ data }: { data: UpdateCardResponse | UpdateEpicResponse }) => {
        const isEpic = type == ResourceType.Epic;
        const card = isEpic
          ? ((data as UpdateEpicResponse).epic as IEpic)
          : (data as UpdateCardResponse).card;

        if (!card) throw new Error('Invalid response');
        if (isEpic) card.board_id = constants.epicsBoardId;

        dispatch('addUiNotification', {
          message: successMsg,
          status: constants.uiNotificationStatuses.warning,
          icon: 'archive',
          duration: 4000,
          // display undo button in toast notification only if card is being archived
          ...(archived && {
            labels: [
              {
                text: translate('undo'),
                type: constants.uiNotificationElements.button,
                action: 'archiveCard',
                buttonStyle: 'important',
                params: {
                  cardId,
                  archived: false,
                },
              },
            ],
          }),
        });

        if (!archived) {
          dispatch('addCardToGroupByBoard', { boardId: card.board_id, card });
        }

        dispatch('addCardToCards', { card });
        return { card };
      })
      .catch((error) => {
        if (archived) {
          dispatch('localUpdateCardField', {
            cardId,
            fieldName: 'archived',
            fieldValue: false,
          });
          dispatch('addCardToListCards', {
            listId: card.list_id,
            cardId,
          });
          dispatch('removeCardFromArchived', cardId);
        } else {
          dispatch('localUpdateCardField', {
            cardId,
            fieldName: 'archived',
            fieldValue: cardArchived,
          });
          dispatch('removeCardFromListCards', {
            listId: card.list_id,
            cardId,
          });
          dispatch('removeCardFromGroupByBoard', { boardId: card.board_id, card });
        }
        dispatch('addUiNotification', {
          message: errorMsg,
          status: constants.uiNotificationStatuses.error,
          labels: [
            {
              text: translate('retry'),
              type: constants.uiNotificationElements.button,
              action: 'archiveCard',
              buttonStyle: 'important',
              params: {
                cardId,
                archived,
              },
            },
          ],
        });
      });
  },
  /**
   * Changes a parent card for a new one
   * * Triggering two requests be removed after adding a new endpoint
   * @param {Card} newParentCard - new parent card to add
   * @param {Card} childCard - card we add a parent to
   */
  changeParentCard({ getters, dispatch }, { newParentCard, childCard }) {
    if (!Object.keys(newParentCard || {}).length) {
      throw new Error('Missing new parent card when changing parent card');
    }

    if (!Object.keys(childCard || {}).length) {
      throw new Error('Missing child card when changing parent card');
    }

    const newParent = structuredClone(newParentCard || {});
    const child = structuredClone(childCard);
    const oldParent = structuredClone(getters.getCards[child.parent_card?.card_id || ''] || {});

    // locally add parent card to child
    dispatch('localUpdateCardField', {
      cardId: child.id,
      fieldName: 'parent_card',
      fieldValue: {
        card_id: newParent.id,
        title: newParent.title,
        list_color: newParent.list_color,
        status: newParent.status,
      },
    });
    // locally remove child card from old parent
    if (Object.keys(oldParent).length) {
      dispatch('localRemoveCardFromParent', {
        childCardId: child.id,
        card: oldParent,
        cardId: oldParent.id,
      });
    }
    // locally add child card to new parents child_card_order
    if (Object.keys(newParent).length) {
      dispatch('localAddCardToParent', {
        parentCard: newParent,
        childCard: child,
      });
    }
    // locally remove card from old epic and add it to new one
    if (Object.keys(child.epic || {}).length) {
      dispatch('localRemoveCardFromEpic', child.id);
    }
    if (Object.keys(newParent.epic || {}).length) {
      dispatch('localAddCardToEpic', { cardId: child.id, epicBrief: newParentCard.epic });
    }

    // remove child card from old parent card
    return (
      api.boards
        // make sure to use child.parent_card.card_id as we update child card locally before sending request
        .removeChildCard(getters.getTeamID, child.parent_card?.card_id, childCard.id)
        .then(() =>
          api.boards.addChildCard(getters.getTeamID, newParent.id, {
            card_id: childCard.id,
          })
        )
        .catch(() => {
          // rollback changes
          dispatch('localUpdateCardField', {
            cardId: child.id,
            fieldName: 'parent_card',
            fieldValue: {
              card_id: oldParent.id,
              title: oldParent.title,
              list_color: oldParent.list_color,
              status: oldParent.status,
            },
          });
          if (Object.keys(oldParent).length) {
            dispatch('localAddCardToParent', {
              parentCard: oldParent,
              childCard: child,
            });
          }
          if (Object.keys(newParent).length) {
            dispatch('localRemoveCardFromParent', {
              childCardId: child.id,
              card: newParent,
              cardId: newParent.id,
            });
          }
          // revert old epic changes
          if (Object.keys(newParent.epic || {}).length) {
            dispatch('localRemoveCardFromEpic', child.id);
          }
          if (Object.keys(child.epic || {}).length) {
            dispatch('localAddCardToEpic', { cardId: child.id, epicId: child.epic.id });
          }
        })
    );
  },
  /**
   * Removes a certain card from its parent by using passed cardId.
   * @param {string} childCardId
   * @param {string} cardId
   */
  removeCardFromParent({ getters, commit, dispatch }, { childCardId, card, cardId = card.id }) {
    // remove card locally
    const { getTeamID, getCards } = getters;
    const oldParentCard = structuredClone(getCards[cardId]?.parent_card || {});
    const childCard = structuredClone(getCards[childCardId]);
    dispatch('localRemoveCardFromParent', { childCardId, cardId });

    if (Object.keys(childCard.epic || {}).length) {
      dispatch('localRemoveCardFromEpic', childCardId);
    }

    return api.boards
      .removeChildCard(getTeamID, cardId, childCardId)
      .then(({ data }) => data)
      .catch((error) => {
        if (Object.keys(card || {}).length > 0) {
          const oldChildCardOrder = [...card.child_card_order];
          commit('localUpdateCardField', {
            cardId: cardId,
            fieldName: 'child_card_order',
            fieldValue: oldChildCardOrder,
          });
        }
        commit('localUpdateCardField', {
          cardId: childCardId,
          fieldName: 'parent_card',
          fieldValue: oldParentCard,
        });
        if (Object.keys(childCard.epic || {}).length) {
          dispatch('localAddCardToEpic', { cardId: childCardId, epicId: childCard.epic.id });
        }
        dispatch('addUiNotification', {
          message: translate('removeChildCardFail'),
          status: constants.uiNotificationStatuses.error,
          labels: [
            {
              text: translate('retry'),
              type: constants.uiNotificationElements.button,
              action: 'removeCardFromParent',
              buttonStyle: 'important',
              params: { childCardId, card },
            },
          ],
        });
        throw error;
      });
  },
  /**
   * Removes a local child card from parent.
   * @param {string} childCardId
   * @param {string} cardId
   */
  localRemoveCardFromParent({ commit, getters }, { childCardId, cardId }) {
    const { getCards } = getters;
    const parentCard = getCards[cardId];
    const childCard = getCards[childCardId];

    if (parentCard) {
      const childCardOrder = parentCard?.child_card_order ?? [];
      const childCardIndex = childCardOrder.findIndex((id: string) => id === childCardId);

      if (childCardIndex > -1) {
        childCardOrder.splice(childCardIndex, 1);
      }
      commit('localUpdateCardField', {
        cardId,
        fieldName: 'child_card_order',
        fieldValue: childCardOrder,
      });
    }

    if (childCard?.parent_card?.card_id == cardId) {
      commit('localUpdateCardField', {
        cardId: childCardId,
        fieldName: 'parent_card',
        fieldValue: {},
      });
    }
  },

  /**
   * Delete a card/epic.
   * https://api.superthread.com/v1/{team_id}/epics/{epic_id}
   * https://api.superthread.com/v1/{team_id}/cards/{card_id}
   * @param {string} card - card/epic that is being deleted
   */
  async deleteCard(
    { getters, dispatch, commit },
    { cardId, cardType }: { cardId: string; cardType: string }
  ) {
    const card = getters.getCards[cardId] || {};
    const cardsByEpicId: (Card | IEpic)[] = structuredClone(
      Object.values(getters.getCardsByEpicId(cardId))
    );

    if (Object.keys(card).length === 0) return Promise.reject(new Error('Card not found'));

    const { list_id: listId, board_id: boardId, sprint_id: sprintId } = card;
    const isEpic = card.type === ResourceType.Epic;
    const cardListId = sprintId ? getSprintListKey(card.project_id, sprintId, listId) : listId;
    const cardBoardId = sprintId ? getSprintKey(card.project_id, sprintId) : boardId;

    if (card.parent_card) {
      dispatch('localRemoveCardFromParent', {
        childCardId: cardId,
        cardId: card.parent_card.card_id,
      });
    }

    if (card.child_cards?.length > 0) {
      card.child_cards.forEach((childCard: any) => {
        dispatch('localUpdateCardField', {
          cardId: childCard.id,
          fieldName: 'parent_card',
          fieldValue: {},
        });
      });
    }

    // removing relation from all linked cards
    if (card.linked_cards?.length > 0) {
      card.linked_cards.forEach((linkedCard: any) => {
        const card = getters.getCards[linkedCard.card_id];
        if (!card) return;
        const linkedCards = card.linked_cards || [];
        const removeLinkedCardIndex = linkedCards.findIndex((c: any) => c.card_id === cardId);
        if (removeLinkedCardIndex > -1) {
          linkedCards.splice(removeLinkedCardIndex, 1);
          dispatch('localUpdateCardField', {
            cardId: linkedCard.card_id,
            fieldName: 'linked_cards',
            fieldValue: linkedCards,
          });
        }
      });
    }

    // remove epic field from all cards that have this epic
    for (const c of cardsByEpicId) {
      commit('localUpdateCardField', {
        cardId: c.id,
        fieldName: 'epic',
        fieldValue: {},
      });
    }

    if (card.epic) {
      dispatch('localRemoveCardFromEpic', cardId);
    }

    const oldListCards = structuredClone(getters.getListCards[cardListId] || []);
    dispatch('removeCardFromCards', card.id);
    dispatch('removeCardFromListCards', { listId: cardListId, cardId });
    dispatch('removeCardFromGroupByBoard', { boardId: cardBoardId, card });

    const isFavourite = getters.isFavourite(cardId, card.type);
    const viewResult = getters.getViewResults;

    try {
      let response;
      if (isEpic) {
        response = await api.boards.deleteEpic(getters.getTeamID, cardId);
      } else {
        response = await api.boards.deleteCard(getters.getTeamID, cardId);
        const viewCardIndex = viewResult.findIndex((c: any) => c.id === cardId);
        if (viewCardIndex > -1) {
          viewResult.splice(viewCardIndex, 1);
          dispatch('setViewResults', viewResult);
          dispatch('currentViewTotalCount', viewResult.length);
        }
      }
      dispatch('removeFromElectronRecentlyViewed', {
        currentlyViewed: {
          id: cardId,
          type:
            cardType === constants.epicRoute.name
              ? constants.epicRoute.name
              : constants.routeNames.boardCard,
        },
        teamId: getters.getTeamID,
      });
      if (isFavourite) {
        dispatch('deleteUserFavourite', {
          resource_id: cardId,
          resource_type: card.type,
          title: card.title,
        });
      }
      return response;
    } catch (error) {
      dispatch('addCardToCards', { card });
      dispatch('setListCards', { [listId]: oldListCards });
      dispatch('addCardToGroupByBoard', { boardId: cardBoardId, card });
      dispatch('localAddCardToParent', {
        parentCard: card.parent_card.card_id,
        childCard: cardId,
      });
      if (card.child_cards.length > 0) {
        card.child_cards.forEach((childCard: any) => {
          dispatch('localUpdateCardField', {
            cardId: childCard.id,
            fieldName: 'parent_card',
            fieldValue: cardId,
          });
        });
      }
      for (const c of cardsByEpicId) {
        commit('localUpdateCardField', {
          cardId: c.id,
          fieldName: 'epic',
          fieldValue: {
            id: cardId,
            title: card.title,
            icon: card.icon || {},
          },
        });
      }
      if (card.epic) {
        dispatch('localAddCardToEpic', { cardId: card.id, epicId: card.epic.id });
      }
      throw error;
    }
  },

  /**
   * Toggles checklist item to passed checklist item.
   * @param {object} checklistItem
   * https://api.superthread.com/v1/{team_id}/cards/{card_id}/checklists/{checklist_id}/items/{item_id}
   */
  checklistItemToggle({ getters, commit }, { checklistItem, checklistId, cardId }) {
    const { id, checked } = checklistItem;
    const { getTeamID, getRoutes } = getters;
    const itemsUrl = getRoutes.checklistitems;
    return authPatch(
      `${itemsUrl}/v1/${getTeamID}/cards/${cardId}/checklists/${checklistId}/items/${id}`,
      { checked: !checked }
    ).then(({ data }) => data);
  },

  /**
   * Update checklist item
   * https://api.superthread.com/v1/{team_id}/checklists/{checklist_id}/items/{item_id}
   */
  updateChecklistItem({ getters }, { checklistId, checklistItemId: id, item, cardId }) {
    const { getTeamID, getRoutes } = getters;
    const itemsUrl = getRoutes.checklistitems;
    return authPatch(
      `${itemsUrl}/v1/${getTeamID}/cards/${cardId}/checklists/${checklistId}/items/${id}`,
      { ...item }
    )
      .then(({ data }) => data)
      .catch((error) => {
        throw error;
      });
  },

  /**
   * Sets card's creating view to display on a certain list by using passed listId.
   * @param {string} listId
   */
  setListDraftCardCreateView({ commit }, listId = '') {
    commit('setListDraftCardCreateView', listId);
  },
  /**
   * Moves the board to a certain project.
   * @param {string} boardId
   * @param {string} projectId
   */
  moveBoardToProject({ getters, dispatch, commit }, { boardId, projectId, position = -1 }) {
    const board = getters.getFlatBoards[boardId];
    const projectBoardsPosition = getters.getProjectBoards(board.project_id).indexOf(boardId);
    const originalProjectId = getters.draggingEntityProjectFromId.replace('pr-', '');
    const newProjectBoardsLength = getters.getProjectBoards(projectId).length;

    dispatch('removeProjectBoard', { boardId, projectId: originalProjectId });
    dispatch('addProjectBoard', {
      boardId,
      projectId,
      ...(position > -1 && newProjectBoardsLength && { position }),
    });

    dispatch('updateBoardPropertyInBoards', {
      boardId,
      propertyName: 'project_id',
      value: projectId,
    });

    commit('localUpdateProjectBoardOrder', {
      projectToId: projectId,
      projectFromId: originalProjectId,
      boardId,
      ...(position > -1 && newProjectBoardsLength && { position }),
    });

    return dispatch('updateBoard', {
      id: boardId,
      board: {
        project_id: projectId,
        ...(position > -1 && newProjectBoardsLength && { position }),
      },
    })
      .then(() => {
        if (projectId !== originalProjectId) {
          // update project ids in cards belonging to moved board
          const boardCardIds: any[] = [];
          (board.lists || []).forEach((l: any) => boardCardIds.push(l.card_order));
          dispatch('localUpdateCardFieldInMultipleCards', {
            cardIds: boardCardIds,
            fieldName: 'project_id',
            fieldValue: projectId,
          });
        }
      })
      .catch(() => {
        dispatch('removeProjectBoard', { boardId, projectId });
        dispatch('addProjectBoard', {
          boardId,
          projectId: originalProjectId,
          position: projectBoardsPosition,
        });
        dispatch('updateBoardPropertyInBoards', {
          boardId,
          propertyName: 'project_id',
          value: originalProjectId,
        });
        throw new Error('Could not move board.');
      });
  },

  localUpdateProjectBoardOrder({ commit }, { projectToId, projectFromId, boardId, position }) {
    commit('localUpdateProjectBoardOrder', {
      projectToId,
      projectFromId,
      boardId,
      position,
    });
  },

  addProjectBoard({ commit }, payload) {
    commit('addProjectBoard', payload);
  },

  /**
   * Stores position of recently removed list with list and board ids.
   */
  setRemovedBoardListPosition({ commit }, { position, boardId, listId }) {
    commit('setRemovedBoardListPosition', { position, boardId, listId });
  },
  /**
   * Stores position of recently removed card with list, card and board ids.
   */
  setRemovedCardPosition({ commit }, { position, cardId, boardId, listId }) {
    commit('setRemovedCardPosition', {
      position,
      cardId,
      boardId,
      listId,
    });
  },
  /**
   * Sets draggend entity object to the state.
   * @param {object} payload
   */
  setDraggedEntity({ commit }, payload) {
    commit('setDraggedEntity', payload);
  },
  /**
   * Sets dropTargetId to id of element in which dragging was finished.
   * @param {object} payload
   */
  setDropTargetId({ commit }, payload) {
    commit('setDropTargetId', payload);
  },
  /**
   * Moves the card to its parent card at passed position.
   * @param {object} childCard
   */
  moveCardToParent({ dispatch }, { childCard, parentCard }) {
    const alreadyAdded = parentCard.child_card_order.some((i: any) => i === childCard.card_id);

    if (alreadyAdded) return Promise.resolve();
    dispatch('localAddCardToParent', { parentCard, childCard });
    return dispatch('addChildCardToParent', { parentCard, childCard });
  },
  /**
   * Adds parent card to a card.
   * @param {object} parentCard
   * @param {object} childCard
   */
  addParentToCard({ dispatch, getters }, { parentCard, childCard }) {
    // check if already added
    if (getters.getCards[childCard.id].parent_card?.card_id === parentCard.id) {
      return Promise.resolve();
    }

    dispatch('localAddCardToParent', { parentCard, childCard });
    return dispatch('addChildCardToParent', { parentCard, childCard, initiatedByAddParent: true });
  },
  /**
   * Adds local child card to parent card.
   * @param {object} parentCard
   * @param {object} childCard
   */
  localAddCardToParent({ commit, getters }, { parentCard, childCard }) {
    const stateParentCard = getters.getCards[parentCard.id];
    if (!stateParentCard) return;

    const newChildCards = [...(stateParentCard.child_cards || [])];
    const newChildCardsOrder = [...(stateParentCard.child_card_order || [])];
    newChildCards.push(childCard);
    newChildCardsOrder.push(childCard.id);

    commit('localUpdateCardField', {
      cardId: parentCard.id,
      fieldName: 'child_card_order',
      fieldValue: newChildCardsOrder,
    });
    commit('localUpdateCardField', {
      cardId: parentCard.id,
      fieldName: 'child_cards',
      fieldValue: newChildCards,
    });
  },
  /**
   * Adds child card to parent card.
   * @param {object} parentCard
   * @param {object} childCard
   * @param {string} localCardId
   * @param {boolean} initiatedByAddParent - indicates whether action was initiated by adding parent to card
   */
  addChildCardToParent(
    { getters, dispatch },
    { parentCard, childCard, localCardId, initiatedByAddParent = false }
  ) {
    const { getRoutes, getTeamID, getCards } = getters;
    const baseUrl = getRoutes.cards;
    const path = `v1/${getTeamID}/cards/${parentCard.id}/child_cards`;
    return authPost(`${baseUrl}/${path}`, { card_id: childCard.id })
      .then(({ data }) => {
        if (localCardId) {
          dispatch('replaceLocalChildCardIdInParentCard', {
            parentCardId: parentCard.id,
            localId: localCardId,
            newId: data.child_card.card_id,
          });
        }
        if (getCards[childCard.id] && childCard.parent_card?.card_id !== parentCard.id) {
          dispatch('localUpdateCardField', {
            cardId: childCard.id,
            fieldName: 'parent_card',
            fieldValue: {
              card_id: parentCard.id,
              title: parentCard.title,
              list_color: parentCard.list_color,
              status: parentCard.status,
            },
          });
        }
        if (childCard.epic?.id) {
          dispatch('localRemoveCardFromEpic', childCard.id);
        }
        if (parentCard.epic) {
          dispatch('localAddCardToEpic', { cardId: childCard.id, epicBrief: parentCard.epic });
        }
        if (!initiatedByAddParent) {
          dispatch('completeOnboardingActions', [OnboardingActionType.AddChildCard]);
        }
      })
      .catch(() => {
        dispatch('localRemoveCardFromParent', {
          childCardId: localCardId || childCard.id,
          cardId: parentCard.id,
        });
        dispatch('addUiNotification', {
          message: translate('addChildCardFail'),
          status: constants.uiNotificationStatuses.error,
          labels: [
            {
              text: translate('retry'),
              type: constants.uiNotificationElements.button,
              action: 'addChildCardToParent',
              buttonStyle: 'important',
              params: { parentCard, childCard },
            },
          ],
        });
      });
  },

  /**
   * Replace local child card id in parent card.
   * @param {string} parentCardId
   * @param {string} localId
   * @param {string} newId
   * @example replaceLocalChildCardIdInParentCard({ parentCardId: '1', localId: 'RMnuPy', newId: '3' });
   */
  replaceLocalChildCardIdInParentCard({ commit }, { parentCardId, localId, newId }) {
    commit('replaceLocalChildCardIdInParentCard', { parentCardId, localId, newId });
  },

  /**
   * Update child card in parent card after response. ()
   * @param {object} childCard
   */
  updateChildCardAfterResponse({ commit }, { childCard }) {
    commit('localupdateChildCardInCard', { childCard });
  },
  /**
   * Toggles flag for displaying board in quick view.
   * @param {string} boardId
   */
  toggleBoardQuickView({ getters, commit }, { boardId, value = !getters.boardExpanded(boardId) }) {
    commit('toggleBoardQuickView', { boardId, value });
  },

  /**
   * Toggle all boards from specific project in quick view.
   * @param {string} projectId
   * @param {boolean} value
   */
  toggleProjectBoards({ commit, getters }, { value, projectId = getters.getCurrentProjectId }) {
    commit('toggleProjectBoards', { value, projectId });
  },
  /**
   * Toggle boards in the same row as board with passed id.
   * In one row can be only two boards.
   * @param {string} boardId
   * @param {boolean} value
   * @param {string} projectId
   */
  toggleBoardsInRow({ commit, getters }, { boardId, value, projectId }) {
    const boards = getters.getProjectBoards(projectId);
    const boardLength = boards.length;
    const lastBoardId = boards[boardLength - 1];
    const isOdd = boardLength % 2 !== 0;
    if (boardLength === 1 || (isOdd && boardId === lastBoardId)) {
      commit('toggleBoardQuickView', { boardId, value });
      return;
    }
    commit('toggleBoardsInRow', { boardId, value, projectId });
  },
  /**
   * Sets the property of a certain list on board by using passed listId.
   * @param {string} listId
   * @param {string} propertyName
   * @param {string} value
   */
  setBoardListProp({ commit }, { listId, propertyName, value }) {
    commit('setBoardListProp', { listId, propertyName, value });
  },
  /**
   * Adds a new member to the board with passed id.
   * @param {string} memberId
   * @param {string} boardId
   */
  addMemberToBoard({ getters, commit, dispatch }, { memberToAdd, boardId }) {
    const { getRoutes, getTeamID, getFlatBoards } = getters;
    const board = getFlatBoards[boardId];

    // check if member is allready added to board,
    // or board is just created and user wants to join it
    if (board?.members) {
      const membersLength = board.members.length;
      for (let i = 0; i < membersLength; i += 1) {
        if (board.members[i].user_id === memberToAdd.user_id) {
          return Promise.resolve();
        }
      }
      commit('localAddMemberToBoard', { memberToAdd, boardId });
    } else {
      return Promise.resolve();
    }

    return authPost(`${getRoutes.boards}/v1/${getTeamID}/boards/${boardId}/members`, {
      user_id: memberToAdd.user_id,
    })
      .then(({ data }) => {
        dispatch('addMemberToProject', {
          membersToAdd: [memberToAdd],
          projectId: board.project_id,
        });
        return data;
      })
      .catch((err) => {
        commit('localRemoveMemberFromBoard', { memberToRemove: memberToAdd, boardId });
        return err;
      });
  },
  /**
   * Removes a member from the board with passed id
   * @param {string} memberId
   * @param {string} boardId
   */
  removeMemberFromBoard({ commit, getters }, { memberToRemove, boardId }) {
    const { getRoutes, getTeamID } = getters;
    const userId = memberToRemove.user_id;

    commit('localRemoveMemberFromBoard', { memberToRemove, boardId });
    return authDelete(
      `${getRoutes.boards}/v1/${getTeamID}/boards/${boardId}/members/${userId}`
    ).catch(() => {
      commit('localAddMemberToBoard', { memberToAdd: memberToRemove, boardId });
    });
  },

  localAddMemberToCard({ getters, dispatch, commit }, { cardId, memberToAdd }) {
    const { getTeamID } = getters;
    const cards = getters.getCards;
    const card = cards[cardId];
    const boardId = card.sprint_id ? getSprintKey(card.project_id, card.sprint_id) : card.board_id;

    if (card) {
      const alreadyMember = card.members?.find((m: any) => m.user_id === memberToAdd.user_id);
      if (alreadyMember) {
        return Promise.resolve();
      }
    } else {
      return Promise.resolve(`Card not found: ${cardId}`);
    }

    const currentMembers = [...(card.members || [])];
    const newMembers = [...currentMembers, memberToAdd];

    // add member to board
    dispatch('addMemberToBoard', { memberToAdd, boardId });

    commit('localUpdateCardField', {
      cardId,
      fieldName: 'members',
      fieldValue: newMembers,
    });

    commit('addGroupByList', {
      boardId,
      epicId: card.epic?.id,
      listId: memberToAdd.user_id,
      groupBy: groupByOption.assignee,
      teamId: getTeamID,
    });
    commit('removeCardFromGroupByList', {
      boardId,
      epicId: card.epic?.id,
      cardId: card.id,
      listId: 'unassigned',
      groupBy: groupByOption.assignee,
      teamId: getTeamID,
    });
    commit('addCardToGroupByList', {
      boardId,
      epicId: card.epic?.id,
      cardId: card.id,
      listId: memberToAdd.user_id,
      groupBy: groupByOption.assignee,
      teamId: getTeamID,
    });
  },
  /**
   * Adds a new member to the card with passed id.
   * @param {string} memberId
   * @param {string} cardId
   */
  addMemberToCard({ getters }, { memberToAdd, cardId }) {
    const { getRoutes, getTeamID } = getters;

    return authPost(`${getRoutes.cards}/v1/${getTeamID}/cards/${cardId}/members`, {
      user_id: memberToAdd.user_id,
      role: 'member',
    })
      .then(({ data }) => data)
      .catch((err: Error) => {
        return Promise.reject(err);
      });
  },
  localRemoveMemberFromCardBoardView({ commit }, { cardId, memberId }) {
    commit('localRemoveMemberFromCardBoardView', { cardId, memberId });
  },

  localRemoveMemberFromCard({ getters, commit }, { cardId, memberToRemove }) {
    const { getTeamID, getCards } = getters;
    const card = getCards[cardId];

    const memberId = memberToRemove?.user_id;

    if (!memberId) return;

    commit('localRemoveMemberFromCardBoardView', { cardId, memberId });

    commit('removeCardFromGroupByList', {
      boardId: card.board_id,
      epicId: card.epic?.id,
      cardId: card.id,
      listId: memberId,
      groupBy: groupByOption.assignee,
      teamId: getTeamID,
    });
    if (card.members.length === 0) {
      commit('addCardToGroupByList', {
        boardId: card.board_id,
        epicId: card.epic?.id,
        cardId: card.id,
        listId: 'unassigned',
        groupBy: groupByOption.assignee,
        teamId: getTeamID,
      });
    }
  },
  /**
   * Removes the member from a card.
   * @param {string} memberId
   * @param {string} cardId
   */
  removeMemberFromCard({ getters }, { memberToRemove, cardId, routeName = 'card' }) {
    const { getTeamID, getRoutes } = getters;
    const memberId = memberToRemove?.user_id;
    if (!memberId) return;

    return authDelete(`${getRoutes.cards}/v1/${getTeamID}/cards/${cardId}/members/${memberId}`)
      .then(({ data }) => data)
      .catch((err: Error) => {
        return Promise.reject(err);
      });
  },
  /**
   * Sets flag for removing board's data.
   * @param {boolean} payload
   */
  setBoardViewNoDestroy({ commit }, payload) {
    commit('setBoardViewNoDestroy', payload);
  },
  /**
   * Sets flag for displaying draft list input when there aren't any lists on the board.
   * @param {boolean} payload
   */
  setDraftListInputVisibleOnOpen({ commit }, payload) {
    commit('setDraftListInputVisibleOnOpen', payload);
  },
  /**
   * Sets type of board list view(list or grid view)
   * @param {string} value
   */
  setBoardListView({ commit }, value) {
    commit('setBoardListView', value);
  },
  /**
   * Sets property for sorting boards.
   * @param {string} orderBy
   */
  setBoardListOrderBy({ commit }, orderBy) {
    commit('setBoardListOrderBy', orderBy);
  },
  /**
   * Sets object of tag that needs to be edited
   * @param {object} tagForEdit
   */
  setCurrentTagForEdit({ commit }, tagForEdit) {
    commit('setCurrentTagForEdit', tagForEdit);
  },

  localAddChecklistItemToCard({ commit, getters }, { cardId, checklistItem, index }) {
    const card = JSON.parse(JSON.stringify(getters.getCards[cardId]));

    if (card) {
      const { checklists } = card;
      const clIndex = checklists.findIndex((cl: any) => cl.id === checklistItem.checklist_id);
      if (clIndex > -1) {
        checklists[clIndex].items.splice(index, 0, checklistItem);
      }
      commit('localUpdateCardField', {
        cardId,
        fieldName: 'checklists',
        fieldValue: checklists,
      });
    }
  },

  localUpdateChecklistItemIdInCard(
    { commit, getters },
    { localItem = false, cardId, checklistId, checklistItemId, ...rest }
  ) {
    const { checklists } = JSON.parse(JSON.stringify(getters.getCards[cardId]));
    const checklistIndex = checklists.findIndex((cl: any) => cl.id === checklistId);
    if (checklistIndex > -1) {
      const checklistItems = checklists[checklistIndex].items;
      if (!checklistItems) return;
      const key = localItem ? 'locaId' : 'id';
      const { item, itemIndex: index } = getItemAndIndex(checklistItems, checklistItemId, key);

      const newItem = { ...item, ...rest };
      checklistItems.splice(index, 1, newItem);
      commit('localUpdateCardField', {
        cardId,
        fieldName: 'checklists',
        fieldValue: checklists,
      });
    }
  },

  addTagToCard({ getters }, { cardId, tagId }) {
    const { getTeamID } = getters;

    return api.boards.addCardTags(getTeamID, cardId, { id: tagId }).catch((error) => {
      return error;
    });
  },

  removeCardTag({ getters }, { cardId, tagId }) {
    const { getTeamID } = getters;

    return api.boards.removeCardTag(getTeamID, cardId, tagId).catch((error) => {
      return error;
    });
  },
  localAddTagToCard({ commit, getters }, { cardId, tag }) {
    const { getCards, getTeamID } = getters;
    const card = getCards[cardId];
    const boardId = card.sprint_id ? getSprintKey(card.project_id, card.sprint_id) : card.board_id;

    commit('addGroupByList', {
      boardId,
      epicId: card.epic?.id,
      listId: tag.id,
      groupBy: groupByOption.tag,
      teamId: getTeamID,
    });
    commit('removeCardFromGroupByList', {
      boardId,
      epicId: card.epic?.id,
      cardId: card.id,
      listId: 'notags',
      groupBy: groupByOption.tag,
      teamId: getTeamID,
    });
    commit('addCardToGroupByList', {
      boardId,
      epicId: card.epic?.id,
      cardId: card.id,
      listId: tag.id,
      groupBy: groupByOption.tag,
      teamId: getTeamID,
    });
    commit('localAddTagToCard', { cardId, tag });
  },
  localAddTagToBoard({ commit }, { boardId, tag }) {
    commit('localAddTagToBoard', { boardId, tag });
  },

  setShowImageUploadPopup({ commit }, value) {
    commit('setShowImageUploadPopup', value);
  },
  /** Sets the card id for action edit.
   * @param {string} cardId
   */
  setCurrentCardIdForActionEdit({ commit }, cardId) {
    commit('setCurrentCardIdForActionEdit', cardId);
  },
  /**
   * Sets the list id for action edit.
   * @param {string} listId
   */
  setCurrentListIdForActionEdit({ commit }, listId) {
    commit('setCurrentListIdForActionEdit', listId);
  },
  /**
   * Sets type of board view
   * @param {string} value
   */
  setCardsLayout({ commit, getters }, { value, boardId = getters.currentBoardId }) {
    if (!boardId) return;

    if (!value) {
      const { value } = getters.cardsLayoutOptions.find(
        (option: CardsLayoutOption) => option.default
      );
      commit('setCardsLayout', { boardId, value });
    } else if (
      getters.cardsLayoutOptions.map((option: CardsLayoutOption) => option.value).includes(value)
    ) {
      commit('setCardsLayout', { boardId, value });
    }
  },

  addChecklistToListCards({ commit, getters }, { checklistId, checklistTitle, cardId }) {
    let cardChecklists = [...getters.getCards[cardId].checklists];
    let checklistOrder = [...(getters.getCards[cardId].checklist_order || [])];
    let checklistItemOrder = { ...(getters.getCards[cardId].checklist_item_order || {}) };
    const newChecklist = {
      id: checklistId,
      title: checklistTitle,
      items: [],
    };

    if (cardChecklists == null) {
      cardChecklists = [newChecklist];
      checklistOrder = [checklistId];
      checklistItemOrder = { [checklistId]: [] };
    } else {
      cardChecklists.push(newChecklist);
      checklistOrder.push(checklistId);
      checklistItemOrder[checklistId] = [];
    }

    commit('localUpdateCardField', {
      cardId,
      fieldName: 'checklists',
      fieldValue: cardChecklists,
    });

    commit('localUpdateCardField', {
      cardId,
      fieldName: 'checklist_order',
      fieldValue: checklistOrder,
    });

    commit('localUpdateCardField', {
      cardId,
      fieldName: 'checklist_item_order',
      fieldValue: checklistItemOrder,
    });
  },

  addBoardInBoards({ commit }, board) {
    commit('addBoardInBoards', board);
  },

  removeBoardFromProjectBoards({ commit }, board) {
    commit('removeBoardFromProjectBoards', board);
  },

  updateCardOrderInPreviewBoards({ commit }, { boardId, listId, order }) {
    commit('updateCardOrderInPreviewBoards', { boardId, listId, order });
  },

  setCurrentCardChecklistsOrder({ commit }, { checklistsOrder, cardId }) {
    commit('localUpdateCardField', {
      cardId,
      fieldName: 'checklist_order',
      fieldValue: checklistsOrder,
    });
  },

  setAttachments({ commit }, payload) {
    commit('setAttachments', { files: payload.files, id: payload.id, prefix: payload.prefix });
  },

  localAddAttachment({ commit }, { attachment, id, prefix = '' }) {
    commit('localAddAttachment', { attachment, id, prefix });
  },

  localRemoveAttachment({ commit }, { id, attachmentId, prefix = '' }) {
    commit('localRemoveAttachment', { id, attachmentId, prefix });
  },

  updateCardAttachmentId({ commit }, { attachment, localId, cardId }) {
    commit('updateCardAttachmentId', { attachment, localId, cardId });
  },

  setUploadFailedFlag({ commit }, { localId, file, cardId, value }) {
    commit('setUploadFailedFlag', { localId, file, cardId, value });
  },

  addCardFailedAttachment({ commit }, attachment) {
    commit('addCardFailedAttachment', attachment);
  },

  removeOrReplaceLocalCurrentCardChecklistItemOrder(
    { commit, getters },
    { cardId, localId, newId = null }
  ) {
    const checklistItemOrder = { ...(getters.getCards[cardId].checklist_item_order || []) };
    if (newId !== null) {
      checklistItemOrder[newId] = checklistItemOrder[localId];
    }
    delete checklistItemOrder[localId];

    commit('localUpdateCardField', {
      cardId,
      fieldName: 'checklist_item_order',
      fieldValue: checklistItemOrder,
    });
  },

  resetBoardState({ commit }) {
    commit('resetBoardState');
  },

  setCardAutoFocus({ commit }, payload) {
    commit('setCardAutoFocus', payload);
  },

  setHeaderIconsDropdown({ commit }, value) {
    commit('setHeaderIconsDropdown', value);
  },
  setPreviewPopoverKey({ commit }, payload) {
    commit('setPreviewPopoverKey', payload);
  },

  setAttachmentTargetCardId({ commit }, id) {
    commit('setAttachmentTargetCardId', id);
  },

  setCardAttachmentsLoaded({ commit }, value) {
    commit('setCardAttachmentsLoaded', value);
  },

  setProjectPreviewBoards({ commit }, { projectId, projectBoards }) {
    commit('setProjectPreviewBoards', { projectBoards, projectId });
  },

  clearProjectPreviewBoards({ commit }) {
    commit('clearProjectPreviewBoards');
  },

  setProjectPreview({ commit }, project) {
    commit('setProjectPreview', project);
  },

  displayProjectPreviewBoards({ commit }, payload) {
    commit('displayProjectPreviewBoards', payload);
  },

  setNewBoardTitle({ commit }, title) {
    commit('setNewBoardTitle', title);
  },

  setCurrentCardIdMemberDropdown({ commit }, cardId) {
    commit('setCurrentCardIdMemberDropdown', cardId);
  },

  setCurrentCardMembersDropdown({ commit }, members) {
    commit('setCurrentCardMembersDropdown', members);
  },

  setCurrentCardIdForPopup({ commit }, cardId) {
    commit('setCurrentCardIdForPopup', cardId);
  },

  updateCardTotalCommentsCount({ dispatch }, { cardId, currentCommentsCount, decrease = false }) {
    const value = decrease ? -1 : 1;
    dispatch('localUpdateCardField', {
      cardId,
      fieldName: 'total_comments',
      fieldValue: currentCommentsCount + value,
    });
  },

  setCardAttachmentUploadingId({ commit }, cardId) {
    commit('setCardAttachmentUploadingId', cardId);
  },

  /**
   * Update child cards
   * https://api.superthread.com/v1/{team_id}/cards/{card_id}/child_cards/{child_card_id}
   */
  updateChildCards({ getters, dispatch }, { childCardOrder, childCardId, item, cardId }) {
    const { getTeamID, getRoutes } = getters;
    const card = getters.getCardById(cardId);
    const oldChildCardOrder = [...card.child_card_order];
    const cardsUrl = getRoutes.cards;

    dispatch('localUpdateCardField', {
      cardId,
      fieldName: 'child_card_order',
      fieldValue: childCardOrder,
    });

    return authPatch(`${cardsUrl}/v1/${getTeamID}/cards/${cardId}/child_cards/${childCardId}`, {
      ...item,
    })
      .then(({ data }) => data)
      .catch((error) => {
        dispatch('localUpdateCardField', {
          cardId,
          fieldName: 'child_card_order',
          fieldValue: oldChildCardOrder,
        });

        dispatch('addUiNotification', {
          message: translate('moveChildCardFail'),
          status: constants.uiNotificationStatuses.error,
          labels: [
            {
              text: translate('retry'),
              type: constants.uiNotificationElements.button,
              action: 'updateChildCards',
              buttonStyle: 'important',
              params: { childCardOrder, childCardId, item },
            },
          ],
        });
        throw error;
      });
  },

  moveCardHandler(
    { getters, dispatch },
    {
      cardModel,
      cardId,
      destinationProjectId,
      destinationBoardId,
      destinationListId,
      destinationPosition,
      originalProjectId,
      originalBoardId,
      originalListId,
      originalPosition,
      route,
    }
  ) {
    addCard({
      cardModel,
      cardId,
      destinationProjectId,
      destinationBoardId,
      destinationListId,
      destinationPosition,
      originalProjectId,
      originalBoardId,
      originalListId,
      originalPosition,
      storeGetters: getters,
      storeDispatch: dispatch,
      route,
    });
  },
  /*
   * Sets flag that indicates is duplicate card popup opened
   * @param {boolean} payload
   */
  setShowDuplicateCardPopup({ commit }, payload) {
    commit('setShowDuplicateCardPopup', payload);
  },

  setShowDuplicateBoardPopup({ commit }, payload) {
    commit('setShowDuplicateBoardPopup', payload);
  },

  setShowSetParentCardPopup({ commit }, payload) {
    commit('setShowSetParentCardPopup', payload);
  },

  /**
   * Creates a duplicated card from passed cardId.
   * https://api.superthread.com/v1/{team_id}/cards/{card_id}
   * @param {object} { cardId: string, newCard: object }
   */
  duplicateCard({ getters, dispatch }, { cardId, newCard }) {
    const { getTeamID, cardsSchema } = getters;

    const copyCard = {
      ...(newCard.title && { title: newCard.title }),
      project_id: newCard.project_id,
      ...(newCard.board_id && { board_id: newCard.board_id }),
      ...(newCard.sprint_id && { sprint_id: newCard.sprint_id }),
      list_id: newCard.list_id,
      schema: newCard.schema ?? cardsSchema,
    };
    if (!copyCard.project_id) return Promise.reject(new Error('Missing card property project id'));
    if (!copyCard.board_id && !copyCard.sprint_id)
      return Promise.reject(new Error('Missing card property board_id and sprint_id'));
    if (!copyCard.list_id) return Promise.reject(new Error('Missing card property list id'));

    return api.boards
      .createCardCopy(getTeamID, cardId, copyCard)
      .then(({ data }) => {
        dispatch('completeOnboardingActions', [OnboardingActionType.CreateCard]);
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },

  cardRelationships({ getters }, { body, cardId }) {
    return api.boards
      .addLinkedCard(getters.getTeamID, cardId, body)
      .then(({ data }) => data)
      .catch((error) => {
        throw error;
      });
  },

  removeCardRelationships(
    { getters },
    { linkedCardId, cardId }: { linkedCardId: string; cardId: string }
  ) {
    return api.boards
      .removeLinkedCard(getters.getTeamID, cardId, linkedCardId)
      .then(({ data }) => data)
      .catch((error) => {
        throw error;
      });
  },

  setLinkedCardType({ commit }, payload) {
    commit('setLinkedCardType', payload);
  },

  updateBoardPropertyInBoards({ commit }, { boardId, propertyName, value }) {
    commit('updateBoardPropertyInBoards', { boardId, propertyName, value });
  },

  handleCardCreated({ commit }, { card, listId }) {
    commit('addCardToCards', { card });
    const listIdToSet = card.sprint_id
      ? getSprintListKey(card.project_id, card.sprint_id, listId)
      : listId;
    commit('addCardToListCards', { listId: listIdToSet, cardId: card.id });
  },

  localUpdateCard({ commit, getters, dispatch }, { cardId, projectId, changes }) {
    const { displayProjectPreviewBoards } = getters;
    if (displayProjectPreviewBoards) return;

    const card = getters.getCards[cardId];
    const teamId = getters.getTeamID;
    if (!card) return;
    changes.forEach((change: any) => {
      if (change.path.includes('total_files')) {
        dispatch('getFiles', { card_id: cardId });
      }
      switch (change.type) {
        case 'create':
          commit('cardWsEventsCreate', { change, cardId, projectId, teamId });
          if (change.path[0] === 'child_cards') {
            const cardId = change.to?.card_id;
            const card = { ...change.to, id: cardId };
            const board = getters.getFlatBoards[card.board_id] || {};
            if (!board?.lists) {
              dispatch('fetchBoard', { boardId: card.board_id });
            }
          }
          break;
        case 'update':
          if (change.path[2] === 'archived') {
            const cardId = change.path[1];
            dispatch('archiveHandler', { cardId, change });
          }
          commit('cardWsEventsUpdate', { change, cardId });
          break;
        case 'delete':
          commit('cardWsEventsDelete', { change, cardId });
          break;
      }
    });
  },

  // todo: use getCardCollaboration instead on new API version
  refreshCardCollaboration({ commit, getters }, { id }) {
    const { getTeamID } = getters;
    return api.boards
      .getCard(getTeamID, id)
      .then(({ data }) => {
        commit('localUpdateCardField', {
          cardId: id,
          fieldName: 'collaboration',
          // @ts-ignore
          fieldValue: data.card?.collaboration,
        });
      })
      .catch((error) => {});
  },

  archiveHandler({ getters, dispatch }, { cardId, change }) {
    if (change.from) {
      //restoring card
      dispatch('fetchCard', { cardId }).then((card) => {
        if (!getters.getListCards[card.list_id]?.includes(cardId)) {
          dispatch('addCardToListCards', {
            listId: card.list_id,
            cardId: cardId,
          });
          if (card.board_id) {
            dispatch('fetchBoard', { boardId: card.board_id });
          }
        }
      });
    } else {
      //archiving card
      const card = getters.getCards[cardId];
      dispatch('removeCardFromListCards', {
        listId: card.list_id,
        cardId: cardId,
      });
    }
  },
  fetchReport({ getters, commit }, query) {
    const boardId = query.board_id;
    return api.reports
      .reportsDetail(getters.getTeamID, query)
      .then(({ data }) => {
        const { report } = data;
        commit('setReportInfoData', { boardId, report });
      })
      .catch((error) => {
        throw error;
      });
  },

  setTimeperoidOptions({ commit }, value) {
    commit('setTimeperoidOptions', value);
  },

  setCardDraggingEnd({ commit }, value) {
    commit('setCardDraggingEnd', value);
  },

  setSingleListInListCards({ commit }, { listId, value }) {
    commit('setSingleListInListCards', { listId, value });
  },

  addCardToListCards({ commit }, { listId, cardId }) {
    commit('addCardToListCards', { listId, cardId });
  },

  removeCardFromListCards({ commit }, { listId, cardId }) {
    commit('removeCardFromListCards', { listId, cardId });
  },

  addCardToCards({ commit }, { card }) {
    commit('addCardToCards', { card });
  },

  removeCardFromCards({ commit }, cardId) {
    commit('removeCardFromCards', cardId);
  },

  /**
   * Fetches card/epic depending on type passed by id
   * @param {string} cardId - id of card/epic
   * @param {string} teamId - id of team
   * @param {ResourceType} type - type of resource
   */
  fetchCard(
    { commit, getters, dispatch },
    {
      cardId,
      teamId = getters.getTeamID,
      type = ResourceType.Card,
    }: {
      cardId: string;
      teamId?: string;
      type?: ResourceType;
    }
  ): Promise<Card | IEpic> {
    const existingPromise = activePromises.cards[cardId];
    if (existingPromise !== undefined) return existingPromise;

    const isEpic = type == ResourceType.Epic;
    const req = isEpic
      ? api.boards.getEpicCard(teamId, cardId)
      : api.boards.getCard(teamId, cardId);

    const promise = req
      .then(({ data }: { data: GetCardResponse | GetEpicResponse }) => {
        const card = isEpic
          ? ((data as GetEpicResponse).epic as IEpic)
          : (data as GetCardResponse).card;
        if (!card) throw new Error('Invalid response');

        if (isEpic) card.board_id = constants.epicsBoardId;
        if (card && !card.owner_id) card.owner_id = '';

        if (teamId === getters.getTeamID) {
          commit('addCardToCards', { card });
        }

        return card;
      })
      .catch((error) => {
        const currentRouteName = getters.getCurrentRoute.name;
        if (error.response?.status === 403 && currentRouteName === constants.routeNames.boardCard) {
          const isCardDisplayed = getters.getIsCardDisplayed(cardId);
          if (isCardDisplayed) {
            dispatch('removeDisplayedCardsByField', {
              field: 'id',
              value: cardId,
            });

            const isOnlyCardDisplayed = getters.getDisplayedCards.length === 1;
            if (isOnlyCardDisplayed) {
              dispatch('itemNotFound', true);
            }
          }
        }
        throw error;
      })
      .finally(() => {
        delete activePromises.cards[cardId];
      });

    activePromises.cards[cardId] = promise;
    return promise;
  },

  setDraggingEnd({ commit }, value) {
    commit('setDraggingEnd', value);
  },

  /**
   * Sets card(s) to be displayed, this will replace all currently displayed cards.
   * Passing an empty array or undefined will clear displayed cards.
   */
  setDisplayedCards({ commit, rootState }, payload: DisplayedCardPayload = []) {
    commit('setDisplayedCards', getNormalizedDisplayedCardPayload(rootState, payload));
  },

  /**
   * Adds card(s) to displayed cards, without replacing current ones.
   */
  addDisplayedCards({ commit, rootState }, payload: DisplayedCardPayload = []) {
    commit('addDisplayedCards', getNormalizedDisplayedCardPayload(rootState, payload));
  },

  /**
   * Removes card(s) from displayed cards.
   */
  removeDisplayedCards({ commit }, payload: string[] | string) {
    commit('removeDisplayedCards', payload);
  },

  /**
   * Removes card(s) from displayed cards by field
   * shouldMatch - if true, will remove cards that match the value, if false, will remove cards that do not match the value
   * E.g. removeDisplayedCardsByField({ field: 'layout', value: 'fullscreen' }) will remove all cards with layout 'fullscreen'
   * E.g. removeDisplayedCardsByField({ field: 'layout', value: 'fullscreen', shouldMatch: false}) will remove all cards with layout other than 'fullscreen'
   */
  removeDisplayedCardsByField({ commit }, { field, value, shouldMatch = true }) {
    commit('removeDisplayedCardsByField', { field, value, shouldMatch });
  },

  /**
   * Changes value of field of displayed card.
   */
  setFieldOfDisplayedCard(
    { commit },
    { id, value, field }: { id: string; value: any; field: keyof DisplayedCard }
  ) {
    commit('setFieldOfDisplayedCard', { id, value, field });
  },

  /**
   * Changes value of fields of displayed card.
   */
  setFieldsOfDisplayedCard(
    { commit },
    { id, payload }: { id: string; payload: Array<{ field: keyof DisplayedCard; value: any }> }
  ) {
    commit('setFieldsOfDisplayedCard', { id, payload });
  },

  /**
   * Sets the layout in which cards should be displayed by default to state and local storage.
   * @param layout - new layout ot be set
   */
  setCurrentCardLayout({ commit }, layout: string) {
    commit('setCurrentCardLayout', layout);
  },

  removeCardFromArchived({ commit }, cardId) {
    commit('removeCardFromArchived', cardId);
  },

  setChecklistCollapsedIds({ commit }, cheklistIds) {
    commit('setChecklistCollapsedIds', cheklistIds);
  },

  setHiddenItemChecklistIds({ commit }, cheklistIds) {
    commit('setHiddenItemChecklistIds', cheklistIds);
  },

  showFilterMenu({ commit }, payload) {
    commit('showFilterMenu', payload);
  },

  showSubHeaderFilterMenu({ commit }, payload) {
    commit('showSubHeaderFilterMenu', payload);
  },

  setBoardFilters({ commit, getters }, payload) {
    commit('setBoardFilters', { ...payload, teamId: getters.getTeamID });
  },

  setBoardPills({ commit }, payload) {
    commit('setBoardPills', payload);
  },

  fetchTags({ commit, getters }, { projectId }) {
    const existingPromise = activePromises.tags[projectId];
    if (existingPromise !== undefined) return existingPromise;

    const { getTeamID } = getters;
    const promise = api.boards
      .getTags(getTeamID, {
        project_id: projectId,
      })
      .then(({ data }) => {
        commit('setTags', {
          tagsArray: data.tags || [],
          projectId,
          teamId: getTeamID,
        });
      })
      .finally(() => {
        delete activePromises.tags[projectId];
      });

    activePromises.tags[projectId] = promise;
    return promise;
  },

  mergeTags({ getters }, tag) {
    return authPost(`${getters.getRoutes.cards}/v1/${getters.getTeamID}/tags/merge`, {
      name: tag.name,
      color: tag.color,
    }).then(({ data }) => data);
  },

  createTag({ getters }, { tag, projectId }) {
    return authPost(`${getters.getRoutes.cards}/v1/${getters.getTeamID}/tags`, {
      ...tag,
      ...(projectId && { project_id: projectId }),
    }).catch((error) => {
      throw error;
    });
  },

  updateTag({ getters }, { tagId, tag }) {
    return authPatch(`${getters.getRoutes.cards}/v1/${getters.getTeamID}/tags/${tagId}`, {
      ...tag,
    })
      .then(({ data }) => data)
      .catch((error) => {
        throw error;
      });
  },

  updateLocalCardTag({ commit }, { cardId, tagId, tag }) {
    commit('updateLocalCardTag', { cardId, tagId, tag });
  },

  updateLocalTag({ commit }, { tagId, tag, cardId }) {
    commit('updateLocalTag', { tagId, tag, cardId });
  },

  removeLocalPropFromTag({ commit }, { tagId, prop }) {
    commit('removeLocalPropFromTag', { tagId, prop });
  },

  deleteLocalTag({ commit }, { tagId }) {
    commit('deleteLocalTag', { tagId });
  },

  deleteTag({ getters }, { tagId }) {
    return authDelete(`${getters.getRoutes.cards}/v1/${getters.getTeamID}/tags/${tagId}`).catch(
      (error) => {
        throw error;
      }
    );
  },

  localDeleteTagFromCard({ commit, getters }, { cardId, tagId }) {
    const { getCards, getTeamID } = getters;
    const card = getCards[cardId];

    commit('removeCardFromGroupByList', {
      boardId: card.board_id,
      epicId: card.epic?.id,
      cardId: card.id,
      listId: tagId,
      groupBy: groupByOption.tag,
      teamId: getTeamID,
    });
    if (card.tags.length === 1) {
      commit('addCardToGroupByList', {
        boardId: card.board_id,
        epicId: card.epic?.id,
        cardId: card.id,
        listId: 'notags',
        groupBy: groupByOption.tag,
        teamId: getTeamID,
      });
    }

    commit('localDeleteTagFromCard', { cardId, tagId });
  },

  bulkDeleteTagFromCards({ commit, getters }, { tagId }) {
    commit('localDeleteTagFromCards', { tagId });
  },

  addLocalTag({ commit }, { tag }) {
    commit('addLocalTag', { tag });
  },

  updateLocalTagId({ commit }, { localTagId, tag }) {
    commit('updateLocalTagId', { localTagId, tag });
  },

  setCardCommentAttachmentUploadPopup({ commit }, value) {
    commit('setCardCommentAttachmentUploadPopup', value);
  },

  /**
   * Sets a flag that indicates that the upload popup
   * has been triggered from a card description.
   */
  setCardDescriptionAttachmentUploadPopup({ commit }, value) {
    commit('setCardDescriptionAttachmentUploadPopup', value);
  },

  setLastOpenedBoardId({ commit }, boardId) {
    commit('setLastOpenedBoardId', boardId);
  },

  setBoardList({ commit }, { listId, list }) {
    commit('setBoardList', { listId, list });
  },

  removeBoardFromBoards({ commit }, boardId) {
    commit('removeBoardFromBoards', boardId);
  },

  removeBoardsListsIds({ commit }, boardId) {
    commit('removeBoardsListsIds', boardId);
  },

  removeMultipleBoardLists({ commit }, listIds) {
    commit('removeMultipleBoardLists', listIds);
  },
  setFlatBoards({ commit }, boards) {
    commit('setFlatBoards', boards);
  },

  lastHoveredListId({ commit }, listId) {
    commit('lastHoveredListId', listId);
  },

  lastHoveredDisplayedCardId({ commit }, cardId: string) {
    commit('lastHoveredDisplayedCardId', cardId);
  },

  lastHoveredCardId({ commit }, cardId) {
    commit('lastHoveredCardId', cardId);
  },

  setSelectedCardId({ commit }, cardId) {
    commit('setSelectedCardId', cardId);
  },

  setCustomColorPicker({ commit }, value) {
    commit('setCustomColorPicker', value);
  },

  setBoardViewMouseSuspended({ commit }, value) {
    commit('setBoardViewMouseSuspended', value);
  },

  setChecklistInputActive({ commit }, { checklistId, isActive }) {
    commit('setChecklistInputActive', { checklistId, isActive });
  },
  setChecklistItemInputActive({ commit }, { checklistItemId, isActive }) {
    commit('setChecklistItemInputActive', { checklistItemId, isActive });
  },

  setCardOwnerId({ commit }, { ownerId, cardId }: { ownerId: string; cardId: string }) {
    commit('setCardOwnerId', { ownerId, cardId });
  },

  setViewItemSettingsDropdown({ commit }, id) {
    commit('setViewItemSettingsDropdown', id);
  },

  addBoardToBookmarked({ commit }, board) {
    commit('addBoardToBookmarked', { ...board, is_bookmarked: true });
  },
  setListCards({ commit }, listCards) {
    commit('setListCards', listCards);
  },

  fetchCardTemplates({ commit, getters }, projectId) {
    return api.boards
      .getCardTemplates(getters.getTeamID, {
        ...(projectId && { project_id: projectId }),
      })
      .then(({ data }) => {
        commit('setCardTemplates', data.card_templates || []);
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },

  fetchCardTemplate({ commit, getters }, templateId) {
    if (!templateId) return Promise.resolve({});

    return api.boards
      .getCardTemplate(getters.getTeamID, templateId)
      .then(({ data: { card_template: cardTemplate } }) => {
        commit('setCardTemplate', cardTemplate || {});
        return cardTemplate;
      })
      .catch((error) => {
        throw error;
      });
  },

  createCardTemplate({ commit, getters }, cardTemplate = getters.getCardTemplateDraft) {
    const tempId = nanoid(4);
    commit('setCardTemplate', {
      id: tempId,
      ...cardTemplate,
      user: { user_id: getters.getUserId },
    });

    return api.boards
      .createCardTemplate(getters.getTeamID, {
        ...cardTemplate,
      })
      .then(({ data }) => {
        commit('replaceCardTemplateId', { localId: tempId, template: data.card_template ?? {} });
        return data;
      })
      .catch((error) => {
        commit('deleteCardTemplate', tempId);
        throw error;
      });
  },

  updateCardTemplate(
    { commit, getters },
    {
      templateId,
      cardTemplate = getters.getCardTemplateDraft,
      user = { user_id: getters.getUserId },
    }
  ) {
    if (!templateId) return Promise.resolve({});

    const oldTemplate = getters.getCardTemplates[templateId];
    commit('setCardTemplate', {
      id: templateId,
      ...cardTemplate,
      user,
      user_updated: { user_id: getters.getUserId },
    });
    return api.boards
      .updateCardTemplate(getters.getTeamID, templateId, {
        ...cardTemplate,
      })
      .then(({ data }) => {
        commit('setCardTemplate', { id: templateId, ...data.card_template });
        return data;
      })
      .catch((error) => {
        commit('setCardTemplate', { id: templateId, ...oldTemplate });
        throw error;
      });
  },

  deleteCardTemplate({ commit, getters }, templateId) {
    if (!templateId) return Promise.resolve({});

    const oldTemplate = getters.getCardTemplates[templateId];
    commit('deleteCardTemplate', templateId);
    return api.boards.deleteCardTemplate(getters.getTeamID, templateId).catch((error) => {
      commit('setCardTemplate', { id: templateId, ...oldTemplate });
      throw error;
    });
  },

  setCardTemplateDraft({ commit }, template) {
    commit('setCardTemplateDraft', template);
  },

  setMembersToCardTemplate({ commit }, payload) {
    commit('setMembersToCardTemplate', payload);
  },

  updateCardTemplateDraftField({ commit }, { fieldName, value }) {
    commit('updateCardTemplateDraftField', { fieldName, value });
  },

  clearCardTemplateDraft({ commit }) {
    commit('clearCardTemplateDraft');
  },

  addTagToTemplate({ commit }, tagId) {
    commit('addTagToTemplate', tagId);
  },

  manageCardTemplateSelectedSpaces({ commit }, spaceId) {
    commit('manageCardTemplateSelectedSpaces', spaceId);
  },

  toggleMemberTemplateChildCard({ commit }, { index, member }) {
    commit('toggleMemberTemplateChildCard', { index, member });
  },

  addChildCardToTemplate({ commit }) {
    commit('addChildCardToTemplate');
  },

  updateChildCardInTemplate({ commit }, index) {
    commit('updateChildCardInTemplate', index);
  },

  removeChildCardFromTemplate({ commit }, index) {
    commit('removeChildCardFromTemplate', index);
  },

  updateTemplateChildCardProperty({ commit }, { fieldName, value, index }) {
    commit('updateTemplateChildCardProperty', { fieldName, value, index });
  },

  setTimelineZoom({ commit }, zoom) {
    commit('setTimelineZoom', zoom);
  },

  setTimelineSidebarWidth({ commit }, width) {
    commit('setTimelineSidebarWidth', width);
  },

  setCardWidth({ commit }, width) {
    commit('setCardWidth', width);
  },

  setChildCardActionsDropdownId({ commit }, value) {
    commit('setChildCardActionsDropdownId', value);
  },

  setChildCardStatusPickerDropdownId({ commit }, value) {
    commit('setChildCardStatusPickerDropdownId', value);
  },

  setListCardStatusPickerDropdownId({ commit }, value) {
    commit('setListCardStatusPickerDropdownId', value);
  },

  setChecklistActionsDropdownId({ commit }, value) {
    commit('setChecklistActionsDropdownId', value);
  },

  closeCardUploadPopup({ commit }) {
    commit('closeCardUploadPopup');
  },

  addQuickCardFileToUpdate({ commit }, { file, isModal = true }) {
    commit('addQuickCardFileToUpdate', { file, isModal });
  },

  removeQuickCardFilesToUpdate({ commit }, isModal = true) {
    commit('removeQuickCardFilesToUpdate', isModal);
  },

  setQuickCardTitle({ commit }, { title, isModal = true }) {
    commit('setQuickCardTitle', { title, isModal });
  },

  setCardIdForMove({ commit }, value) {
    commit('setCardIdForMove', value);
  },
  setMembersToAddQuickCard({ commit }, { value, isModal = true }) {
    commit('setMembersToAddQuickCard', { value, isModal });
  },

  setQuickCardField({ commit }, { fieldName, value, isModal = true }) {
    commit('setQuickCardField', { fieldName, value, isModal });
  },

  clearQuickCardObject({ commit }, isModal = true) {
    commit('clearQuickCardObject', isModal);
  },

  updateQuickCardChildCardProperty({ commit }, { fieldName, value, index, isModal = true }) {
    commit('updateQuickCardChildCardProperty', { fieldName, value, index, isModal });
  },

  toggleMemberQuickCardChildCard({ commit }, { index, member, isModal = true }) {
    commit('toggleMemberQuickCardChildCard', { index, member, isModal });
  },

  setQuickCardProjectId({ commit }, { projectId, isModal = true }) {
    commit('setQuickCardProjectId', { projectId, isModal });
  },

  addTagToQuickCard({ commit }, { tag, isModal = true }) {
    commit('addTagToQuickCard', { tag, isModal });
  },

  removeTagFromQuickCard({ commit }, { tagId, isModal = true }) {
    commit('removeTagFromQuickCard', { tagId, isModal });
  },

  addChildCardToQuickCard({ commit }, { isModal = true }) {
    commit('addChildCardToQuickCard', isModal);
  },

  updateChildCardInQuickCard({ commit }, { index, isModal = true }) {
    commit('updateChildCardInQuickCard', { index, isModal });
  },

  removeChildCardFromQuickCard({ commit }, { index, isModal = true }) {
    commit('removeChildCardFromQuickCard', { index, isModal });
  },

  setParentCardForQuickCard({ commit }, parentCard) {
    commit('setParentCardForQuickCard', parentCard);
  },

  addTagsToCard({ getters }, { cardId, ids }) {
    return api.boards.addCardTags(getters.getTeamID, cardId, {
      ids,
    });
  },

  setChildCardCreation({ commit }, value) {
    commit('setChildCardCreation', value);
  },

  setCardInEpicCreation({ commit }, value) {
    commit('setCardInEpicCreation', value);
  },

  setCardInNoteCreation({ commit }, value) {
    commit('setCardInNoteCreation', value);
  },

  setCardSliderActive({ commit }, value) {
    commit('setCardSliderActive', value);
  },

  setBoardSortBy({ commit, getters }, { value, boardId = getters.currentBoardId }) {
    const teamId = getters.getTeamID;

    if (!boardId) return;

    if (!value) {
      const { value } = getters.boardSortByOptions.find(
        (option: CardsLayoutOption) => option.default
      );
      commit('setBoardSortBy', { boardId, value, teamId });
    } else if (
      getters.boardSortByOptions.map((option: CardsLayoutOption) => option.value).includes(value)
    ) {
      commit('setBoardSortBy', { boardId, value, teamId });
    }
  },

  setBoardGroupBy(
    { commit, getters },
    { value, boardId = getters.currentBoardId, teamId = getters.getTeamID }
  ) {
    if (!boardId) return;

    if (!value) {
      const { value } = getters
        .boardGroupByOptions(boardId)
        .find((option: CardsLayoutOption) => option.default);
      commit('setBoardGroupBy', { boardId, value, teamId });
    } else if (
      getters.boardGroupByOptions.map((option: CardsLayoutOption) => option.value).includes(value)
    ) {
      commit('setBoardGroupBy', { boardId, value, teamId });
    }
  },

  /**
   * Sets section name and cardId for which dropdown will be shown in boards cards
   * @param {string} payload {sectionName/cardId}
   * @example setCardCustomSectionDropdown('priority/123')
   */
  setCardCustomSectionDropdown({ commit }, payload) {
    commit('setCardCustomSectionDropdown', payload);
  },

  setQuickCardObject({ commit }, { quickCardObject, isModal = true }) {
    commit('setQuickCardObject', { quickCardObject, isModal });
  },

  /**
   * Sets tag and duplicate card suggestions for card
   */
  setSuggestions(
    { commit, getters },
    { suggestions, cardId }: { suggestions: CardHint[]; cardId: string }
  ) {
    commit('localUpdateCardField', {
      cardId,
      fieldName: 'hints',
      fieldValue: suggestions,
    });
  },

  /**
   * Sets tag and duplicate card suggestions for quickCard card
   */
  setQCSuggestions({ commit }, suggestions: CardHint[]) {
    commit('setQCSuggestions', suggestions);
  },

  /**
   * Removes a specifc suggestion from the list of suggestions for card and sends an API request to ignore it on the backend
   * Ignored suggestions will not be shown again for the same card
   */
  removeSuggestion(
    { getters, dispatch },
    { payload, cardId }: { payload: RemoveSuggestionPayload; cardId: string }
  ) {
    const oldSuggestions = getters.getSuggestions(cardId);
    const newSuggestions = fnRemoveSuggestion(payload, [...oldSuggestions]);

    if (oldSuggestions.length !== newSuggestions.length) {
      dispatch('setSuggestions', { suggestions: newSuggestions, cardId });
      const { card_id, tagName, type, ignoreSuggestion } = payload;

      if (!ignoreSuggestion) return;
      api.boards.updateTagHints(getters.getTeamID, cardId, {
        actions: [
          {
            type: 'ignore',
            ...(type === SuggestionType.tag && tagName && { tag_slug: tagName.toLowerCase() }),
            ...(type === SuggestionType.relation && card_id && { related_card_id: card_id }),
          },
        ],
      });
    }
  },

  /**
   * Removes a specifc suggestion from the list of suggestions for quick card
   */
  removeQCSuggestion({ getters, commit }, payload: RemoveSuggestionPayload) {
    const oldHints = getters.getQCSuggestions;
    const newHints = fnRemoveSuggestion(payload, oldHints);
    if (newHints.length !== oldHints.length) {
      commit('setQCSuggestions', newHints);
    }
  },

  /**
   * Sets flag that indicates is suggestions placeholder shown for card
   */
  setShowSuggestionsPlaceholder({ commit }, showPlaceholder: boolean) {
    commit('setShowSuggestionsPlaceholder', showPlaceholder);
  },

  /**
   * Sets flag that indicates is suggestions placeholder shown for quick card
   */
  setQCShowSuggestionsPlaceholder({ commit }, showPlaceholder: boolean) {
    commit('setQCShowSuggestionsPlaceholder', showPlaceholder);
  },

  setBoardListGroupByOrder(
    { commit, getters },
    { boardId, listIds, groupBy = getters.getBoardGroupBy(boardId) }
  ) {
    const teamId = getters.getTeamID;
    commit('setBoardListGroupByOrder', { boardId, listIds, groupBy, teamId });
  },

  addGroupByList({ commit, getters }, { boardId, epicId, listId, groupBy }) {
    const teamId = getters.getTeamID;
    commit('addGroupByList', { boardId, epicId, listId, groupBy, teamId });
  },

  updateGroupByListOrder({ commit, getters }, payload) {
    const teamId = getters.getTeamID;
    const groupBy = getters.getBoardGroupBy(payload.boardId);
    commit('updateGroupByListOrder', { ...payload, groupBy, teamId });
  },

  setListCardsGroupByOrder({ commit, getters }, { boardId, listId, cardIds, groupBy }) {
    const teamId = getters.getTeamID;
    commit('setListCardsGroupByOrder', { boardId, listId, cardIds, teamId, groupBy });
  },

  reorderCardsGroupByList({ commit, getters }, payload) {
    const teamId = getters.getTeamID;
    commit('reorderCardsGroupByList', { ...payload, teamId });
  },

  updateCardsGroupByOrder(
    { commit, getters },
    { boardId, originalListId, destinationListId, cardId, newIndex, groupBy }
  ) {
    const teamId = getters.getTeamID;
    commit('updateCardsGroupByOrder', {
      boardId,
      originalListId,
      destinationListId,
      cardId,
      newIndex,
      groupBy,
      teamId,
    });
  },

  addCardToGroupByList({ commit, getters }, { boardId, epicId, cardId, listId, groupBy }) {
    const teamId = getters.getTeamID;
    commit('addCardToGroupByList', { boardId, epicId, cardId, listId, groupBy, teamId });
  },

  removeCardFromGroupByList(
    { commit, getters },
    { boardId, epicId, cardId, listId, groupBy = getters.getBoardGroupBy(boardId) }
  ) {
    const teamId = getters.getTeamID;
    commit('removeCardFromGroupByList', { boardId, epicId, cardId, listId, groupBy, teamId });
  },

  removeCardFromGroupByBoard({ commit, getters }, { boardId, card }) {
    const teamId = getters.getTeamID;

    // remove from tag list
    if (card.tags && card.tags.length > 0) {
      card.tags.forEach((tag: any) => {
        commit('removeCardFromGroupByList', {
          boardId,
          cardId: card.id,
          epicId: card.epic?.id,
          listId: tag.id,
          groupBy: 'tag',
          teamId,
        });
      });
    } else {
      commit('removeCardFromGroupByList', {
        boardId,
        cardId: card.id,
        epicId: card.epic?.id,
        listId: 'notags',
        groupBy: 'tag',
        teamId,
      });
    }

    // remove from member list
    if (card.members && card.members.length > 0) {
      card.members.forEach((member: any) => {
        commit('removeCardFromGroupByList', {
          boardId,
          cardId: card.id,
          epicId: card.epic?.id,
          listId: member.user_id,
          groupBy: 'assignee',
          teamId,
        });
      });
    } else {
      commit('removeCardFromGroupByList', {
        boardId,
        cardId: card.id,
        epicId: card.epic?.id,
        listId: 'unassigned',
        groupBy: 'assignee',
        teamId,
      });
    }

    // remove from priority
    commit('removeCardFromGroupByList', {
      boardId,
      epicId: card.epic?.id,
      cardId: card.id,
      listId: card?.priority || 0,
      groupBy: 'priority',
      teamId,
    });

    // remove from none
    commit('removeCardFromGroupByList', {
      boardId,
      epicId: card.epic?.id,
      cardId: card.id,
      listId: 'none',
      groupBy: 'none',
      teamId,
    });

    commit('removeCardFromGroupByList', {
      epicId: card.epic?.id,
      cardId: card.id,
      listId: card.status || 'backlog',
      groupBy: 'statusType',
      teamId,
    });
  },

  addCardToGroupByBoard({ commit, getters }, { boardId, card }) {
    const teamId = getters.getTeamID;

    // add to tag list
    if (card.tags && card.tags.length > 0) {
      card.tags.forEach((tag: any) => {
        commit('addCardToGroupByList', {
          boardId,
          epicId: card.epic?.id,
          cardId: card.id,
          listId: tag.id,
          groupBy: 'tag',
          teamId,
        });
      });
    } else {
      commit('addCardToGroupByList', {
        boardId,
        epicId: card.epic?.id,
        cardId: card.id,
        listId: 'notags',
        groupBy: 'tag',
        teamId,
      });
    }

    // add to member list
    if (card.members && card.members.length > 0) {
      card.members.forEach((member: any) => {
        commit('addCardToGroupByList', {
          boardId,
          epicId: card.epic?.id,
          cardId: card.id,
          listId: member.user_id,
          groupBy: 'assignee',
          teamId,
        });
      });
    } else {
      commit('addCardToGroupByList', {
        boardId,
        epicId: card.epic?.id,
        cardId: card.id,
        listId: 'unassigned',
        groupBy: 'assignee',
        teamId,
      });
    }

    commit('addCardToGroupByList', {
      boardId,
      epicId: card.epic?.id,
      cardId: card.id,
      listId: card?.priority || 0,
      groupBy: 'priority',
      teamId,
    });

    commit('addCardToGroupByList', {
      boardId,
      epicId: card.epic?.id,
      cardId: card.id,
      listId: 'none',
      groupBy: 'none',
      teamId,
    });

    // add card to status type group by list for in epic board
    commit('addCardToGroupByList', {
      epicId: card.epic?.id,
      cardId: card.id,
      listId: card.status || 'backlog',
      groupBy: 'statusType',
      teamId,
    });
  },

  setCurrentGroupByListIdCardActionEdit({ commit }, listId) {
    commit('setCurrentGroupByListIdCardActionEdit', listId);
  },

  setCurrentCardUniqueRowId({ commit }, uniqueRowId) {
    commit('setCurrentCardUniqueRowId', uniqueRowId);
  },

  setNewBoardObjectField({ commit }, { fieldName, value }) {
    commit('setNewBoardObjectField', { fieldName, value });
  },

  updateBoardProperty(
    { commit, getters, dispatch },
    {
      boardId,
      propertyName,
      value,
      message = {
        success: translate('boardUpdated'),
        error: translate('actionFailed'),
      },
    }
  ) {
    return api.boards
      .updateBoard(getters.getTeamID, boardId, {
        [propertyName]: value,
      })
      .then(({ data }) => {
        dispatch('addUiNotification', {
          message: message.success,
          status: constants.uiNotificationStatuses.success,
        });
        return data;
      })
      .catch((error) => {
        dispatch('addUiNotification', {
          message: message.error,
          status: constants.uiNotificationStatuses.error,
        });
        throw error;
      });
  },

  resetNewBoardObject({ commit }) {
    commit('resetNewBoardObject');
  },

  setSprintStatusPickerDropdownId({ commit }, value) {
    commit('setSprintStatusPickerDropdownId', value);
  },

  setSprintSettings({ getters, commit }, { projectId, settings }) {
    return authPut(
      `${getters.getRoutes.cards}/v1/${getters.getTeamID}/sprints/settings?project_id=${projectId}`,
      settings
    )
      .then(({ data }) => data)
      .catch((err) => {
        throw err;
      });
  },

  localSetSprintSettings({ commit }, { projectId, settings }) {
    commit('setProjectSprintSettings', { projectId, settings });
  },

  getSprintSettings({ getters, commit }, projectId) {
    return authGet(
      `${getters.getRoutes.cards}/v1/${getters.getTeamID}/sprints/settings?project_id=${projectId}`
    )
      .then(({ data }) => {
        const settings = data.sprint_settings.enabled
          ? data.sprint_settings
          : {
              enabled: false,
              start_day: 0,
              length: 7,
              cooldown: 0,
              statuses: defaultSprintStatuses,
            };
        commit('setProjectSprintSettings', { projectId, settings });
        return data.sprint_settings;
      })
      .catch((err) => {
        return err;
      });
  },

  createNewSprint({ getters, commit, dispatch }, payload) {
    return authPost(`${getters.getRoutes.cards}/v1/${getters.getTeamID}/sprints`, payload)
      .then(({ data }) => {
        const { sprint } = data;
        commit('localAddSprintToProject', {
          projectId: sprint.project_id,
          sprint: {
            id: sprint.id,
            title: sprint.title,
            start_date: sprint.start_date,
            end_date: sprint.end_date,
          },
        });
        dispatch('postFetchSprint', sprint);
        return data;
      })
      .catch((err) => {
        return err;
      });
  },

  getSprintsForProject({ getters, commit }, { projectId, untilDate }) {
    const existingPromise = activePromises.sprints[projectId];
    if (existingPromise !== undefined) return existingPromise;

    const until = untilDate ? `&until=${untilDate}` : '';
    return authGet(
      `${getters.getRoutes.cards}/v1/${getters.getTeamID}/sprints?project_id=${projectId}${until}`
    )
      .then(({ data }) => {
        const sprints: { [key: string]: any } = {};
        data.sprints.forEach((sprint: any) => {
          const rolledoverList = {
            id: 'rolledover',
            title: 'Rolled over',
            board_id: `${sprint.id}`,
            team_id: sprint.team_id,
            card_order: (sprint.unfinished_cards || []).map((c: any) => c.id),
            cards: sprint.unfinished_cards,
            behavior: 'rolledover',
          };
          if (sprint?.unfinished_cards?.length) {
            sprint.lists.push(rolledoverList);
          }
          if (sprint.lists && sprint.lists.length > 0) {
            const listCards: any = {};
            const sprintLists: any = {};
            const sprintListIds: string[] = [];
            sprint.lists.forEach((l: any) => {
              const sprintListKey = getSprintListKey(projectId, sprint.id, l.id);
              sprintListIds.push(sprintListKey);
              l.orginalId = l.id;
              l.id = sprintListKey;
              listCards[sprintListKey] = [...(l.card_order || [])];
              sprintLists[sprintListKey] = l;
            });
            commit('setSprintListsFlat', sprintLists);
            commit('setListCards', listCards);
            commit('setSprintsListsIds', { sprintId: sprint.id, list: sprintListIds, projectId });
          }
          commit('setBoardListsFlat', sprint.lists || []);
          const sprintKey = getSprintKey(projectId, sprint.id);
          sprints[sprintKey] = sprint;
          sprints[sprintKey].orginalId = sprint.id;
          sprints[sprintKey].id = sprintKey;
        });
        commit('setSpaceSprints', { projectId, sprints });
        commit('setFlatBoards', Object.values(sprints));
        return data;
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        delete activePromises.sprints[projectId];
      });
  },

  setSprintsListsIds({ commit }, { sprintId, list, projectId }) {
    commit('setSprintsListsIds', { sprintId, list, projectId });
  },

  getSprint({ getters, dispatch }, { projectId, sprintId }) {
    const generatedSprintId = getSprintKey(projectId, sprintId);
    const existingPromise = activePromises.sprints[generatedSprintId];

    if (existingPromise !== undefined) return existingPromise;
    const promise = authGet(
      `${getters.getRoutes.cards}/v1/${getters.getTeamID}/sprints/${sprintId}?project_id=${projectId}`
    )
      .then(({ data }) => {
        const sprint = data.sprint;
        dispatch('postFetchSprint', sprint);
        return data;
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        delete activePromises.sprints[generatedSprintId];
      });
    activePromises.sprints[generatedSprintId] = promise;
    return promise;
  },

  postFetchSprint({ getters, commit }, sprint) {
    const projectId = sprint.project_id;
    const sprintForSet = sprint;
    sprintForSet.orginalId = sprint.id;
    sprintForSet.id = getSprintKey(projectId, sprint.id);
    const listCards: any = {};
    const cards: any = {};
    const rolledoverList = {
      id: 'rolledover',
      title: 'Rolled over',
      board_id: `${sprintForSet.id}`,
      team_id: sprintForSet.team_id,
      card_order: (sprintForSet.unfinished_cards || []).map((c: any) => c.id),
      cards: sprintForSet.unfinished_cards,
      behavior: 'rolledover',
    };
    if (sprint?.unfinished_cards?.length) {
      sprintForSet.lists.push(rolledoverList);
    }

    const sprintLists: any[] = [];
    const sprintListIds: string[] = [];

    (sprintForSet?.lists || []).forEach((l: any) => {
      const sprintListId = getSprintListKey(projectId, sprintForSet.orginalId, l.id);
      sprintListIds.push(sprintListId);
      sprintLists.push({
        ...l,
        orginalId: l.id,
        id: sprintListId,
        board_id: `${sprintForSet.id}`,
      });

      listCards[sprintListId] = [...(l.card_order || [])];
      l.cards?.forEach((c: any) => {
        const cards = getters.getCards || {};
        const isRolledOverList = l.behavior === 'rolledover';
        const shouldAddRolledOverCard = !cards[c.id] || cards[c.id].partially_loaded;
        const shouldAddCardToState = !isRolledOverList || shouldAddRolledOverCard;

        if (!c || !shouldAddCardToState) return;

        const stateCard = cards[c.id] || {};
        cards[c.id] = {
          ...stateCard,
          ...c,
        };

        if (c?.child_cards?.length) {
          c.child_cards.forEach((child: any) => {
            if (!cards[child.card_id]) {
              child.partially_loaded = true;
              child.id = child.card_id;
              commit('addCardToCards', { card: child });
            }
          });
        }
      });
    });
    sprintForSet.lists = sprintLists;
    commit('addSprintToSpaceSprints', { projectId, sprint: sprintForSet });
    commit('setFlatBoards', [sprintForSet]);
    commit('setCards', cards);
    commit('setListCards', listCards);
    commit('setBoardListsFlat', sprintLists || []);
    commit('setSprintsListsIds', {
      sprintId: sprintForSet.orginalId,
      list: sprintListIds,
      projectId,
    });
  },

  setSprintForEdit({ commit }, item) {
    commit('setSprintForEdit', item);
  },

  localUpdateSprint({ commit }, { projectId, sprintId, prop, value }) {
    commit('localUpdateSprint', { projectId, sprintId, prop, value });
  },

  updateSprint({ getters }, { projectId, sprintId, payload }) {
    return authPatch(
      `${getters.getRoutes.cards}/v1/${getters.getTeamID}/sprints/${sprintId}?project_id=${projectId}`,
      payload
    )
      .then(({ data }) => data)
      .catch((err) => {
        throw err;
      });
  },

  localUpdateEpicInChildCards(
    { getters, commit, dispatch },
    { cardId = '', epicBrief = {} }: { cardId: string; epicBrief: EpicBrief }
  ) {
    if (!cardId) return;

    const card: Card = structuredClone(getters.getCardById(cardId));

    if (Object.keys(card || {}).length === 0) return;

    const childCards = card.child_cards || [];

    childCards.forEach((child: any) => {
      commit('localUpdateCardField', {
        cardId: child.card_id || child.id,
        fieldName: 'epic',
        fieldValue: epicBrief,
      });
      dispatch('localUpdateEpicInChildCards', {
        cardId: child.card_id || child.id,
        epicBrief,
      });
    });
  },

  localRemoveCardFromEpic({ getters, commit, dispatch }, cardId: string) {
    if (!cardId) return;

    const stateCard: Card = structuredClone(getters.getCardById(cardId));

    if (Object.keys(stateCard || {}).length === 0) return;

    commit('localUpdateCardField', {
      cardId,
      fieldName: 'epic',
      fieldValue: {},
    });

    dispatch('localUpdateEpicInChildCards', {
      cardId,
      epicBrief: {},
    });

    const stateEpic: IEpic = getters.getCardById(stateCard.epic?.id);
    if (Object.keys(stateEpic || {}).length > 0) {
      dispatch('localRemoveCardFromParent', {
        childCardId: stateCard.id,
        cardId: stateEpic.id,
      });
      dispatch('removeCardFromGroupByBoard', {
        card: stateCard,
      });
    }
  },

  localAddCardToEpic(
    { getters, commit, dispatch },
    { cardId, epicId, epicBrief = {} }: { cardId: string; epicId: string; epicBrief: any }
  ) {
    if (!epicBrief.id && !epicId) return;

    const card: Card = structuredClone(getters.getCardById(cardId));

    if (Object.keys(card || {}).length === 0) return;

    const stateEpic = getters.getCardById(epicId || epicBrief.id);
    card.epic = {
      id: epicId || epicBrief.id,
      title: stateEpic?.title || epicBrief.title,
      icon: stateEpic?.icon || epicBrief.icon,
    };

    commit('localUpdateCardField', {
      cardId,
      fieldName: 'epic',
      fieldValue: card.epic,
    });

    // add epic to all child cards
    if (card.child_cards && card.child_cards.length > 0) {
      dispatch('localUpdateEpicInChildCards', {
        cardId,
        epicBrief: card.epic,
      });
    }

    if (Object.keys(stateEpic || {}).length > 0) {
      dispatch('localAddCardToParent', {
        parentCard: stateEpic,
        childCard: card,
      });
      dispatch('addCardToGroupByBoard', {
        card,
      });
    }
  },

  removeCardFromEpic({ getters, dispatch, commit }, cardId: string) {
    const card: Card = structuredClone(getters.getCardById(cardId));
    const epic: EpicBrief = card.epic ?? {};

    if (!epic?.id) {
      throw new Error('card is not added to an epic');
    }

    dispatch('localRemoveCardFromEpic', cardId);

    commit('removeCardFromGroupByList', {
      boardId: card.board_id,
      cardId,
      listId: epic.id,
      groupBy: groupByOption.epic,
      teamId: getters.getTeamID,
    });
    commit('addCardToGroupByList', {
      boardId: card.board_id,
      cardId,
      listId: 'noproject',
      groupBy: groupByOption.epic,
      teamId: getters.getTeamID,
    });

    return api.boards.removeCardFromEpic(getters.getTeamID, epic.id, cardId).catch(() => {
      dispatch('localAddCardToEpic', { cardId, epicId: epic.id });
      commit('addCardToGroupByList', {
        boardId: card.board_id,
        cardId,
        listId: 'noproject',
        groupBy: groupByOption.epic,
        teamId: getters.getTeamID,
      });
      commit('addCardToGroupByList', {
        boardId: card.board_id,
        cardId,
        listId: epic.id,
        groupBy: groupByOption.epic,
        teamId: getters.getTeamID,
      });
    });
  },

  addCardToEpic(
    { getters, dispatch, commit },
    { cardId, epicId }: { cardId: string; epicId: string }
  ) {
    const card: Card = structuredClone(getters.getCardById(cardId));

    if (card.epic?.id) {
      throw new Error('card is already added to an epic');
    }

    dispatch('localAddCardToEpic', { cardId, epicId });
    commit('addCardToGroupByList', {
      boardId: card.board_id,
      cardId,
      listId: epicId,
      groupBy: groupByOption.epic,
      teamId: getters.getTeamID,
    });
    commit('removeCardFromGroupByList', {
      boardId: card.board_id,
      cardId,
      listId: 'noproject',
      groupBy: groupByOption.epic,
      teamId: getters.getTeamID,
    });
    return api.boards.addCardToEpic(getters.getTeamID, epicId, cardId).catch(() => {
      dispatch('localRemoveCardFromEpic', cardId);
      commit('removeCardFromGroupByList', {
        boardId: card.board_id,
        cardId,
        listId: epicId,
        groupBy: groupByOption.epic,
        teamId: getters.getTeamID,
      });
      commit('addCardToGroupByList', {
        boardId: card.board_id,
        cardId,
        listId: 'noproject',
        groupBy: groupByOption.epic,
        teamId: getters.getTeamID,
      });
    });
  },

  async deleteBoard({ getters, dispatch, commit }, { boardId, projectId }) {
    const board = getters.getBoardById(boardId) || {};

    if (Object.keys(board).length === 0) return Promise.reject(new Error('Board not found'));

    const projectIndex = getters.getTeamProjects.findIndex(
      (project: any) => project.id === projectId
    );
    if (projectIndex === -1) {
      return Promise.reject(new Error('Project not found'));
    }
    const oldBoardOrder = getters.getTeamProjects[projectIndex].board_order;
    const newBoardOrder = oldBoardOrder.filter((id: string) => id !== boardId);
    const flatBoard = getters.getFlatBoards[boardId];
    const allCards: any = [];

    if (flatBoard.lists?.length > 0) {
      flatBoard.lists.forEach((list: any) => {
        list.card_order.forEach((cardId: any) => {
          allCards.push(cardId);
        });
      });
    }

    dispatch('localUpdateProjectBoardsOrder', {
      projectId: projectId,
      payload: newBoardOrder,
    });

    const isFavourite = getters.isFavourite(boardId, ResourceType.Board);

    return await api.boards
      .deleteBoard(getters.getTeamID, boardId)
      .then(() => {
        // remove board from flat boards
        const flatBoards = getters.getFlatBoards;
        if (flatBoards?.hasOwnProperty(boardId)) {
          delete flatBoards[boardId];
        }
        const boardsArray = Object.values(flatBoards);
        commit('setFlatBoards', boardsArray);
        dispatch('addBoardInBoards', { id: `${boardId}_notFound`, notFound: true });

        dispatch('removeFromElectronRecentlyViewed', {
          currentlyViewed: {
            id: boardId,
            type: constants.routeNames.board,
          },
          teamId: getters.getTeamID,
        });

        dispatch('setProjectBoards', {
          projectId: projectId,
          projectBoards: newBoardOrder,
        });

        if (isFavourite) {
          dispatch('deleteUserFavourite', {
            resource_id: boardId,
            resource_type: ResourceType.Board,
            title: board.title,
          });
        }
        if (allCards.length) {
          // delete all cards of the deleted board from favourites and recently viewed
          allCards.forEach((cardId: any) => {
            if (getters.isFavourite(cardId, ResourceType.Card)) {
              dispatch('deleteUserFavourite', {
                resource_id: cardId,
                resource_type: ResourceType.Card,
              });
            }
            dispatch('removeFromElectronRecentlyViewed', {
              currentlyViewed: {
                id: cardId,
                type: constants.routeNames.boardCard,
              },
              teamId: getters.getTeamID,
            });
          });
        }
      })
      .catch(() => {
        dispatch('localUpdateProjectBoardsOrder', {
          projectId: projectId,
          payload: oldBoardOrder,
        });
      });
  },

  // /**
  //  * Deletes a list from the board
  //  * https://api.superthread.com/v1/{team_id}/lists/{list_id}
  //  * @param {string} listId
  //  */
  deleteList({ commit, getters, dispatch }, { listId }) {
    const list = getters.lists[listId];
    const listCards = getters.getListCards[listId];

    if (Object.keys(list).length === 0) return Promise.reject(new Error('List not found'));

    const currentListOrder = [...getters.boardsListsIds[list.board_id]];
    const newListOrder = [...currentListOrder];
    const index = newListOrder.indexOf(listId);

    return api.boards.deleteList(getters.getTeamID, listId).then(() => {
      // delete all cards of the deleted list from favourites and recently viewed
      listCards?.forEach((cardId: any) => {
        if (getters.isFavourite(cardId, ResourceType.Card)) {
          dispatch('deleteUserFavourite', {
            resource_id: cardId,
            resource_type: ResourceType.Card,
          });
        }
        dispatch('removeFromElectronRecentlyViewed', {
          currentlyViewed: {
            id: cardId,
            type: constants.routeNames.boardCard,
          },
          teamId: getters.getTeamID,
        });
      });
      if (index > -1) {
        commit('removeBoardListId', {
          boardId: list.board_id,
          id: listId,
        });
      }
    });
  },

  async createBranchAutomation({ getters, dispatch, commit }, { boardId, projectId, automation }) {
    try {
      if (boardId) {
        return await api.boards.createNewVcsBranchAutomation(getters.getTeamID, boardId, {
          name: automation.name,
        });
      }

      return await api.boards.createNewSprintVcsBranchAutomation(
        getters.getTeamID,
        { project_id: projectId },
        { name: automation.name }
      );
    } catch (error) {
      return Promise.reject(new Error('Failed to create branch specific automation'));
    }
  },

  async updateBranchAutomation(
    { getters, dispatch, commit },
    { boardId, projectId, oldName, newName }
  ) {
    try {
      if (boardId) {
        return await api.boards.updateBranchNameVcsBranchAutomation(
          getters.getTeamID,
          boardId,
          oldName,
          {
            branch: newName,
          }
        );
      }

      return await api.boards.updateBranchNameSprintVcsBranchAutomation(
        getters.getTeamID,
        oldName,
        { project_id: projectId },
        { branch: newName }
      );
    } catch (error) {
      return Promise.reject(new Error('Failed to update branch specific automation'));
    }
  },

  async deleteBranchAutomation({ getters, dispatch, commit }, { boardId, projectId, branch }) {
    try {
      if (boardId) {
        return await api.boards.deleteBoardVcsBranchAutomation(getters.getTeamID, boardId, branch);
      }

      return await api.boards.deleteSprintVcsBranchAutomation(getters.getTeamID, branch, {
        project_id: projectId,
      });
    } catch (error) {
      return Promise.reject(new Error('Failed to delete branch specific automation'));
    }
  },

  updateBoardEmail({ getters, dispatch }, { boardId, payload }) {
    return api.boards
      .updateBoardEmail(getters.getTeamID, boardId, payload)
      .then(({ data }) => {
        dispatch('updateBoardPropertyInBoards', {
          boardId,
          propertyName: 'email',
          value: payload.active ? { email: data?.email?.email, active: true } : null,
        });
      })
      .catch((error) => error);
  },

  constructInEpicBoard({ getters, dispatch }, epicId: string) {
    return api.boards.getAllCardsForEpic(getters.getTeamID, epicId).then(({ data }) => {
      const inEpicBoardId = `${constants.inEpicBoardIdPrefix}-${epicId}`;
      const inEpicBoardListId = `${inEpicBoardId}-list`;

      const inEpicBoard: any = {
        id: inEpicBoardId,
        lists: [
          {
            id: inEpicBoardListId,
            cards: [],
            card_order: [],
          },
        ],
        list_order: [inEpicBoardListId],
      };

      for (const c of data?.cards || []) {
        if (c.archived) continue;
        inEpicBoard.lists[0].cards.push(c);
        inEpicBoard.lists[0].card_order.push(c.id);
      }

      dispatch('postFetchBoard', { board: inEpicBoard });

      return inEpicBoard;
    });
  },

  setEpicSelectedTabId({ commit }, { cardId, tabId }) {
    commit('setEpicSelectedTabId', { cardId, tabId });
  },

  async convertCardToEpic({ getters, dispatch }, cardId) {
    if (!cardId) {
      throw new Error('No card id provided');
    }

    // make sure epics board is loaded
    if (!getters.getFlatBoards[constants.epicsBoardId]) {
      await dispatch('fetchBoard', { boardId: constants.epicsBoardId });
    }

    // get first epics board list
    const epicsBoard = getters.getFlatBoards[constants.epicsBoardId];
    const firstListId = epicsBoard?.list_order?.[0] || -1;

    if (firstListId < 0) {
      throw new Error(constants.noListsInEpicsBoardErr);
    }

    const oldCard = structuredClone(getters.getCardById(cardId)); // we will use this to revert changes if needed
    const firstList = getters.lists[firstListId];
    const backlog = getCardStatus(cardStatusType.Backlog);
    const newEpic = {
      ...oldCard,
      type: ResourceType.Epic,
      board_id: constants.epicsBoardId,
      board_title: '',
      project_id: '',
      estimate: null,
      linked_cards: [],
      epic: {},
      parent_card: {},
      tags: oldCard.tags?.filter((tag: any) => !tag.project_id) || [],
      list_id: firstListId,
      status: firstList.behavior || backlog.id,
      list_color: firstList.color || backlog.color,
      list_title: firstList.title || '',
      sprint_id: null,
      sprint_title: '',
    };

    // update start date based on new lists status, if not already set
    // ? setting date might be off as the one from BE will differ
    // ? and it will be set after card is opened again

    // remove this card from all linked cards
    const oldLinkedCards: any = {};
    if (oldCard.linked_cards?.length > 0) {
      oldCard.linked_cards.forEach((linkedCard: any) => {
        const linkedCardState = getters.getCardById(linkedCard.card_id);
        if (linkedCardState.linked_cards) {
          oldLinkedCards[linkedCard.card_id] = linkedCardState.linked_cards;
          linkedCardState.linked_cards = linkedCardState.linked_cards.filter(
            (c: any) => c.card_id !== cardId
          );
          dispatch('localUpdateCardField', {
            cardId: linkedCard.card_id,
            fieldName: 'linked_cards',
            fieldValue: linkedCardState.linked_cards,
          });
        }
      });
    }

    const oldEpicLinkedCards = getters.getCardById(oldCard.epic?.id || '')?.linked_cards;
    // remove card from parent card
    if (oldCard.parent_card?.card_id) {
      dispatch('localRemoveCardFromParent', {
        childCardId: cardId,
        cardId: oldCard.parent_card.card_id,
      });
    } else if (oldCard.epic?.id) {
      // add current epic as related card
      dispatch('localUpdateCardField', {
        cardId,
        fieldName: 'linked_cards',
        fieldValue: [
          {
            card_id: oldCard.epic.id,
            linked_card_type: 'related',
          },
        ],
      });
      dispatch('localUpdateCardField', {
        cardId: oldCard.epic.id,
        fieldName: 'linked_cards',
        fieldValue: [
          {
            card_id: cardId,
            linked_card_type: 'related',
          },
        ],
      });
    }

    // update prop in child cards from parent_card to epic
    if (oldCard.child_cards?.length > 0) {
      dispatch('localUpdateEpicInChildCards', {
        cardId,
        epicBrief: {
          id: cardId,
          title: oldCard.title,
        },
      });

      oldCard.child_cards.forEach((child: any) => {
        dispatch('localUpdateCardField', {
          cardId: child.card_id || child.id,
          fieldName: 'parent_card',
          fieldValue: {},
        });
      });
    }

    // remove from old list
    const oldListId = oldCard.sprint_id
      ? getSprintListKey(oldCard.project_id, oldCard.sprint_id, oldCard.list_id)
      : oldCard.list_id;
    const oldListCards = structuredClone(getters.getListCards[oldListId]);
    dispatch('removeCardFromListCards', { listId: oldListId, cardId });
    dispatch('removeCardFromGroupByBoard', { boardId: oldCard.board_id, card: oldCard });

    // overwrite card in state
    dispatch('addCardToCards', { card: newEpic });

    // add to new list, board and board groupby
    dispatch('addCardToListCards', { listId: firstListId, cardId });
    dispatch('addCardToGroupByBoard', { boardId: constants.epicsBoardId, card: newEpic });

    return api.boards
      .convertCardToEpic(getters.getTeamID, cardId)
      .then((/* { epic } */) => {
        // remove card from currently displayed view results
        const viewResults = getters.getViewResults;
        const viewCardIndex = viewResults.findIndex((c: any) => c.id === cardId);
        if (viewCardIndex > -1) {
          viewResults.splice(viewCardIndex, 1);
          dispatch('setViewResults', viewResults);
          dispatch('currentViewTotalCount', viewResults.length);
        }

        // update existing card in recently viewed
        dispatch('updateItemInElectronRecentlyViewed', {
          id: cardId,
          type: constants.routeNames.boardCard,
          toUpdate: {
            type: constants.epicRoute.name,
            additionalIcon: { src: constants.epicDefaultIcon },
          },
        });

        // remove from favourites
        //! will be changed after we implement it on BE (card in favourites will be updated instead)
        if (getters.isFavourite(cardId, ResourceType.Card)) {
          dispatch('deleteUserFavourite', {
            resource_id: cardId,
            resource_type: ResourceType.Card,
            title: oldCard.title,
          });
        }

        // we return locally adjusted epic instead of one from response
        // as certain fields are updated asynchrounously on BE
        return newEpic;
      })
      .catch((e: Error) => {
        dispatch('addCardToCards', { card: oldCard });
        dispatch('removeCardFromListCards', { listId: firstListId, cardId });
        dispatch('removeCardFromGroupByBoard', {
          boardId: constants.epicsBoardId,
          card: newEpic,
        });
        dispatch('setListCards', { [oldListId]: oldListCards });
        dispatch('addCardToGroupByBoard', { boardId: oldCard.board_id, card: oldCard });

        if (oldCard.linked_cards?.length > 0) {
          // revert old linked cards
          oldCard.linked_cards.forEach((linkedCard: any) => {
            const linkedCardState = getters.getCardById(linkedCard.card_id);
            if (linkedCardState.linked_cards) {
              dispatch('localUpdateCardField', {
                cardId: linkedCard.card_id,
                fieldName: 'linked_cards',
                fieldValue: oldLinkedCards[linkedCard.card_id] || [],
              });
            }
          });

          dispatch('localUpdateCardField', {
            cardId,
            fieldName: 'linked_cards',
            fieldValue: oldCard.linked_cards,
          });
        }

        if (oldCard.parent_card?.card_id) {
          dispatch('localAddCardToParent', {
            childCardId: cardId,
            cardId: oldCard.parent_card.card_id,
          });
        } else if (oldCard.epic?.id) {
          if ((oldCard.linked_cards ?? []).length === 0) {
            dispatch('localUpdateCardField', {
              cardId,
              fieldName: 'linked_cards',
              fieldValue: [],
            });
          }

          // revert old epics linked cards
          if (oldEpicLinkedCards) {
            dispatch('localUpdateCardField', {
              cardId: oldCard.epic.id,
              fieldName: 'linked_cards',
              fieldValue: oldEpicLinkedCards,
            });
          }
        }

        if (oldCard.child_cards?.length > 0) {
          oldCard.child_cards.forEach((child: any) => {
            dispatch('localUpdateCardField', {
              cardId: child.card_id || child.id,
              fieldName: 'parent_card',
              fieldValue: { id: cardId, title: oldCard.title },
            });
          });

          dispatch('localUpdateEpicInChildCards', {
            cardId,
            epicBrief: {},
          });
        }

        throw e; // re-throw to propagate it further
      });
  },
};

export default actions;
