import { computed } from 'vue';

export default function useCustomIcon() {
  const iconsContext = computed(() => {
    return require.context('@/assets/icons', false);
  });

  function iconPath(name: string, extension = 'svg') {
    if (!name) return '';
    try {
      return iconsContext.value(`./${name}.${extension}`);
    } catch (error) {
      return '';
    }
  }

  function getCustomIconStyle(path: string, styleOptions: any = {}, useMask: boolean = true) {
    const url = `url(${path})`;
    return useMask
      ? {
          // Use mask styles if you want to override colors
          maskImage: url,
          webkitMaskImage: url,
          maskSize: 'contain', // change with css if needed, use !important to apply
          webkitMaskSize: 'contain',
          maskRepeat: 'no-repeat',
          webkitMaskRepeat: 'no-repeat',
          ...styleOptions,
        }
      : {
          // Use background-image to preserve original colors
          backgroundImage: url,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          ...styleOptions,
        };
  }

  return {
    iconPath,
    getCustomIconStyle,
  };
}
